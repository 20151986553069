import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import validate from '../../../actions/validate';
import { Spinner, Input, Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faSearch } from '@fortawesome/free-solid-svg-icons';
import { isEmpty, debounce, get } from 'lodash';
import classnames from 'classnames';
import {
    default as CommonButton,
    BackButton,
    NextButton,
} from '../../../../../Common/Button/Button';
import { getProviderDetails } from '../../../../../../api/enrollment';
import PhysicianInfo from '../../Plans/Subviews/PhysicianInfo';
import { formatPhysicianName } from '../../../../util/format';
import AttentionModal from '../../../../components/common/AttentionModal/AttentionModal';
import {
    getNumGroups,
    getNumLocations,
    getNearestLocation,
    getSpecialtyDesc,
} from '../../../../util/physicianInfo';
import './DoctorSelection.scss';
import { useConfigContext } from '../../../../../../contexts/config';
import LinkWithAppInsights from '../../../../../Common/LinkWithAppInsights';
import { APPINSIGHT_EVENTS } from '../../../../../../constants/appInsightEvents';
import { useAppInsightContext } from '../../../../../../contexts/appInsight';
import PagerComponentTabIndexed from '../../../../components/common/Paging/PagerComponentTabIndex';
import { PAGES, SUBPAGES, PAGE_NAME } from '../../../../constants/enum';

const PHYSICIAN_DISPLAY_NUM = 6;
const DoctorSelectionPopup = ({ showModal, toggleModal, onSubmit }) => (
    <AttentionModal
        showModal={showModal}
        toggleModal={toggleModal}
        bodyComponent={
            <div>
                <strong>
                    You did not select a Primary Care Provider (PCP).
                </strong>
                <p>
                    If you have chosen an HMO plan, we suggest you also choose a
                    PCP now, or a default PCP will be selected for you upon
                    enrollment.
                </p>
                <strong>Do you want to choose a PCP now?</strong>
            </div>
        }
        buttonsComponent={
            <div>
                <Button
                    className="attention-no-btn"
                    onClick={() => {
                        toggleModal();
                        onSubmit();
                    }}
                >
                    NO
                </Button>
                <Button
                    className="attention-yes-btn"
                    onClick={() => {
                        toggleModal();
                    }}
                >
                    YES
                </Button>
            </div>
        }
    />
);
const DoctorOption = ({ savePhysician, physician, change, enrollment }) => {
    const chosenPhysician = get(enrollment, 'selectPlan.physicianInfo');

    const selectPhysician = () => {
        window.analytics.track('Doctor Selected', {
            name: formatPhysicianName(physician),
            npi: physician.npi,
        });
        if (!chosenPhysician) {
            savePhysician(physician);
            change('enrollment.selectPlan.physicianNPI', physician.npi);
        }
    };

    const nearestLocation = getNearestLocation(physician);
    return (
        <div className="pcp-container">
            <PhysicianInfo
                name={formatPhysicianName(physician)}
                npi={physician.npi}
                pcp={`${getSpecialtyDesc(get(physician, 'specialities'))}`}
                location={get(physician, 'ahRecords[0].addresses[0]', {})}
                selectPhysician={selectPhysician}
                groupNum={getNumGroups(physician)}
                locationNum={getNumLocations(physician)}
                distance={nearestLocation.distanceFromGivenAddress.toFixed(2)}
                canSelect
                physicianInfo={physician}
                enrollment={enrollment}
                change={change}
                ratingQuality={`${get(physician, 'ratings.qualityRating')}`}
            />
        </div>
    );
};
const DoctorSelectionComponent = (props) => {
    const { enrollment, change, onSubmit, previousPage } = props;
    const queryString = new URL(window.location.href).searchParams;
    const requestParams = Object.fromEntries(queryString);
    const hasSelectedProviderFromGC = !isEmpty(requestParams['providerId']);

    const [physicianSelection, setPhysicianSelection] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [loadingState, setLoadingState] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalNPI, setTotalNPI] = useState();
    const { PROVIDER_SEARCH_URL } = useConfigContext();
    const appInsights = useAppInsightContext();
    const pageNav = React.createRef();

    useEffect(() => {
        window._mfq.push(['newPageView', 'Enrollment-DoctorSelectionPage']);
        window.analytics.page('Doctor Selection');

        appInsights.startTrackPage(
            PAGE_NAME[PAGES.ENROLLMENT][SUBPAGES.CHOOSE_DOCTOR]
        );

        getProviderWithSearchQuery();

        return () => {
            appInsights.stopTrackPage(
                PAGE_NAME[PAGES.ENROLLMENT][SUBPAGES.CHOOSE_DOCTOR],
                null,
                {
                    applicationId: get(enrollment, 'applicationId'),
                }
            );
        };
    }, []);

    const delayedQuery = useCallback(
        debounce((q) => {
            setCurrentPage(1);
            return getProviderWithSearchQuery(q);
        }, 500),
        []
    );
    const getProviderWithSearchQuery = (query = '', page = 1) => {
        setLoadingState(true);
        if (!isEmpty(query) || page !== 1) {
            window.analytics.track('Doctor Searched', {
                keyword: query,
                page: page,
                zipCode: enrollment.findPlan.zipCode,
                pbp: enrollment.selectPlan.planSummary.pbp,
                coverageYear: enrollment.findPlan.coverageYear,
                contractCode: enrollment.selectPlan.planSummary.contractCode,
            });
        }
        getProviderDetails(
            enrollment.findPlan.zipCode,
            enrollment.selectPlan.planSummary.pbp,
            enrollment.findPlan.coverageYear,
            enrollment.selectPlan.planSummary.contractCode,
            query,
            page,
            PHYSICIAN_DISPLAY_NUM
        )
            .then((data) => {
                setPhysicianSelection(get(data, 'providers', []));
                setTotalNPI(get(data, 'totalNPICount', 0));
                setLoadingState(false);
            })
            .catch(() => {
                setLoadingState(false);
            });
    };
    const onChange = (e) => {
        setSearchQuery(e.target.value);
        delayedQuery(e.target.value);
    };
    const keyPressed = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            return false;
        }
    };
    const navigatePage = (pagination) => {
        if (currentPage !== pagination.currentPage) {
            setCurrentPage(pagination.currentPage);
            getProviderWithSearchQuery(searchQuery, pagination.currentPage);
        }
    };
    const savePhysician = (physician) => {
        change('enrollment.selectPlan.physicianInfo', physician);
    };
    const checkPCPInput = (e) => {
        e.preventDefault();
        if (enrollment.selectPlan['physicianNPI']) {
            onSubmit();
        } else {
            toggleModal();
        }
    };
    const toggleModal = () => {
        setShowModal((showModal) => !showModal);
    };

    const clearSelection = () => {
        window.analytics.track('Doctor Unselected', {
            name: formatPhysicianName(enrollment.selectPlan.physicianInfo),
            npi: enrollment.selectPlan.physicianNPI,
        });
        change('enrollment.selectPlan.physicianInfo', null);
        change('enrollment.selectPlan.physicianNPI', null);
    };
    return (
        <div className="doctor-selection">
            <div className="text-left">
                <h1 className="py-2 enrollment-title">
                    Primary Care Provider Selection
                </h1>
                {hasSelectedProviderFromGC ? (
                    <p>
                        Please confirm your primary care provider (PCP) listed
                        above. If you would like to change your PCP, use the
                        search below. You can change your PCP at any time after
                        you enroll.
                    </p>
                ) : (
                    <p>
                        We highly encourage you to select a primary care
                        provider (PCP). You can change your PCP at any time
                        after you enroll.
                        <ul>
                            <li>
                                For HMO enrollees, a PCP will be assigned to you
                                automatically if you do not select one when you
                                enroll
                            </li>
                            <li>
                                For PPO enrollees, a PCP selection is not
                                required but recommended.
                            </li>
                        </ul>
                    </p>
                )}
                <div className="mx-0 search-controls">
                    <div className="px-0 search-container pcp-input">
                        <Input
                            aria-label="Search Provider by PCP Name or NPI"
                            type="search"
                            placeholder="Search by PCP Name or NPI"
                            onChange={onChange}
                            onKeyPress={keyPressed}
                            value={searchQuery}
                        />
                        <div className="search-button">
                            <CommonButton
                                aria-label="Search for Provider"
                                onClick={() =>
                                    getProviderWithSearchQuery(searchQuery)
                                }
                            >
                                <FontAwesomeIcon
                                    icon={faSearch}
                                    aria-label="Search icon for Providers"
                                    aria-hidden="false"
                                />
                            </CommonButton>
                        </div>
                    </div>
                </div>
                <p className="subtitle-text">
                    Showing Primary Care Providers within 35 miles of zip code{' '}
                    {enrollment.findPlan['zipCode']}
                </p>
                <div className="provider-link">
                    <LinkWithAppInsights
                        href={PROVIDER_SEARCH_URL}
                        target="_blank"
                        appInsightEventName={
                            APPINSIGHT_EVENTS.LINK_CLICK_OTHER_PROVIDERS
                        }
                    >
                        Search for other providers{' '}
                        <FontAwesomeIcon
                            icon={faChevronRight}
                            aria-label="chevron-right"
                        />
                    </LinkWithAppInsights>
                </div>
                <div className="col-lg-2 clear-button">
                    <CommonButton
                        aria-label="Clear Selected Provider"
                        onClick={clearSelection}
                    >
                        Clear Selection
                    </CommonButton>
                </div>
                {loadingState ? (
                    <div className="box text-center py-5">
                        <Spinner />
                    </div>
                ) : (
                    <>
                        {!isEmpty(physicianSelection) ? (
                            <Row>
                                {physicianSelection.map((physician, index) => (
                                    <Col
                                        md={4}
                                        className="doctor-option-container"
                                        key={`physician_${index}`}
                                    >
                                        <DoctorOption
                                            index={index}
                                            physician={physician}
                                            savePhysician={savePhysician}
                                            {...props}
                                            enrollment={enrollment}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        ) : (
                            <div className="no-search-results">
                                No matches found for:{' '}
                                <strong>{searchQuery}</strong>
                            </div>
                        )}
                    </>
                )}
                <div className="pt-3" ref={pageNav}>
                    <PagerComponentTabIndexed
                        totalRecordsCount={totalNPI}
                        pageSize={PHYSICIAN_DISPLAY_NUM}
                        pageCount={10}
                        click={navigatePage}
                        currentPage={currentPage}
                    />
                </div>
            </div>
            <div className="py-4">
                <form onSubmit={onSubmit}>
                    <div className="d-flex justify-content-center">
                        <BackButton
                            className="mr-2 ml-0 ml-md-2"
                            onClick={previousPage}
                        >
                            Back
                        </BackButton>
                        <NextButton
                            className="ml-2"
                            type="submit"
                            onClick={(e) => checkPCPInput(e)}
                        >
                            Continue
                        </NextButton>
                    </div>
                    {/* Conditional Popup */}
                    <DoctorSelectionPopup
                        onSubmit={onSubmit}
                        toggleModal={toggleModal}
                        showModal={showModal}
                    />
                </form>
            </div>
        </div>
    );
};

const DoctorSelection = reduxForm({
    form: 'wizard', //Form name is same
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
})(
    connect((state) => {
        return getFormValues('wizard')(state);
    })(DoctorSelectionComponent)
);

export default DoctorSelection;
