import { useEffect, useRef } from 'react';
import { APPINSIGHT_EVENTS } from '../../../constants/appInsightEvents';
import { useAppInsightContext } from '../../../contexts/appInsight';
import { PAGES } from '../constants/enum';

const appInsightsTrackApplicationSubmissionTime = (
    appInsights,
    page,
    prevPage
) => {
    if (prevPage === PAGES.FIND_PLAN && page === PAGES.SELECT_PLAN) {
        appInsights.startTrackEvent(
            APPINSIGHT_EVENTS.APPLICATION_TIME_TO_SUBMISSION
        );
    } else if (page === PAGES.CONFIRMATION) {
        appInsights.stopTrackEvent(
            APPINSIGHT_EVENTS.APPLICATION_TIME_TO_SUBMISSION,
            {
                result: 'success',
            }
        );
    }
};

const useTrackSubmissionFLow = (page) => {
    const appInsights = useAppInsightContext();
    const prevPageRef = useRef(null);
    useEffect(() => {
        appInsightsTrackApplicationSubmissionTime(
            appInsights,
            page,
            prevPageRef.current
        );
        prevPageRef.current = page;
    }, [page]);
};

export default useTrackSubmissionFLow;
