import React from 'react';
import { Field } from 'redux-form';

const trimLeadingWhitespaces = (str) => {
    if (typeof str === 'string') {
        return str.replace(/^\s+/g, '');
    }
    return str;
};

const FieldTrimmed = ({ children, name, input, ...rest }) => {
    return (
        <Field {...rest} name={name} parse={trimLeadingWhitespaces}>
            {children}
        </Field>
    );
};

export default FieldTrimmed;
