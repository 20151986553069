import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import validate from '../../../actions/validate';
import { translateRelation } from '../../../../util/translation';

let ReviewEmergencyContact = (props) => {
    return (
        <div className="row">
            <div className="col-md-5">
                <ul className="no-bullet list-align-left">
                    <li>
                        Last Name:{' '}
                        <strong>
                            {
                                props.enrollment.enrolling.clientInfo[
                                    'emergencyLastName'
                                ]
                            }
                        </strong>
                    </li>
                    <li>
                        First Name:{' '}
                        <strong>
                            {
                                props.enrollment.enrolling.clientInfo[
                                    'emergencyFirstName'
                                ]
                            }
                        </strong>
                    </li>
                    <li>
                        Phone Number:{' '}
                        <strong>
                            {
                                props.enrollment.enrolling.clientInfo[
                                    'emergencyPhone'
                                ]
                            }
                        </strong>
                    </li>
                </ul>
            </div>
            <div className="col-md-5">
                <ul className="no-bullet list-align-left">
                    <li>
                        Email:{' '}
                        <strong>
                            {
                                props.enrollment.enrolling.clientInfo[
                                    'emergencyEmail'
                                ]
                            }
                        </strong>
                    </li>
                    <li>
                        Relationship to Enrollee:{' '}
                        <strong>
                            {translateRelation(
                                props.enrollment.enrolling.clientInfo[
                                    'emergencyRelationship'
                                ]
                            )}
                        </strong>
                    </li>
                </ul>
            </div>
        </div>
    );
};

ReviewEmergencyContact = reduxForm({
    form: 'wizard', //Form name is same
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
})(ReviewEmergencyContact);

ReviewEmergencyContact = connect((state) => {
    return getFormValues('wizard')(state) || {};
})(ReviewEmergencyContact);

export default ReviewEmergencyContact;
