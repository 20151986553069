import React from 'react';
import {
    InputGroup,
    FormControl,
    Row,
    Col,
    Form,
    Button,
} from 'react-bootstrap';
const TextInputWrap = ({ label, name, placeholder }) => {
    return (
        <InputGroup className="mb-3">
            <InputGroup.Prepend>
                <InputGroup.Text id="inputGroup-sizing-default">
                    {label}
                </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                name={name}
                placeholder={placeholder}
            />
        </InputGroup>
    );
};
const RadioInputWrap = ({ label, options, name }) => {
    return (
        <div>
            <p>
                <strong>{label}</strong>
            </p>
            <div key={`inline-radio`} className="mb-3">
                {options.map(({ label }, i) => (
                    <Form.Check
                        key={`${name}_${i}`}
                        inline
                        value={label}
                        label={label}
                        name={name}
                        type="radio"
                        id={`${name}_${i}`}
                    />
                ))}
            </div>
        </div>
    );
};
const MockGoldenCommInterface = () => {
    return (
        <Form className="py-2" action="/">
            <p>
                <strong style={{ fontSize: '1.3rem', color: 'teal' }}>
                    Plan Details
                </strong>
            </p>
            <Row>
                <Col md={4}>
                    <TextInputWrap
                        label="PBP: "
                        name="pbp"
                        placeholder="ex: 001"
                    />
                </Col>
                <Col md={4}>
                    <TextInputWrap
                        label="Contract Code: "
                        name="hcode"
                        placeholder="ex: H3815"
                    />
                </Col>
                <Col md={4}>
                    <TextInputWrap
                        label="Coverage Year: "
                        name="coverageYear"
                        placeholder="ex: 2023"
                    />
                </Col>
                <Col md={4}>
                    <TextInputWrap
                        label="Zip Code: "
                        name="zipCode"
                        placeholder="ex: 92620"
                    />
                </Col>
            </Row>
            <p>
                <strong style={{ fontSize: '1.3rem', color: 'teal' }}>
                    PCP Details
                </strong>
            </p>
            <Row>
                <Col md={4}>
                    <TextInputWrap
                        label="Provider ID: "
                        name="providerId"
                        placeholder="ex: OCNSO11477654440"
                    />
                </Col>
            </Row>
            <p>
                <strong style={{ fontSize: '1.3rem', color: 'teal' }}>
                    Coverage Responses
                </strong>
            </p>
            <Row>
                <Col md={6}>
                    <RadioInputWrap
                        label="I am currently enrolled in Medicare"
                        options={[{ label: 'Yes' }, { label: 'No' }]}
                        name="EnrolledInMedicare"
                    />
                    <RadioInputWrap
                        label="I have Medicaid coverage"
                        options={[{ label: 'Yes' }, { label: 'No' }]}
                        name="HasMedicaidCoverage"
                    />
                    <RadioInputWrap
                        label="I receive Veterans health care benefits through the VA"
                        options={[{ label: 'Yes' }, { label: 'No' }]}
                        name="ReceiveVeterans"
                    />
                    <RadioInputWrap
                        label="I have Group Retiree healthcare coverage"
                        options={[{ label: 'Yes' }, { label: 'No' }]}
                        name="HasGroupRetireeCoverage"
                    />
                </Col>
            </Row>
            <p>
                <strong style={{ fontSize: '1.3rem', color: 'teal' }}>
                    Do you have any of the following conditions?
                </strong>
            </p>
            <Row>
                <Col md={6}>
                    <RadioInputWrap
                        label="Diabetes"
                        options={[{ label: 'Yes' }, { label: 'No' }]}
                        name="HasDiabetes"
                    />
                    <RadioInputWrap
                        label="Heart Conditions"
                        options={[{ label: 'Yes' }, { label: 'No' }]}
                        name="HasHeartConditions"
                    />
                    <RadioInputWrap
                        label="End-stage Renal Disease"
                        options={[{ label: 'Yes' }, { label: 'No' }]}
                        name="HasESRD"
                    />
                </Col>
            </Row>
            <Button variant="primary" type="submit">
                Next
            </Button>
        </Form>
    );
};
export default MockGoldenCommInterface;
