import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { ReactComponent as QuestionOutline } from '../../../assets/icons/question-outline.svg';
import { ReactComponent as QuestionSolid } from '../../../assets/icons/question-solid.svg';
import { ReactComponent as SilverBadge } from '../../../assets/icons/silver-badge.svg';
import { ReactComponent as GoldBadge } from '../../../assets/icons/gold-badge.svg';

const CustomIcon = ({ icon, size, className, ...rest }) => {
    const parsedClassName = classnames('svg-inline--fa fa-w-16', className, {
        [`fa-${size}`]: !!size,
    });
    switch (icon) {
        case 'question-outline':
            return <QuestionOutline {...rest} className={parsedClassName} />;
        case 'question-solid':
            return <QuestionSolid {...rest} className={parsedClassName} />;
            case 'gold-badge':
                return <GoldBadge {...rest} className={parsedClassName} />;
            case 'silver-badge':
                return <SilverBadge {...rest} className={parsedClassName} />;
            default:
                return null;
    }
};

CustomIcon.propTypes = {
    icon: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['xs', 'sm', 'lg', '2x', '3x', '5x', '7x', '10x']),
    className: PropTypes.string,
};
CustomIcon.defaultProps = {
    className: '',
};

export default CustomIcon;
