import { validateFirstLastName } from '../../../actions/Validation/utils/utils';

const validateReview = (values, errors) => {
    // Electronic Signature Agreement
    if (!values.enrollment.review.reviewAuthSignature) {
        errors.enrollment.review.reviewAuthSignature = 'Please enter signature';
    }
    if (!validateFirstLastName(values.enrollment.review.reviewAuthSignature)) {
        errors.enrollment.review.reviewAuthSignature =
            'Please enter first and last name';
    }
    if (!values.enrollment.review.reviewAuthSignatureDate) {
        errors.enrollment.review.reviewAuthSignatureDate =
            'Please enter valid date';
    }
};
export default validateReview;
