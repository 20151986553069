import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import validate from '../../../actions/validate';
import { translatePayment } from '../../../../util/translation';

let ReviewPayment = (props) => {
    return (
        <p>
            {translatePayment(
                props.enrollment.enrolling.additionalInfo[
                    'premiumPaymentOption'
                ]
            )}
        </p>
    );
};

ReviewPayment = reduxForm({
    form: 'wizard', //Form name is same
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
})(ReviewPayment);

ReviewPayment = connect((state) => {
    return getFormValues('wizard')(state) || {};
})(ReviewPayment);

export default ReviewPayment;
