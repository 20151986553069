import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import classnames from 'classnames';
import { isEmpty, reduce, sortBy, uniq } from 'lodash';
import validate from '../../actions/validate';
import { RenderField, RenderItemSelector } from '../../actions/renderField';
import { get, head } from 'lodash';
import { normalizeDigit } from '../../util/normalize';
import { getCountyByZipcode } from '../../../../api/enrollment';
import '../../styles/index.scss';
import Button, { NextButton } from '../../../Common/Button/Button';
import { LENGTH_RESTRICTIONS } from '../../constants/input';
import { useConfigContext } from '../../../../contexts/config';
import { APPINSIGHT_EVENTS } from '../../../../constants/appInsightEvents';
import LinkWithAppInsights from '../../../../components/Common/LinkWithAppInsights';
import FieldTrimmed from '../../actions/FieldTrimmed';
import { useAppInsightContext } from '../../../../contexts/appInsight';
import { Spinner } from 'reactstrap';
import { PAGES, PAGE_NAME } from '../../constants/enum';

const CountyList = ({ counties, state }) => {
    if (isEmpty(counties)) {
        return null;
    }
    return (
        <div className="list-counties">
            {counties.map((countyName, idx) => (
                <span key={`county_${idx}`}>
                    {countyName} County, {state}
                </span>
            ))}
        </div>
    );
};

const populateAepFlags = ({ allowAEPEnrollments, isPreAEP }, change) => {
    change('enrollment[findPlan][allowAEPEnrollments]', allowAEPEnrollments);
    change('enrollment[findPlan][isPreAEP]', isPreAEP);
};

let WizardFormFirstPage = (props) => {
    const [zipCode, setZipCode] = useState('');
    const [countyByZipCode, setCountyByZipCode] = useState([]);
    const {
        enrollment,
        change,
        handleSubmit,
        availableCoverageYears,
        stateAbbrFromZip,
    } = props;
    const [pending, setPending] = useState(false);
    const { BROKER_PORTAL_URL } = useConfigContext();
    const appInsights = useAppInsightContext();

    useEffect(() => {
        window._mfq.push(['newPageView', 'FindPlanPage']);
        window.analytics.page('Find Plan');

        appInsights.startTrackPage(PAGE_NAME[PAGES.FIND_PLAN]);
        return () => {
            appInsights.stopTrackPage(PAGE_NAME[PAGES.FIND_PLAN], null, {
                applicationId: get(enrollment, 'applicationId'),
            });
        };
    }, []);

    useEffect(() => {
        setZipCode(get(enrollment, 'findPlan.zipCode', ''));
    }, [enrollment, setZipCode]);

    useEffect(() => {
        if (zipCode.length >= 5) {
            setPending(true);
            getCountyByZipcode(zipCode)
                .then((results) => {
                    setPending(false);
                    const { allowAEPEnrollments, isPreAEP } = get(
                        results,
                        'countyandPlanYear',
                        {
                            allowAEPEnrollments: false,
                            isPreAEP: false,
                        }
                    );

                    populateAepFlags({ allowAEPEnrollments, isPreAEP }, change);

                    const combinedYears = reduce(
                        get(results, 'countyandPlanYear.counties', []),
                        (cumu, curr) => {
                            return uniq([
                                ...cumu,
                                ...get(curr, 'planYears', []),
                            ]);
                        },
                        []
                    );
                    if (isEmpty(combinedYears)) {
                        appInsights.trackEvent({
                            name: APPINSIGHT_EVENTS.ZIPCODE_FAILURE,
                            properties: {
                                zipCode,
                                coverageYears: combinedYears,
                            },
                            error: true,
                        });
                    }
                    const combinedCounties = reduce(
                        get(results, 'countyandPlanYear.counties', []),
                        (cumu, curr) => {
                            return [...cumu, curr.countyName];
                        },
                        []
                    );
                    setCountyByZipCode(combinedCounties);
                    change(
                        'enrollment[findPlan][counties]',
                        combinedCounties
                            .map((county) => `${county} County`)
                            .join(', ')
                    );
                    change(
                        'stateAbbrFromZip',
                        get(results, 'countyandPlanYear.stateCode')
                    );
                    change(
                        'availableCoverageYears',
                        sortBy([...combinedYears])
                    );

                    if (isEmpty(enrollment.findPlan.coverageYear)) {
                        change(
                            'enrollment[findPlan][coverageYear]',
                            combinedYears[0]
                        );
                    }
                    if (allowAEPEnrollments && combinedYears.length > 1) {
                        change(
                            'enrollment[findPlan][coverageYear]',
                            combinedYears[1]
                        );
                    }
                })
                .catch((e) => {
                    appInsights.trackEvent({
                        name: APPINSIGHT_EVENTS.ZIPCODE_FAILURE,
                        properties: { zipCode, coverageYears: [] },
                        error: true,
                    });
                });
        }
    }, [zipCode, setPending, setCountyByZipCode]);

    const handleZipCodeOnChange = useCallback(
        (e) => {
            if (zipCode.length === 5 && e.target.value.length < 5) {
                change('availableCoverageYears', []);
                change('enrollment[findPlan][coverageYear]', null);
            }
            setZipCode(e.target.value);
        },
        [zipCode]
    );

    const initiateApplication = () => {
        if (!get(enrollment, 'applicationId')) {
            change('enrollment.applicationId', uuidv4());
            window.analytics.track('Zipcode Entered', {
                value: get(enrollment, 'findPlan.zipCode'),
            });
        }
    };

    const haveAvailableCoverageYears =
        !pending &&
        availableCoverageYears &&
        !isEmpty(availableCoverageYears) &&
        availableCoverageYears.length >= 1;

    const canShowNoCountyFoundMessage =
        !pending && isEmpty(countyByZipCode) && zipCode.length == 5;
    const noCountyFoundMessage = (
        <span>
            Zip Code: {zipCode} is not in our coverage area. <br />
            Please enter another Zip Code to continue.
        </span>
    );

    const canMoveToNextStep = haveAvailableCoverageYears && zipCode.length == 5;
    return (
        <Row className="pt-5 justify-content-md-center find-plan">
            <Col
                lg={6}
                className="d-flex justify-content-md-center flex-column"
            >
                <div className="pt-3">
                    <h1 className="find-plan-title">
                        Shop for plans in your area
                    </h1>
                </div>
                <form onSubmit={handleSubmit}>
                    <Row className="justify-content-md-center py-4">
                        <Col md="4" className="flex-column">
                            <FieldTrimmed
                                onChange={handleZipCodeOnChange}
                                name="enrollment[findPlan][zipCode]"
                                type="text"
                                required
                                charLimit={LENGTH_RESTRICTIONS.ZIPCODE}
                                component={RenderField}
                                normalize={normalizeDigit}
                                label="Zip Code"
                                autoFocus
                            />
                            {pending && (
                                <div className="text-md-center">
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    {'  '} Verifying...
                                </div>
                            )}
                            {haveAvailableCoverageYears && (
                                <CountyList
                                    counties={countyByZipCode}
                                    state={stateAbbrFromZip}
                                />
                            )}
                        </Col>
                        <Col
                            md="4"
                            className={classnames({
                                'd-none': !haveAvailableCoverageYears,
                            })}
                        >
                            <Field
                                name="enrollment[findPlan][coverageYear]"
                                type="select"
                                required
                                list={availableCoverageYears}
                                component={RenderItemSelector}
                                label="Coverage Year"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-md-center">
                            <div
                                className={classnames(
                                    'text-center no-found-county',
                                    {
                                        show: canShowNoCountyFoundMessage,
                                    }
                                )}
                            >
                                {noCountyFoundMessage}
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center py-4">
                        <NextButton
                            onClick={initiateApplication}
                            type="submit"
                            color="success"
                            disabled={!canMoveToNextStep}
                        >
                            Continue
                        </NextButton>
                    </Row>
                    <Row className="justify-content-md-center py-4 px-4">
                        <p>
                            If you are an authorized sales agent, please{' '}
                            <LinkWithAppInsights
                                href={BROKER_PORTAL_URL}
                                target="_new"
                                appInsightEventName={
                                    APPINSIGHT_EVENTS.LINK_CLICK_BROKER_PORTAL
                                }
                            >
                                log in to our Agent Portal
                            </LinkWithAppInsights>
                            .
                        </p>
                    </Row>
                </form>
            </Col>
        </Row>
    );
};

WizardFormFirstPage = reduxForm({
    form: 'wizard', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
})(WizardFormFirstPage);

WizardFormFirstPage = connect((state) => {
    return getFormValues('wizard')(state);
})(WizardFormFirstPage);

export default WizardFormFirstPage;
