import { isEmpty, get } from 'lodash';
// Returns yyyy-mm-dd

const formatDate = (dateValue) => {
    return dateValue.toISOString().substring(0, 10);
};

// Returns $xx.xx
const formatCost = (value, minimumFractionDigits = 2) => {
    if (typeof value === 'string' && value.includes('-')) {
        const values = value.split(/-/g).map((num) => {
            const strValue = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
            }).format(num);
            return !strValue.includes('NaN') ? strValue : num;
        });
        return values.join('-');
    }
    const strValue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits,
    }).format(value);
    return !strValue.includes('NaN') ? strValue : value;
};

const formatPhysicianName = (physicianInfo) =>
    !isEmpty(physicianInfo)
        ? `${get(physicianInfo, 'nppes.firstName')} ${get(
              physicianInfo,
              'nppes.middleName'
          )} ${get(physicianInfo, 'nppes.lastName')}, ${get(
              physicianInfo,
              'nppes.suffix'
          )}`
        : '';

const formatIdString = (value) => {
    if (typeof value !== 'string') return value;
    return value.replace(/([\s()#\[\]])/g, '');
};
const removeAttributes = (target, attributes = []) => {
    if (!target) {
        return;
    }
    attributes.forEach((attr) => target?.removeAttribute(attr));
};
const duplicateAttributes = (target, attribute, otherAttributes = []) => {
    if (!target) {
        return;
    }
    otherAttributes.forEach((attr) =>
        target?.setAttribute(attr, target?.getAttribute(attribute))
    );
};
export {
    formatDate,
    formatCost,
    formatPhysicianName,
    formatIdString,
    removeAttributes,
    duplicateAttributes,
};
