import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import { Spinner } from 'reactstrap';
import { Row, Col } from 'react-bootstrap';
import { get, isEmpty } from 'lodash';
import validate from '../../actions/validate';
import SelectPlans from './SelectPlan/SelectPlans';
import ComparePlans from './SelectPlan/ComparePlans';
import { SUBPAGES } from '../../../constants/enum';
import { getPlansByZipCodeEnrollYear } from '../../../../../api/enrollment';
import { NextButton, BackButton } from '../../../../Common/Button/Button';
import AttentionModal from '../../../components/common/AttentionModal/AttentionModal';
import AutoScrollTop from '../../../components/HOC/AutoScrollTop';
import './SecondPage-SelectPlan.scss';
import moment from 'moment';

const PreAEPMessage = connect(
    (state) => getFormValues('wizard')(state),
    null
)(({ enrollment }) => {
    const isPreAEP = get(enrollment, 'findPlan.isPreAEP', false);
    if (isPreAEP) {
        return (
            <div className="pre-aep-message">
                <h5>
                    YOU’VE SELECTED A {moment().year() + 1} MEDICARE PLAN.
                    <br />
                    YOU CAN CONTINUE TO ENROLL STARTING OCTOBER 15!
                </h5>
                <p>
                    If you have any questions, please call us toll-free for
                    assistance at{' '}
                    <span className="phone-number"> 1-888-979-2247</span>, TTY
                    711, 8 a.m. to 8 p.m., seven days a week (except
                    Thanksgiving and Christmas) from Oct. 1 through March 31,
                    and Monday to Friday (except holidays) from April 1 through
                    Sept. 30.
                </p>
            </div>
        );
    }
    return null;
});

let WizardFormSecondPage = ({
    enrollment,
    handleSubmit,
    navigatePage,
    previousPage,
    updateSubPage,
}) => {
    const [HealthPlans, setHealthPlans] = useState([]);
    const [page, setPage] = useState(SUBPAGES.SELECT_PLAN);
    const [showModal, setShowModal] = useState(false);
    const [loadingState, setLoadingState] = useState(false);
    const isPreAEP = get(enrollment, 'findPlan.isPreAEP', false);
    const preventEnrollment =
        isPreAEP &&
        (moment().year() + 1).toString() ===
            enrollment.findPlan.coverageYear.toString();

    useEffect(() => {
        updateSubPage(page);
    }, [page]);

    useEffect(() => {
        window._mfq.push(['newPageView', 'SelectPlanPage']);
        window.analytics.page('Plan Selection');

        setLoadingState(true);

        getPlansByZipCodeEnrollYear(
            enrollment.findPlan.zipCode,
            enrollment.findPlan.coverageYear
        )
            .then((results) => setHealthPlans(results.planSummary))
            .finally(() => setLoadingState(false));
    }, []);

    const navigateSubPage = (pageNum) => {
        setPage(pageNum);
    };
    const checkPlanInput = () => {
        if (get(enrollment, 'selectPlan.plan', false)) {
            handleSubmit();
        } else {
            toggleModal();
        }
    };
    const toggleModal = () => {
        setShowModal(!showModal);
    };

    if (loadingState) {
        return (
            <div className="box text-center">
                <h4>Loading...</h4>
                <Spinner />
            </div>
        );
    } else {
        return (
            <AutoScrollTop>
                <form className="secondpage-selectplan" onSubmit={handleSubmit}>
                    {page === SUBPAGES.SELECT_PLAN && (
                        <>
                            {!isEmpty(HealthPlans) && (
                                <SelectPlans
                                    navigateSub={navigateSubPage}
                                    navigatePage={navigatePage}
                                    HealthPlans={HealthPlans}
                                />
                            )}

                            <div className="d-flex justify-content-center mt-5">
                                <BackButton
                                    className="mr-2"
                                    onClick={previousPage}
                                    color="secondary"
                                >
                                    Back
                                </BackButton>
                                {!preventEnrollment && (
                                    <NextButton
                                        className="ml-2"
                                        onClick={checkPlanInput}
                                        color="success"
                                    >
                                        Enroll into Selected Plan
                                    </NextButton>
                                )}
                            </div>
                        </>
                    )}
                    {page === SUBPAGES.COMPARE_PLAN && (
                        <>
                            <ComparePlans
                                navigateSub={navigateSubPage}
                                HealthPlans={HealthPlans}
                                checkPlanInput={checkPlanInput}
                            />
                            <div className="d-flex justify-content-center mt-5">
                                <BackButton
                                    className="mr-2"
                                    onClick={() =>
                                        navigateSubPage(SUBPAGES.SELECT_PLAN)
                                    }
                                    color="secondary"
                                >
                                    Back
                                </BackButton>
                                {!preventEnrollment && (
                                    <NextButton
                                        className="ml-2"
                                        onClick={checkPlanInput}
                                        color="success"
                                    >
                                        Enroll into Selected Plan
                                    </NextButton>
                                )}
                            </div>
                        </>
                    )}
                    <AttentionModal
                        toggleModal={toggleModal}
                        showModal={showModal}
                        bodyComponent={
                            <div>
                                <strong>
                                    You have not selected a plan for enrollment.
                                </strong>
                                <p>Please choose your plan.</p>
                                <p>
                                    If you are unsure which plan to choose and
                                    need assistance, call a licensed sales agent
                                    at{' '}
                                    <a href="tel:1-888-979-2247">
                                        1-888-979-2247
                                    </a>{' '}
                                    (TTY 711), 8 a.m. to 8 p.m. seven days a
                                    week.
                                </p>
                            </div>
                        }
                    />
                    {preventEnrollment && (
                        <Row className="justify-content-center">
                            <Col md={6}>
                                <PreAEPMessage />
                            </Col>
                        </Row>
                    )}
                </form>
            </AutoScrollTop>
        );
    }
};
WizardFormSecondPage = reduxForm({
    form: 'wizard', //Form name is same
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
})(WizardFormSecondPage);

WizardFormSecondPage = connect((state) => {
    return getFormValues('wizard')(state);
})(WizardFormSecondPage);

export default WizardFormSecondPage;
