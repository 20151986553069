import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import { Row, Col, Accordion, Card, useAccordionButton } from 'react-bootstrap';
import { get, head, isEmpty } from 'lodash';
import validate from '../../../actions/validate';
import Button, {
    NextButton,
    BackButton,
} from '../../../../../Common/Button/Button';
import { BenefitDetailsList } from '../../Plans/Subviews/SubviewPlanDetails';
import './optionalBenefitsPage.scss';
import { formatCost } from '../../../../util/format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { ListGroup, ListGroupItem, ListGroupItemHeading } from 'reactstrap';
import BlankModal from '../../../../components/common/BlankModal/BlankModal';
import { useAppInsightContext } from '../../../../../../contexts/appInsight';
import LinkWithAppInsights from '../../../../../Common/LinkWithAppInsights';
import { APPINSIGHT_EVENTS } from '../../../../../../constants/appInsightEvents';
import { PAGES, PAGE_NAME } from '../../../../constants/enum';
import AttentionModal from '../../../../components/common/AttentionModal/AttentionModal';
import { RESET_ENROLLMENT_WARNING } from '../../../../constants/alertMessages';
import { useConfigContext } from 'contexts/config';

const SplitDetailList = ({
    inNetworkHeading,
    outNetworkHeading,
    inNetworkBenefits,
    outNetworkBenefits,
}) => {
    return (
        <Row className="mx-0 split-details">
            <Col lg="6" className="px-0 detail-column">
                <ListGroup className="mx-0 detail-list">
                    <ListGroupItem color="light">
                        <ListGroupItemHeading className="text-dark pt-2  detail-header">
                            {inNetworkHeading}
                        </ListGroupItemHeading>
                        <div className="py-4" />
                        {inNetworkBenefits.map(({ name, description }, idx) => (
                            <div key={`detail-${idx}`}>
                                <ListGroupItemHeading className="text-dark detail-header">
                                    {name}
                                </ListGroupItemHeading>
                                <p>{description}</p>
                            </div>
                        ))}
                    </ListGroupItem>
                </ListGroup>
            </Col>
            <Col lg="6" className="px-0 detail-column">
                <ListGroup className="mx-0 detail-list">
                    <ListGroupItem color="light">
                        <ListGroupItemHeading className="text-dark pt-2  detail-header">
                            {outNetworkHeading}
                        </ListGroupItemHeading>
                        <div className="py-4" />
                        {outNetworkBenefits.map(
                            ({ name, description }, idx) => (
                                <div key={`detail-${idx}`}>
                                    <ListGroupItemHeading className="text-dark detail-header">
                                        {name}
                                    </ListGroupItemHeading>
                                    <p>{description}</p>
                                </div>
                            )
                        )}
                    </ListGroupItem>
                </ListGroup>
            </Col>
        </Row>
    );
};

const SingleRowBenefits = ({ benefits }) => (
    <Row className="mx-0 split-details">
        <Col className="px-0 detail-column">
            <ListGroup className="mx-0 detail-list">
                <ListGroupItem color="light" className="pt-4">
                    {benefits.map(({ name, description }, idx) => (
                        <div key={`detail-${idx}`}>
                            <ListGroupItemHeading className="text-dark detail-header">
                                {name}
                            </ListGroupItemHeading>
                            <p>{description}</p>
                        </div>
                    ))}
                </ListGroupItem>
            </ListGroup>
        </Col>
    </Row>
);
const DetailsAccordionToggle = ({ eventKey }) => {
    const toggle = useAccordionButton(eventKey);
    return (
        <div
            className="d-flex align-items-center justify-content-between"
            onClick={toggle}
        >
            <p className="accordion-text">Optional Plan Details</p>
            <span>
                <FontAwesomeIcon icon={faChevronDown} title="chevron-down" />
            </span>
        </div>
    );
};
const OptionalBenefitsCard = ({
    planName,
    premiumAmount,
    benefits,
    inNetworkBenefits,
    outNetworkBenefits,
    maximumCoverage,
    children,
}) => {
    const additionalCoverageVerbiageRegex = new RegExp(
        'in-network & out-of-network max',
        'i'
    );
    const hasAdditionalCoverageVerbiage = maximumCoverage?.match(
        additionalCoverageVerbiageRegex
    );
    const headerText = isEmpty(outNetworkBenefits)
        ? 'Calendar Year Maximum Coverage: '
        : 'In-Network and Out-of-Network Calendar Year Maximum Coverage: ';

    const BenefitsSummary = useCallback(
        () => (
            <>
                {!isEmpty(benefits) && (
                    <BenefitDetailsList details={benefits} />
                )}
                {isEmpty(outNetworkBenefits) ? (
                    <SingleRowBenefits benefits={inNetworkBenefits} />
                ) : (
                    <SplitDetailList
                        inNetworkHeading="Comprehensive Dental In-Network"
                        outNetworkHeading="Comprehensive Dental Out-of-Network"
                        inNetworkBenefits={inNetworkBenefits}
                        outNetworkBenefits={outNetworkBenefits}
                    />
                )}
                {maximumCoverage && (
                    <ListGroup className="mx-0 detail-list">
                        <ListGroupItem color="light">
                            {hasAdditionalCoverageVerbiage ? (
                                <>
                                    <ListGroupItemHeading
                                        className="text-dark text-center py-3 detail-header"
                                        data-testid="optional-benefit-max-coverage"
                                    >
                                        {headerText}
                                    </ListGroupItemHeading>
                                    <p className="font-weight-normal text-center">
                                        {maximumCoverage}
                                    </p>
                                </>
                            ) : (
                                <ListGroupItemHeading
                                    className="text-dark text-center py-3 detail-header"
                                    data-testid="optional-benefit-max-coverage"
                                >
                                    {headerText}
                                    <span className="font-weight-normal">
                                        {maximumCoverage}
                                    </span>
                                </ListGroupItemHeading>
                            )}
                        </ListGroupItem>
                    </ListGroup>
                )}
            </>
        ),
        []
    );

    return (
        <div className="text-left plan-preview">
            <div className="row title-height">
                <Col xs="12" className="pl-4 pl-sm-0 pr-2">
                    <h5 className="enrollment-title optional-plan-name">
                        {planName}
                    </h5>
                </Col>
            </div>
            {premiumAmount && (
                <div className="text-center monthly-premium">
                    <h3>{formatCost(parseFloat(premiumAmount))}</h3>
                    <p>Monthly Premium</p>
                </div>
            )}
            <div>
                <Accordion className="accordion-view clickable">
                    <Card>
                        <Card.Header>
                            <DetailsAccordionToggle eventKey="0" />
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body className="p-0">
                                <BenefitsSummary />
                                {children}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <div className="benefit-details">
                    <BenefitsSummary />
                    {children}
                </div>
            </div>
        </div>
    );
};

const OptionalHealthPlanView = ({ plan }) => {
    if (isEmpty(plan)) {
        return null;
    }
    const {
        planName,
        benefitSummary,
        inNetworkBenefitSummary,
        outNetworkBenefitSummary,
        premiumAmount,
        maximumCoverage,
    } = plan;

    return (
        <>
            <OptionalBenefitsCard
                planName={planName}
                benefits={benefitSummary}
                inNetworkBenefits={inNetworkBenefitSummary}
                outNetworkBenefits={outNetworkBenefitSummary}
                premiumAmount={premiumAmount}
                maximumCoverage={maximumCoverage}
            />
        </>
    );
};
const OptionalBenefitsPage = ({
    enrollment,
    hasGoldenCommmOrigin,
    handleSubmit,
    navigatePage,
    previousPage,
    change,
}) => {
    const [optionalHealthPlan, setOptionalHealthPlan] = useState(null);
    const { GOLDEN_COMM_SHOPPING_URL } = useConfigContext();
    const [showLeavingSiteWarningModal, setShowLeavingSiteWarningModal] =
        useState(false);
    const appInsights = useAppInsightContext();
    const dentalVendorLink = get(optionalHealthPlan, 'vendorLink', '');
    const [showEnrollmentWarnigModal, setShowEnrollmentWarnigModal] =
        useState(false);

    useEffect(() => {
        window._mfq.push(['newPageView', 'OptionalBenefitsPage']);
        window.analytics.page('Optional Benefits Selection');

        appInsights.startTrackPage(PAGE_NAME[PAGES.OPTIONAL_BENEFITS_PLAN]);

        setOptionalHealthPlan(
            head(
                get(
                    enrollment,
                    'selectPlan.planSummary.optionalBenefitPlans',
                    []
                )
            )
        );

        return () => {
            appInsights.stopTrackPage(
                PAGE_NAME[PAGES.OPTIONAL_BENEFITS_PLAN],
                null,
                {
                    applicationId: get(enrollment, 'applicationId'),
                }
            );
        };
    }, []);

    const handleAddOptional = (e) => {
        e.preventDefault();
        change('enrollment.selectPlan.optionalPlanSummary', optionalHealthPlan);
        change(
            'enrollment.selectPlan.optionalPlanName',
            optionalHealthPlan.planName
        );
        handleSubmit();
    };

    const handleWithoutOptional = (e) => {
        e.preventDefault();
        change('enrollment.selectPlan.optionalPlanSummary', null);
        change('enrollment.selectPlan.optionalPlanName', null);
        handleSubmit();
    };

    const toggleLeavingSiteWarningModal = () => {
        setShowLeavingSiteWarningModal(!showLeavingSiteWarningModal);
    };
    const toggleEnrollmentWaringModal = () => {
        setShowEnrollmentWarnigModal(!showEnrollmentWarnigModal);
    };
    const alertToRestoreProcess = () => {
        setShowEnrollmentWarnigModal(true);
    };
    return (
        <div className="optional-benefits px-sm-3">
            <AttentionModal
                toggleModal={toggleEnrollmentWaringModal}
                showModal={showEnrollmentWarnigModal}
                bodyComponent={RESET_ENROLLMENT_WARNING}
                buttonsComponent={
                    <div>
                        <Button
                            className="attention-no-btn"
                            onClick={toggleEnrollmentWaringModal}
                        >
                            NO
                        </Button>
                        <Button
                            className="attention-yes-btn"
                            onClick={() => {
                                toggleEnrollmentWaringModal();
                                window.location.href = GOLDEN_COMM_SHOPPING_URL;
                            }}
                        >
                            YES
                        </Button>
                    </div>
                }
            />
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col lg={12}>
                        <h1 className="header">
                            Alignment offers the following optional benefit(s)
                            for your selected plan at an additional cost.
                        </h1>
                        {!isEmpty(dentalVendorLink) && (
                            <p>
                                This optional benefit offers an enhanced
                                provider network.{' '}
                                <a
                                    className="dental-link"
                                    onClick={toggleLeavingSiteWarningModal}
                                >
                                    View dental provider network.
                                </a>{' '}
                            </p>
                        )}
                    </Col>
                </Row>

                <Row className="plans">
                    <Col lg="12" className={'plan-item'}>
                        <OptionalHealthPlanView plan={optionalHealthPlan} />
                    </Col>
                </Row>

                <div className="button-row">
                    <BackButton
                        className="col-sm mr-2"
                        onClick={
                            hasGoldenCommmOrigin
                                ? alertToRestoreProcess
                                : previousPage
                        }
                        color="secondary"
                    >
                        Back
                    </BackButton>
                    <Button
                        className="col-sm ml-0 ml-md-2 mr-0 mr-md-2 no-select"
                        onClick={(e) => handleWithoutOptional(e)}
                        color="success"
                        type="submit"
                    >
                        No Thanks, continue without optional benefit
                    </Button>
                    <NextButton
                        className="col-sm ml-0 ml-md-2"
                        onClick={(e) => handleAddOptional(e)}
                        color="success"
                        type="submit"
                    >
                        Yes, I want to add the optional benefit!
                    </NextButton>
                </div>

                <BlankModal
                    showModal={showLeavingSiteWarningModal}
                    toggleModal={toggleLeavingSiteWarningModal}
                    title={'You are now leaving AlignmentHealthPlan.com'}
                    bodyComponent={
                        <div>
                            You are now leaving Alignment Health Plan’s website
                            to go to another website, which is managed by a
                            third party. In many instances, to keep your
                            confidential information private, a secure
                            connection between this third party website and ours
                            will be established. Please note that, once you have
                            left our website, you may be able to access portions
                            of the third party's website that are not related to
                            your Alignment plan. If you wish to stay on the
                            Alignment Health Plan website, please click “cancel”
                        </div>
                    }
                    buttonsComponent={
                        <div style={{ width: '100%' }}>
                            <Button
                                variant="secondary"
                                className="cancel-button col-sm-5"
                                onClick={toggleLeavingSiteWarningModal}
                            >
                                Cancel
                            </Button>
                            <LinkWithAppInsights
                                href={dentalVendorLink}
                                target="_blank"
                                appInsightEventName={
                                    APPINSIGHT_EVENTS.LINK_CLICK_DENTAL_PROVIDERS
                                }
                            >
                                <Button className="ok-button col-sm-5 ml-0 ml-md-2">
                                    View Dental Provider Network
                                </Button>
                            </LinkWithAppInsights>
                        </div>
                    }
                />
            </form>
        </div>
    );
};
const OptionalBenefitsPageWithForm = reduxForm({
    form: 'wizard', //Form name is same
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
})(OptionalBenefitsPage);

const OptionalBenefitsPageWithRedux = connect((state) => {
    return getFormValues('wizard')(state) || {};
})(OptionalBenefitsPageWithForm);

export default OptionalBenefitsPageWithRedux;
