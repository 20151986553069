import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import { get } from 'lodash';
import validate from '../../actions/validate';
import EligibilityVerification from './Enrollment/First-EligibilityVerification';
import ClientInform from './Enrollment/Second-ClientInfo/Second-ClientInfo';
import AddInformation from './Enrollment/Third-AddInformation';
import PreQualification from './Enrollment/Fourth-PreQualificationQuestions';
import ProcessSteps from './Enrollment/ProcessSteps';
import { SUBPAGES } from '../../../constants/enum';
import DoctorSelection from './Enrollment/DoctorSelection';
import ReviewEnrollment from './Enrollment/ReviewEnrollment';
import AutoScrollTop from '../../../components/HOC/AutoScrollTop';

const WizardFormFourthPageComponent = ({
    subPage: page,
    enrollment,
    previousPage,
    navigatePage,
    onSubmit,
    updateSubPage: setPage,
}) => {
    const [pageHeaders, setPageHeaders] = useState([
        { label: 'Applicant Information', pageIndex: SUBPAGES.APPLICANT_INFO },
        {
            label: 'Confirm Eligibility',
            pageIndex: SUBPAGES.CONFIRM_ELIGIBILITY,
        },
        { label: 'Choose My Doctor', pageIndex: SUBPAGES.CHOOSE_DOCTOR },
        {
            label: 'Additional Information',
            pageIndex: SUBPAGES.ADDITIONAL_INFO,
        },
        { label: 'Review & Submit', pageIndex: SUBPAGES.REVIEW },
    ]);
    const [goToRef, setGoToRef] = useState(null);
    const requiresSNPWorkflow = get(
        enrollment,
        'selectPlan.planSummary.isSNPPrequalFormRequired'
    );
    useEffect(() => {
        if (requiresSNPWorkflow) {
            setPageHeaders((pageHeaders) => {
                let newPageHeaders = [...pageHeaders];
                newPageHeaders.splice(SUBPAGES.SNP_PREQUALIFICATION - 1, 0, {
                    label: 'SNP Pre-qualification',
                    pageIndex: SUBPAGES.SNP_PREQUALIFICATION,
                });
                return newPageHeaders;
            });
        }
    }, []);

    const nextSubPage = () => {
        setPage((page) => page + 1);
    };
    const previousSubPage = () => {
        setPage((page) => page - 1);
    };
    const navigateSubPage = (pageNum, section = null) => {
        setPage(pageNum);
        if (section) {
            setGoToRef(section);
        }
    };

    return (
        <div>
            <ProcessSteps
                headerLabels={pageHeaders}
                currentPage={page}
                navigatePage={navigateSubPage}
            />
            <div className="mx-3">
                {page === SUBPAGES.APPLICANT_INFO && (
                    <ClientInform
                        onSubmit={nextSubPage}
                        previousPage={previousPage}
                        navigatePage={navigateSubPage}
                        goToRef={goToRef}
                        setGoToRef={setGoToRef}
                    />
                )}
                {page === SUBPAGES.CONFIRM_ELIGIBILITY && (
                    <AutoScrollTop>
                        <EligibilityVerification
                            onSubmit={nextSubPage}
                            previousPage={previousSubPage}
                        />
                    </AutoScrollTop>
                )}
                {page === SUBPAGES.CHOOSE_DOCTOR && (
                    <AutoScrollTop>
                        <DoctorSelection
                            onSubmit={nextSubPage}
                            previousPage={previousSubPage}
                        />
                    </AutoScrollTop>
                )}
                {page === SUBPAGES.ADDITIONAL_INFO && (
                    <AddInformation
                        onSubmit={
                            requiresSNPWorkflow
                                ? nextSubPage
                                : () => navigateSubPage(SUBPAGES.REVIEW)
                        }
                        previousPage={previousSubPage}
                        navigatePage={navigateSubPage}
                        goToRef={goToRef}
                        setGoToRef={setGoToRef}
                    />
                )}
                {page === SUBPAGES.SNP_PREQUALIFICATION && (
                    <AutoScrollTop>
                        <PreQualification
                            onSubmit={nextSubPage}
                            previousPage={previousSubPage}
                            navigatePage={navigateSubPage}
                        />
                    </AutoScrollTop>
                )}
                {page === SUBPAGES.REVIEW && (
                    <AutoScrollTop>
                        <ReviewEnrollment
                            onSubmit={onSubmit}
                            previousPage={previousSubPage}
                            navigatePage={navigatePage}
                            navigateSubPage={navigateSubPage}
                        />
                    </AutoScrollTop>
                )}
            </div>
        </div>
    );
};

const WizardFormFourthPage = reduxForm({
    form: 'wizard', //Form name is same
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
})(
    connect((state) => {
        return getFormValues('wizard')(state) || {};
    })(WizardFormFourthPageComponent)
);

export default WizardFormFourthPage;
