import React from 'react';

import { Button as ButtonBootstrap } from 'react-bootstrap';
import classnames from 'classnames';
import './button.scss';
import { useAppInsightContext } from '../../../contexts/appInsight';
import { APPINSIGHT_EVENTS } from '../../../constants/appInsightEvents';
import { isFunction } from 'lodash';

const Button = ({ children, className, ...rest }) => (
    <ButtonBootstrap {...rest} className={classnames('button', className)}>
        {children}
    </ButtonBootstrap>
);

const ButtonWithHrefTracking = ({ children, onClick, ...rest }) => {
    const appInsights = useAppInsightContext();

    const onClickWithAppInsights = (args) => {
        const { href } = rest;
        if (href && appInsights) {
            window.analytics.track('Click Outbound Link', {
                link: href,
            });
            appInsights.trackEvent({
                name: APPINSIGHT_EVENTS.LINK_CLICK_BUTTON,
                properties: {
                    href,
                },
            });
        }
        if (isFunction(onClick)) {
            onClick(args);
        }
    };

    return (
        <Button onClick={onClickWithAppInsights} {...rest}>
            {children}
        </Button>
    );
};

const NextButton = ({ className, ...rest }) => (
    <Button {...rest} className={classnames('next', className)} />
);
const BackButton = ({ className, ...rest }) => (
    <Button {...rest} className={classnames('back', className)} />
);

export { ButtonWithHrefTracking as default, NextButton, BackButton };
