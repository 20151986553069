import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import { Container, Row, Col, Spinner, Alert, Button } from 'react-bootstrap';
import { get, isEmpty, every, some, reduce } from 'lodash';
import moment from 'moment';
import WizardFormSelectPlan from '../form/components/Pages/SecondPage-SelectPlan';
import WizardFormOptionalBenfits from '../form/components/Pages/OptionalBenefitsPage/OptionalBenefitsPage';
import SelectionPlanSummary from '../form/components/Pages/SelectionPlanSummary';
import WizardFormEnroll from '../form/components/Pages/FourthPage-Enroll';
import ConfirmationPage from '../form/components/Pages/LastPage-Confirmation/LastPage-Confirmation';
import { INITIAL_VALUES } from '../constants/initialValues';
import WizardFormFirstPage from './Pages/FirstPage-FindPlan';
import { PAGES, SUBPAGES, YES_NO_OPTION } from '../constants/enum';
import { CACHE_ENROLLMENT } from '../../../api/enrollment';
import SelectionStatus from './Pages/SelectionStatusView';
import AutoScrollTop from './HOC/AutoScrollTop';
import useTrackSubmissionFLow from '../hooks/submission_track';
import { useAppInsightContext } from 'contexts/appInsight';
import { APPINSIGHT_EVENTS } from 'constants/appInsightEvents';
import {
    getCountyByZipcode,
    getPlansByZipCodeEnrollYear,
    getProviderDetails,
} from 'api/enrollment';
import { v4 as uuidv4 } from 'uuid';
import { ErrorBoundary, useErrorBoundary } from 'react-error-boundary';
import { useConfigContext } from 'contexts/config';

const shouldNotIncludeOptionalBenefits = (enrollment) =>
    isEmpty(get(enrollment, 'selectPlan.planSummary.optionalBenefitPlans[0]'));

const FallbackComponent = (error) => {
    const { GOLDEN_COMM_SHOPPING_URL } = useConfigContext();
    return (
        <div className="d-flex justify-content-center">
            <Alert show={error} variant="danger" className="mt-5 w-50">
                There was a network error and the application could not be
                processed.
                <br />
                Please start the process from the beginning.
                <div className="text-center pt-3">
                    <Button variant="danger" href={GOLDEN_COMM_SHOPPING_URL}>
                        Start Over
                    </Button>
                </div>
            </Alert>
        </div>
    );
};
const WizardFormComponent = ({
    change,
    onSubmit,
    enrollment,
    confirmationNo,
}) => {
    const [page, setPage] = useState(PAGES.FIND_PLAN);
    const [subPage, setSubPage] = useState(SUBPAGES.APPLICANT_INFO);
    const [isLoading, setIsLoading] = useState(false);
    const appInsights = useAppInsightContext();
    const queryString = new URL(window.location.href).searchParams;
    const requestParams = Object.fromEntries(queryString);
    const { showBoundary } = useErrorBoundary();

    useTrackSubmissionFLow(page);

    useEffect(() => {
        window.addEventListener('beforeunload', resetEnrollmentForm);

        if (!isEmpty(requestParams)) {
            preloadEnrollmentState();
            change('hasGoldenCommmOrigin', true);
        }

        return () => {
            resetEnrollmentForm();
            window.removeEventListener('beforeunload', resetEnrollmentForm);
        };
    }, []);

    const populateAepFlags = ({ allowAEPEnrollments, isPreAEP }, change) => {
        change('enrollment.findPlan.allowAEPEnrollments', allowAEPEnrollments);
        change('enrollment.findPlan.isPreAEP]', isPreAEP);
    };
    const preloadEnrollmentState = () => {
        setIsLoading(true);
        change('enrollment.applicationId', uuidv4());
        change('enrollment.findPlan.coverageYear', requestParams.coverageYear);
        change('enrollment.findPlan.zipCode', requestParams.zipCode);

        // Coverage Questions
        const ExternalCoverageKeys = [
            'ReceiveVeterans',
            'HasGroupRetireeCoverage',
        ];
        if (some(ExternalCoverageKeys, (key) => requestParams[key] === 'Yes')) {
            change(
                'enrollment.enrolling.additionalInfo.questions[0].response',
                YES_NO_OPTION.Yes.toString()
            );
        } else if (
            every(ExternalCoverageKeys, (key) => requestParams[key] === 'No')
        ) {
            change(
                'enrollment.enrolling.additionalInfo.questions[0].response',
                YES_NO_OPTION.No.toString()
            );
        }
        const ChronicConditionKeys = [
            'HasDiabetes',
            'HasHeartConditions',
            'HasESRD',
        ];
        if (some(ChronicConditionKeys, (key) => requestParams[key] === 'Yes')) {
            change(
                'enrollment.enrolling.additionalInfo.questions[1].response',
                YES_NO_OPTION.Yes.toString()
            );
        } else if (
            every(ChronicConditionKeys, (key) => requestParams[key] === 'No')
        ) {
            change(
                'enrollment.enrolling.additionalInfo.questions[1].response',
                YES_NO_OPTION.No.toString()
            );
        }

        if (requestParams['HasMedicaidCoverage'] === 'Yes') {
            change(
                'enrollment.enrolling.additionalInfo.questions[3].response',
                YES_NO_OPTION.Yes.toString()
            );
        } else if (requestParams['HasMedicaidCoverage'] === 'No') {
            change(
                'enrollment.enrolling.additionalInfo.questions[3].response',
                YES_NO_OPTION.No.toString()
            );
        }

        if (requestParams['providerId']) {
            const providerId = requestParams['providerId'];
            getProviderDetails('', '', '', '', '', '', '', providerId)
                .then((data) => {
                    const provider = get(data, 'providers', [])[0];

                    if (provider) {
                        if (provider.ahRecords) {
                            const { addresses, ipa } = provider.ahRecords[0];
                            provider.ipaId = ipa.id;
                            provider.ipaName = ipa.desc;
                            provider.phone = addresses[0].phone;
                        }

                        const { firstName, middleName, lastName } =
                            provider.nppes;
                        provider.provID = providerId;
                        provider.firstName = firstName;
                        provider.mi = middleName;
                        provider.lastName = lastName;
                        change(
                            'enrollment.selectPlan.physicianNPI',
                            provider.npi
                        );
                        change('enrollment.selectPlan.physicianInfo', provider);
                    }
                })
                .catch((error) => {
                    showBoundary(error);
                });
        }

        const getPlans = getPlansByZipCodeEnrollYear(
            requestParams.zipCode,
            requestParams.coverageYear,
            requestParams.hcode,
            requestParams.pbp
        );

        const getCounty = getCountyByZipcode(requestParams.zipCode);

        Promise.all([getPlans, getCounty])
            .then(([planData, countyData]) => {
                change(
                    'enrollment.selectPlan.planSummary',
                    planData.planSummary[0]
                );
                change(
                    'enrollment.selectPlan.plan',
                    planData.planSummary[0].planName
                );
                if (planData.planSummary[0].hasOptionalBenefitsPlans) {
                    setPage(PAGES.OPTIONAL_BENEFITS_PLAN);
                } else {
                    setPage(PAGES.SUMMARY);
                }

                const { allowAEPEnrollments, isPreAEP } = get(
                    countyData,
                    'countyandPlanYear',
                    {
                        allowAEPEnrollments: false,
                        isPreAEP: false,
                    }
                );

                populateAepFlags({ allowAEPEnrollments, isPreAEP }, change);

                const combinedCounties = reduce(
                    get(countyData, 'countyandPlanYear.counties', []),
                    (cumu, curr) => {
                        return [...cumu, curr.countyName];
                    },
                    []
                );

                change(
                    'enrollment.findPlan.counties',
                    combinedCounties
                        .map((county) => `${county} County`)
                        .join(', ')
                );
                setIsLoading(false);
            })
            .catch((error) => {
                showBoundary(error);
            });

        appInsights.trackEvent({
            name: APPINSIGHT_EVENTS.GOLDENCOMM_REQUEST_PARAMS,
            properties: {
                queryString: queryString.toString(),
                requestParams,
            },
        });
    };
    const submitApplication = async (event) => {
        nextPage();
        try {
            const response = await onSubmit(
                enrollment,
                moment().format('YYYY-MM-DDTHH:mm:ssZ')
            );
            await change('confirmationNo', get(response, 'referenceNo'));
        } catch (err) {
            await change('confirmationNo', 'ERROR');
        }
    };
    const nextPage = useCallback(() => {
        if (
            (page === PAGES.SELECT_PLAN || page === SUBPAGES.COMPARE_PLAN) &&
            shouldNotIncludeOptionalBenefits(enrollment)
        ) {
            setPage(PAGES.SUMMARY);
        } else {
            setPage(page + 1);
        }
        if (page + 1 === PAGES.ENROLLMENT) {
            setSubPage(SUBPAGES.APPLICANT_INFO);
        }
    }, [enrollment, page]);

    const previousPage = useCallback(() => {
        if (
            page === PAGES.SUMMARY &&
            shouldNotIncludeOptionalBenefits(enrollment)
        ) {
            setPage(PAGES.SELECT_PLAN);
        } else {
            setPage(page - 1);
        }
    }, [enrollment, page]);

    const goToPage = (pageNum, subPageNum = SUBPAGES.APPLICANT_INFO) => {
        setPage(pageNum);
        setSubPage(subPageNum);
    };

    const resetEnrollmentForm = () => {
        caches.delete(CACHE_ENROLLMENT);
    };

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center py-5">
                <Spinner animation="border" />
            </div>
        );
    }

    return (
        <div className="ahc-enrollment">
            {/* This div with id="null" serves to be a target for Syncfusion's DatePickerComponent used in renderField.js */}
            <div id="null"></div>

            <Container fluid>
                {[PAGES.OPTIONAL_BENEFITS_PLAN, PAGES.ENROLLMENT].indexOf(
                    page
                ) > -1 && (
                    <Row>
                        <Col>
                            <SelectionStatus navigatePage={goToPage} />
                        </Col>
                    </Row>
                )}
                <Row className="justify-content-md-center pb-5 wizard-wrapper-row">
                    <Col>
                        {page === PAGES.FIND_PLAN && (
                            <AutoScrollTop>
                                <WizardFormFirstPage onSubmit={nextPage} />
                            </AutoScrollTop>
                        )}
                        {page === PAGES.SELECT_PLAN && (
                            <AutoScrollTop>
                                <WizardFormSelectPlan
                                    previousPage={previousPage}
                                    onSubmit={nextPage}
                                    navigatePage={goToPage}
                                    subPage={subPage}
                                    updateSubPage={setSubPage}
                                />
                            </AutoScrollTop>
                        )}
                        {page === PAGES.OPTIONAL_BENEFITS_PLAN && (
                            <AutoScrollTop>
                                <WizardFormOptionalBenfits
                                    previousPage={previousPage}
                                    onSubmit={nextPage}
                                    navigatePage={goToPage}
                                />
                            </AutoScrollTop>
                        )}
                        {page === PAGES.SUMMARY && (
                            <AutoScrollTop>
                                <SelectionPlanSummary
                                    previousPage={previousPage}
                                    onSubmit={nextPage}
                                />
                            </AutoScrollTop>
                        )}
                        {page === PAGES.ENROLLMENT && (
                            <WizardFormEnroll
                                previousPage={previousPage}
                                onSubmit={submitApplication}
                                subPage={subPage}
                                navigatePage={goToPage}
                                updateSubPage={setSubPage}
                            />
                        )}
                        {page === PAGES.CONFIRMATION && (
                            <AutoScrollTop>
                                <ConfirmationPage />
                            </AutoScrollTop>
                        )}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const WizardFormWithReduxForm = reduxForm({
    form: 'wizard', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    initialValues: INITIAL_VALUES,
})(WizardFormComponent);

const WizardFormWithRedux = connect((state) => {
    return {
        ...getFormValues('wizard')(state),
    };
})(WizardFormWithReduxForm);

const WizardFormWithErrorBoundary = (props) => {
    return (
        <ErrorBoundary FallbackComponent={FallbackComponent}>
            <WizardFormWithRedux {...props} />
        </ErrorBoundary>
    );
};
export default WizardFormWithErrorBoundary;
