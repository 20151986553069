import React, { ReactElement } from 'react';
import AdditionalQuestionInputType from '../../../../enums/AdditionalQuestionInputType';
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import {
    RenderDatePicker,
    RenderItemSelector,
} from '../../actions/renderField';
import { connect } from 'react-redux';
import {
    CommonInputProps,
    DatePickerProps,
    FileUploadProps,
    SelectProps,
} from 'types/Input';
import { pick } from 'lodash';
import UploadDocument from './UploadDocument';

type AdditionalQuestionProps = {
    enrollment: Record<string, unknown>;
    name: string;
    type: AdditionalQuestionInputType;
    dateProps?: DatePickerProps;
    selectProps?: SelectProps;
    fileUploadProps?: FileUploadProps;
};

const COMMON_INPUT_PROPS = ['id', 'required', 'label', 'disabled'];

let AdditionalQuestionComponent = ({
    enrollment,
    change,
    name,
    type,
    dateProps,
    selectProps,
    fileUploadProps,
    ...rest
}: InjectedFormProps<{}, AdditionalQuestionProps & CommonInputProps> &
    AdditionalQuestionProps &
    CommonInputProps) => {
    let question: ReactElement | null = null;
    const saveFormattedDate = (path, value) => {
        change(path, value);
    };

    // filter out the action props passed by redux-form
    const commonInputProps = pick(rest, COMMON_INPUT_PROPS);

    switch (type) {
        case AdditionalQuestionInputType.Date:
            question = (
                <Field
                    name={name}
                    type="date"
                    component={RenderDatePicker}
                    props={{
                        ...commonInputProps,
                        ...dateProps,
                        saveFormattedDate,
                    }}
                />
            );
            break;
        case AdditionalQuestionInputType.Select:
            question = (
                <Field
                    name={name}
                    type="select"
                    component={RenderItemSelector}
                    props={{
                        ...commonInputProps,
                        ...selectProps,
                    }}
                />
            );
            break;
        case AdditionalQuestionInputType.FileUpload:
            question = (
                <Field
                    name={name}
                    component={UploadDocument}
                    props={{
                        ...fileUploadProps,
                        change,
                        enrollment,
                        name,
                    }}
                />
            );
    }
    return question;
};

const AdditionalQuestion = reduxForm<
    {},
    AdditionalQuestionProps & CommonInputProps
>({
    form: 'wizard', //Form name is same
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(
    connect((state) => {
        return getFormValues('wizard')(state);
    })(AdditionalQuestionComponent)
);

export default AdditionalQuestion;
