export const DIABETES_SURVEY = {
    diabetes1:
        'Have you been told by a doctor that you have diabetes (too much sugar in the blood or urine)?',
    diabetes2:
        'Have you ever been prescribed or are you taking insulin or an oral medication that is supposed to lower the sugar in your blood?',
};
export const CARDIOVASCULAR_SURVEY = {
    cardiovascular1:
        'Have you ever been told by a doctor that you have coronary artery disease, poor circulation due to hardening of the arteries or poor veins?',
    cardiovascular2:
        'Have you ever had a heart attack or been admitted to the hospital for Angina(chest pain)?',
};
export const HEART_FAILURE_SURVEY = {
    chronicHeart1:
        'Have you ever been told by a doctor that you have heart failure (weak heart)?',
    chronicHeart2:
        'Have you ever had problems with fluid in your lungs and swelling in your legs in the past, accompanied by shortness of breath, due to a heart problem?',
};

export const LUNG_DISORDER_SURVEY = {
    lungDisorder1:
        'Have you ever been told by a health care provider that you have one of the following chronic lung conditions?',
    lungDisorder2: 'Have you smoked for over 15 or more years?',
    lungDisorder3: 'Do you/Did you smoke 20 or more packs a year?',
    lungDisorder4: 'Do you need to use oxygen to help you breathe?',
};
export const LUNG_DISORDER_CONDITIONS = [
    { label: 'Asthma', value: 'AS' },
    { label: 'Chronic Bronchitis', value: 'CB' },
    { label: 'Emphysema', value: 'EM' },
    {
        label: 'COPD (Chronic Obstructive Pulmonary Disease)',
        value: 'COPD',
    },
    { label: 'Pulmonary Fibrosis', value: 'PF' },
    { label: 'Pulmonary Hypertension', value: 'PH' },
    { label: 'None of the Above', value: 'NULL' },
];
export const MENTAL_HEALTH_SURVEY = {
    mentalHealth1:
        'Have you ever been told by a doctor that you have one of the following mental health conditions?',
    mentalHealth2:
        'Have you been prescribed medication by a Psychiatrist on a routine basis?',
    mentalHealth3:
        'Have you ever been prescribed medication that is to help stabilize or improve your mood?',
    mentalHealth4: 'Have you been prescribed an antipsychotic medication?',
};
export const MENTAL_HEALTH_CONDITIONS = [
    { label: 'Bipolar Disorder', value: 'BD' },
    { label: 'Major Depressive Disorder', value: 'MDD' },
    { label: 'Paranoid Disorder', value: 'PD' },
    { label: 'Schizoaffective Disorder', value: 'SD' },
    { label: 'Schizophrenia', value: 'SC' },
    { label: 'None of the Above', value: 'NULL' },
];

export const ESRD_SURVEY = {
    esrd1: 'Are you currently undergoing Peritoneal dialysis?',
    esrd2: 'Are you currently undergoing Hemodialysis?',
};
