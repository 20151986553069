import { API_ENDPOINT, getLocalTimeHeader } from './api';
import { isNil, defaultTo } from 'lodash';

const urlGetPlansByZipCodeEnrollYear = '/Plan/GetPlansByZipCodeEnrollYear';
const urlGetProviderDetails = '/Provider/GetProviderDetails';
const urlGetEnrollmentDocuments = '/Enrollment/GetEnrollmentDocuments';
const urlDeleteEnrollmentDocument = '/Enrollment/DeleteEnrollmentDocument';
const urlGetCountyByZipcode = '/Plan/GetCountyByZipcode';
const urlSubmitEnrollment = '/Enrollment/SubmitEnrollment';
const urlGetSinglePlanFromGoldenComm = '/Plan/GetSinglePlanFromGoldenComm';

const getSearchParamsString = (obj) => new URLSearchParams(obj).toString();

const CACHE_ENROLLMENT = 'submit-enrollment';

const getPlansByZipCodeEnrollYear = async (
    zipcode,
    coverageYear,
    contractcode = '',
    pbp = ''
) => {
    const url = `${API_ENDPOINT}${urlGetPlansByZipCodeEnrollYear}?${getSearchParamsString(
        {
            zipcode,
            coverageYear,
            contractcode,
            pbp,
        }
    )}`;

    const cache = await caches.open(CACHE_ENROLLMENT);
    const cachedResponse = await cache.match(url, {});

    if (isNil(cachedResponse)) {
        const response = fetch(url, {
            method: 'GET',
        }).then((response) => {
            if (response.ok) {
                cache.put(url, response.clone());
            }
            return response.json();
        });

        return response;
    }

    return cachedResponse.json();
};

const getProviderDetails = (
    Zipcode = '',
    PBP = '',
    CoverageYear = '',
    ContractCode = '',
    SearchTerm = '',
    PageIndex = 1,
    PageSize = 6,
    ProviderId = ''
) => {
    const url = `${API_ENDPOINT}${urlGetProviderDetails}?${getSearchParamsString(
        {
            Zipcode,
            PBP,
            CoverageYear,
            ContractCode,
            SearchTerm,
            PageIndex,
            PageSize,
            ProviderId,
        }
    )}`;
    const response = fetch(url, {
        method: 'GET',
    }).then((response) => response.json());
    return response;
};

const deleteEnrollmentDocument = (documentID, applicationID) => {
    const headers = { DocumentID: documentID, ApplicationID: applicationID };
    const url = `${API_ENDPOINT}${urlDeleteEnrollmentDocument}`;
    const response = fetch(url, {
        method: 'POST',
        headers,
    });
    return response;
};
const getEnrollmentDocumentsUrl = (applicationId, documentId, documentType) => {
    return `${API_ENDPOINT}${urlGetEnrollmentDocuments}?${getSearchParamsString(
        {
            applicationId,
            documentId,
            documentType,
        }
    )}`;
};

const getCountyByZipcode = (zipcode) => {
    const headers = getLocalTimeHeader();
    const url = `${API_ENDPOINT}${urlGetCountyByZipcode}?${getSearchParamsString(
        {
            zipcode,
        }
    )}`;
    const response = fetch(url, {
        method: 'GET',
        headers,
    }).then((response) => response.json());
    return response;
};
const submitEnrollmentApplication = (enrollment) => {
    const url = `${API_ENDPOINT}${urlSubmitEnrollment}`;
    const response = fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(enrollment),
    })
        .then((data) => {
            if (data.status >= 400) {
                throw `ERROR STATUS: ${data.status}`;
            }
            return data.json();
        })
        .catch((err) => {
            throw err;
        });
    return response;
};

const getCMSEligibilityDetail = (medicareID, lastName, dateOfBirth) => {
    const queryParamStr = new URLSearchParams({
        HICNumber: defaultTo(medicareID, ''),
        lastName: defaultTo(lastName, ''),
        birthDate: defaultTo(dateOfBirth, ''),
    }).toString();

    const url = `api/Enrollment/GetCMSEligibilityDetail?${queryParamStr}`;
    const response = fetch(url, {
        method: 'GET',
    }).then((response) => response.json());
    return response;
};

const getEnrollmentFieldOptions = async (optionsField) => {
    return await fetch(
        `api/enrollment/GetEnrollmentFieldOptions?${getSearchParamsString({
            optionsField,
        })}`,
        {
            method: 'GET',
        }
    );
};

export {
    getPlansByZipCodeEnrollYear,
    getProviderDetails,
    getEnrollmentDocumentsUrl,
    CACHE_ENROLLMENT,
    getCountyByZipcode,
    deleteEnrollmentDocument,
    submitEnrollmentApplication,
    getCMSEligibilityDetail,
    getEnrollmentFieldOptions,
};
