import React, { useCallback, useEffect, useState } from 'react';
import PropType from 'prop-types';
import Pagination from 'react-bootstrap/Pagination';
import { noop } from 'lodash';
import './pagination.scss';

const PaginationView = ({
    className,
    totalRecordsCount,
    pageSize,
    pageCount,
    goToPage,
    currentPage,
    onLoad,
}) => {
    const [maxPage, setMaxPage] = useState(
        Math.ceil(totalRecordsCount / pageSize)
    );

    useEffect(() => {
        setMaxPage(Math.ceil(totalRecordsCount / pageSize));
    }, [totalRecordsCount, pageSize]);
    useEffect(() => {
        onLoad({
            totalPages: maxPage,
        });
    }, [maxPage]);
    const numPagesToShow = Math.min(pageCount, maxPage);
    const numPagesPadding = Math.floor(numPagesToShow / 2);

    const goBackOnePage = useCallback(() => {
        goToPage(Math.max(currentPage - 1, 1));
    }, [currentPage, goToPage]);
    const goForwardOnePage = useCallback(() => {
        goToPage(Math.min(currentPage + 1, maxPage));
    }, [currentPage, goToPage, maxPage]);

    const goToFirstPage = useCallback(() => goToPage(1), [goToPage]);
    const goToLastPage = useCallback(() => goToPage(maxPage), [
        goToPage,
        maxPage,
    ]);

    const renderNumberofPages = () => {
        const paginationPlan = [];
        paginationPlan.push(
            <Pagination.Item
                key={`page_${1}`}
                onClick={() => {
                    goToPage(1);
                }}
                active={currentPage === 1}
            >
                {1}
            </Pagination.Item>
        );

        const fromPage = Math.max(
            Math.min(
                currentPage - numPagesPadding,
                maxPage - numPagesToShow + 1
            ),
            2
        );

        const toPage = Math.max(
            Math.min(currentPage + numPagesPadding, maxPage - 1),
            numPagesToShow - 1
        );

        if (fromPage > 2) {
            paginationPlan.push(
                <Pagination.Ellipsis
                    key="page_start_ellipse"
                    onClick={() => {
                        goToPage(Math.max(fromPage - numPagesPadding, 1));
                    }}
                />
            );
        }
        for (let pageNum = fromPage; pageNum <= toPage; pageNum++) {
            paginationPlan.push(
                <Pagination.Item
                    key={`page_${pageNum}`}
                    onClick={() => {
                        goToPage(pageNum);
                    }}
                    active={currentPage === pageNum}
                >
                    {pageNum}
                </Pagination.Item>
            );
        }

        if (toPage < maxPage - 1) {
            paginationPlan.push(
                <Pagination.Ellipsis
                    key="page_end_ellipse"
                    onClick={() => {
                        goToPage(Math.min(toPage + numPagesPadding, maxPage));
                    }}
                />
            );
        }
        if (maxPage > 1) {
            paginationPlan.push(
                <Pagination.Item
                    key={`page_${maxPage}`}
                    onClick={() => {
                        goToPage(maxPage);
                    }}
                    active={currentPage === maxPage}
                >
                    {maxPage}
                </Pagination.Item>
            );
        }
        return paginationPlan;
    };

    if (totalRecordsCount === 0) {
        return null;
    }
    return (
        <Pagination className={className}>
            <Pagination.First
                onClick={goToFirstPage}
                disabled={currentPage === 1}
            />
            <Pagination.Prev
                onClick={goBackOnePage}
                disabled={currentPage === 1}
            />
            {renderNumberofPages()}
            <Pagination.Next
                onClick={goForwardOnePage}
                disabled={currentPage === maxPage}
            />
            <Pagination.Last
                onClick={goToLastPage}
                disabled={currentPage === maxPage}
            />
        </Pagination>
    );
};
PaginationView.propTypes = {
    className: PropType.string,
    totalRecordsCount: PropType.number,
    pageSize: PropType.number,
    pageCount: PropType.number,
    goToPage: PropType.func,
    currentPage: PropType.number,
    onLoad: PropType.func,
};
PaginationView.defaultProps = {
    className: '',
    totalRecordsCount: 0,
    pageSize: 10,
    pageCount: 10,
    goToPage: noop,
    currentPage: 1,
    onLoad: noop,
};

export default PaginationView;
