import React, { useCallback, useState } from 'react';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { duplicateAttributes, removeAttributes } from '../../../util/format';

const AccessibleDatePicker = (props) => {
    const dateInputRef = React.useRef();
    const [dummyPopup, setDummyPopup] = useState({ id: null, show: true });

    const onCreated = useCallback(() => {
        // Redundant aria attributes (disabled - aria-disabled, placeholder - aria-placeholder)
        const ariaControlId = dateInputRef.current.element.getAttribute(
            'aria-owns'
        );
        duplicateAttributes(dateInputRef.current.element, 'aria-owns', [
            'aria-controls',
        ]);
        removeAttributes(dateInputRef.current.element, [
            'aria-disabled',
            'aria-placeholder',
            'aria-owns',
            'aria-activedescendant',
        ]);

        setDummyPopup({
            show: true,
            id: ariaControlId,
        });
    }, []);
    const onFocus = useCallback(
        () => setDummyPopup({ ...dummyPopup, show: false }),
        [dummyPopup.id]
    );
    const onClose = useCallback(() => {
        removeAttributes(dateInputRef.current.element, [
            'aria-activedescendant',
        ]);

        setDummyPopup({ ...dummyPopup, show: true });
    }, [dummyPopup.id]);

    return (
        <>
            <DatePickerComponent
                {...props}
                ref={dateInputRef}
                created={onCreated}
                close={onClose}
                onFocus={onFocus}
                className="date-picker"
            />
            {dummyPopup.show && <div id={dummyPopup.id}></div>}
        </>
    );
};
export default AccessibleDatePicker;
