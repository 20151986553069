import { PAY_OPTION, YES_NO_OPTION } from '../constants/enum';
import { GENDER_MAP, RELATIONSHIP_MAP } from '../constants/options';

const translateBool = (value) => {
    switch (parseInt(value)) {
        case YES_NO_OPTION['Yes']:
            return 'Yes';
        case YES_NO_OPTION['No']:
            return 'No';
        case YES_NO_OPTION['Not Sure']:
            return 'Not Sure';
        default:
            return 'N/A';
    }
};
const translateRelation = (value) => {
    if (value) {
        const relationshipValue = parseInt(value);
        for (const [name, optionValue] of Object.entries(RELATIONSHIP_MAP)) {
            if (optionValue === relationshipValue) {
                return name;
            }
        }
    }
    return '';
};
const translatePayment = (value) => {
    switch (parseInt(value)) {
        case PAY_OPTION.RECEIVE_BILL:
            return 'Get a Bill';
        case PAY_OPTION.DEDUCT_FROM_CHECK:
            return 'Automatic deduction from your monthly Social Security or Railroad Retirement Board (RRB) benefits check. (The Social Security/RRB deduction may take two or more months to begin after Social Security or RRB approves the deduction. In most cases, if Social Security or RRB accepts your request for automatic deduction, the first deduction from your Social Security or RRB benefit check will include all premiums due from your enrollment effective date up to the point withholding begins. If Social Security or RRB does not approve your request for automatic deduction, we will send you a paper bill for your monthly premiums.';
        default:
            break;
    }
    return;
};
const translateGender = (value) => {
    if (value) {
        let genderEnumValue = parseInt(value);
        for (const [name, optionValue] of Object.entries(GENDER_MAP)) {
            if (optionValue === genderEnumValue) {
                return name;
            }
        }
    }
    return '';
};
export { translateBool, translatePayment, translateRelation, translateGender };
