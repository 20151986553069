import React, { useState, useEffect } from 'react';
import { Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { isEmpty, get } from 'lodash';
import { Alert } from 'react-bootstrap';
import { formatCost } from '../../../../util/format';
import PrintButton from '../../../../../Common/PrintButton/PrintButton';
import Button from '../../../../../Common/Button/Button';
import { ReactComponent as IconFileComplete } from '../../../../../../assets/img/icon_file_complete.svg';
import './lastPage-Confirmation.scss';
import { useConfigContext } from '../../../../../../contexts/config';
import { useAppInsightContext } from '../../../../../../contexts/appInsight';
import { PAGES, PAGE_NAME } from '../../../../constants/enum';

const NextSteps = () => {
    const { PLAN_DOCUMENTS_URL } = useConfigContext();
    return (
        <div className="next-steps">
            <div className="step">
                <h5>What happens next</h5>
                <p>
                    We will process your application and send you a letter when
                    your enrollment has been approved. Once your application is
                    approved, your new Alignment Health Plan member ID card will
                    arrive in the mail within 7-10 business days.
                </p>
            </div>
            <div className="step">
                <h5>Member Forms &amp; Resources</h5>
                <p>
                    Alignment Health Plan member forms and resources are
                    available online for free! You may download and save these
                    documents for future reference.
                    <br />
                    <Button href={PLAN_DOCUMENTS_URL} target="_new">
                        View Member Forms &amp; Resources
                    </Button>
                </p>
            </div>
        </div>
    );
};

const ConfirmationPageComponent = (props) => {
    const { enrollment, confirmationNo, documents } = props;
    const [loadingState, setLoadingState] = useState(isEmpty(confirmationNo));
    const appInsights = useAppInsightContext();

    useEffect(() => {
        setLoadingState(isEmpty(confirmationNo));
    }, [confirmationNo, documents]);
    useEffect(() => {
        if (!isEmpty(confirmationNo)) {
            window.analytics.track('Received Confirmation Code', {
                success: confirmationNo !== 'ERROR',
                value: confirmationNo,
            });
        }
    }, [confirmationNo]);

    useEffect(() => {
        window._mfq.push(['newPageView', 'ConfirmationPage']);
        window.analytics.page('Confirmation');

        appInsights.startTrackPage(PAGE_NAME[PAGES.CONFIRMATION]);
        return () => {
            appInsights.stopTrackPage(PAGE_NAME[PAGES.CONFIRMATION], null, {
                applicationId: get(enrollment, 'applicationId'),
            });
        };
    }, []);

    if (loadingState) {
        return (
            <div className="box text-center">
                <h4>Submitting Application...</h4>
                <Spinner />
            </div>
        );
    }

    const selectPlanText = `${
        enrollment.selectPlan.planSummary.planName
    } - Plan Year ${enrollment.findPlan.coverageYear} - ${formatCost(
        parseFloat(enrollment.selectPlan.planSummary.premiumAmount)
    )}`;
    const selectOptionalPlanText = !enrollment.selectPlan
        .optionalPlanName ? null : (
        <>
            <span className="choice">
                {enrollment.selectPlan.optionalPlanName}
            </span>{' '}
            -{' '}
            {formatCost(
                parseFloat(
                    enrollment.selectPlan.optionalPlanSummary.premiumAmount
                )
            )}
        </>
    );
    const monthlyPremiumTotalText = formatCost(
        parseFloat(enrollment.selectPlan.planSummary.premiumAmount) +
            parseFloat(
                get(
                    enrollment,
                    'selectPlan.optionalPlanSummary.premiumAmount',
                    '0'
                )
            )
    );

    if (confirmationNo === 'ERROR') {
        return (
            <div className="last-page-confirmation text-left mx-md-5 px-md-5">
                <div className="choices">
                    <Alert variant="danger" className="mt-5">
                        There was a network error and the application could not
                        be processed.
                        <br />
                        Please contact the system administrator.
                    </Alert>
                </div>
            </div>
        );
    }

    return (
        <div className="last-page-confirmation text-left mx-md-5 px-md-5">
            <div className="print-button-wrapper">
                <PrintButton
                    label="Print this page"
                    onClick={() => {
                        window.analytics.track('Print Confirmation', {
                            confirmationNo: confirmationNo,
                        });
                    }}
                />
            </div>
            <h1>
                <IconFileComplete className="d-none d-md-block icon" />
                We've received your application
            </h1>
            <div className="choices">
                <p>
                    Thank you for choosing:{' '}
                    <span className="choice">{selectPlanText}</span>
                    {!selectOptionalPlanText && '.'}
                </p>
                {enrollment.selectPlan.optionalPlanName && (
                    <p>with {selectOptionalPlanText}</p>
                )}
                <p className="total">
                    Your total monthly premium will be {monthlyPremiumTotalText}
                </p>
            </div>

            <span className="choice confirmation-number">
                Confirmation number: {confirmationNo}
            </span>

            <NextSteps />
        </div>
    );
};
const ConfirmationPage = connect((state) => {
    return getFormValues('wizard')(state) || {};
})(ConfirmationPageComponent);
export default ConfirmationPage;
