import { cloneDeep } from 'lodash';
import { INITIAL_VALUES } from '../../constants/initialValues';
import validateFindPlan from './Validation/FindPlan-Validate';
import validateEnrolling from './Validation/Enrolling-Validate';
import validateReview from './Validation/Review-Validate';
import validateSelectPlan from './Validation/SelectPlan-Validate';

const validate = (values) => {
    const errors = cloneDeep(INITIAL_VALUES);

    // Convert Initial Default Values
    errors.enrollment.enrolling.clientInfo.memberInformationDelivery = null;

    if (values.enrollment) {
        if (values.enrollment.findPlan) {
            validateFindPlan(values, errors);
        }

        if (values.enrollment.selectPlan) {
            validateSelectPlan(values, errors);
        }
        if (values.enrollment.enrolling) {
            validateEnrolling(values, errors);
        }
        if (values.enrollment.review) {
            validateReview(values, errors);
        }
    }
    return errors;
};

export default validate;
