import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Field, reduxForm, getFormValues } from 'redux-form';
import classnames from 'classnames';
import PlanPreview from '../../Plans/PlanPreview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { PAGES, SUBPAGES, PAGE_NAME } from '../../../../constants/enum';
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    ButtonDropdown,
} from 'reactstrap';
import { get } from 'lodash';
import LinkButton from '../../../../components/common/LinkButton';
import { useAppInsightContext } from '../../../../../../contexts/appInsight';
import './selectPlans.scss';
import './comparePlan.scss';

let ComparePlans = ({
    HealthPlans,
    comparePlans,
    change,
    navigateSub,
    enrollment,
    navigatePage,
}) => {
    const loadSelectedPlans = () => {
        return HealthPlans.filter((healthPlan) => {
            return comparePlans[`${healthPlan.planName}`];
        });
    };

    const loadNotSelectedPlans = () => {
        return HealthPlans.filter((healthPlan) => {
            return !comparePlans[`${healthPlan.planName}`];
        });
    };

    const [comparedPlans, setComparedPlans] = useState(loadSelectedPlans);
    const [otherPlans, setOtherPlans] = useState(loadNotSelectedPlans);
    const [buttonOpen, setButonOpen] = useState(false);
    const appInsights = useAppInsightContext();
    const selectMessage = '+ Add Plans to Compare';

    useEffect(() => {
        window._mfq.push(['newPageView', 'ComparePlansPage']);
        window.analytics.page('Compare Plans');

        appInsights.startTrackPage(
            PAGE_NAME[PAGES.SELECT_PLAN[SUBPAGES.COMPARE_PLAN]]
        );

        return () => {
            appInsights.stopTrackPage(
                PAGE_NAME[PAGES.SELECT_PLAN[SUBPAGES.COMPARE_PLAN]],
                null,
                {
                    applicationId: get(enrollment, 'applicationId'),
                }
            );
        };
    }, []);

    const toggleDrop = () => {
        setButonOpen(!buttonOpen);
    };

    const removePlan = (planToRemove) => {
        var newComparedPlans = comparedPlans.filter((healthPlan) => {
            return healthPlan.planName != planToRemove;
        });
        setComparedPlans(newComparedPlans);
        var newNotSelectedPlan = HealthPlans.filter((healthPlan) => {
            return healthPlan.planName == planToRemove;
        });
        setOtherPlans([...otherPlans, newNotSelectedPlan[0]]);
    };

    const addPlan = (planName) => {
        var newPlan = HealthPlans.filter((healthPlan) => {
            return healthPlan.planName == planName;
        });
        if (newPlan) {
            setComparedPlans([...comparedPlans, newPlan[0]]);
            var newNotSelectedPlans = otherPlans.filter((healthPlan) => {
                return healthPlan.planName != planName;
            });

            setOtherPlans(newNotSelectedPlans);
        }
    };

    const savePlan = (index) => {
        const thisPlan = comparedPlans[index];
        change('enrollment.selectPlan.planSummary', thisPlan);
        if (get(enrollment, 'selectPlan.plan') !== thisPlan.planName) {
            change('enrollment.selectPlan.plan', thisPlan.planName);
            // flush PCP
            change('enrollment.selectPlan.physicianInfo', null);
            change('enrollment.selectPlan.physicianNPI', null);
            // flus optional benefits
            change('enrollment.selectPlan.optionalPlanName', null);
            change('enrollment.selectPlan.optionalPlanSummary', null);
        }
    };
    const countyString = get(enrollment, 'findPlan.counties', null);

    const ComparePlansDropdown = () => {
        return (
            <ButtonDropdown isOpen={buttonOpen} toggle={() => toggleDrop()}>
                <DropdownToggle caret disabled={comparedPlans.length >= 3}>
                    {selectMessage}
                </DropdownToggle>
                <DropdownMenu>
                    {otherPlans.map((healthPlan, i) => {
                        return (
                            <DropdownItem
                                key={`otherPlan_${i}`}
                                onClick={() => addPlan(healthPlan.planName)}
                                data-testid={`otherPlan_${i}`}
                            >
                                {healthPlan.planName}
                            </DropdownItem>
                        );
                    })}
                </DropdownMenu>
            </ButtonDropdown>
        );
    };

    return (
        <div className="select-plans compare">
            <div className="py-2 m-0 header">
                <div className="col-md">
                    <h1>
                        Compare Selected Plans in zip code{' '}
                        <span>
                            <LinkButton
                                className="clickable zip-county-btn"
                                onClick={() => navigatePage(PAGES.FIND_PLAN)}
                            >
                                <span className="ml-1">
                                    {enrollment.findPlan['zipCode']}
                                </span>
                                <span className="county ml-2">
                                    {countyString && `(${countyString})`}
                                </span>
                            </LinkButton>
                        </span>
                    </h1>
                </div>
            </div>
            <Row role="navigation" className="widgets">
                <Col md="5" className="widget">
                    <span>
                        <LinkButton
                            className="clickable text-link navigate-view-plans"
                            onClick={() => navigateSub(SUBPAGES.SELECT_PLAN)}
                        >
                            <FontAwesomeIcon
                                icon={faArrowLeft}
                                aria-label="left-arrow-back"
                            />{' '}
                            Back to View Plans
                        </LinkButton>
                    </span>
                </Col>
                <Col className="widget compare-dropdown">
                    <ComparePlansDropdown />
                </Col>
            </Row>

            <Row
                className="plans pt-1"
                role="radiogroup"
                aria-label="Compare Plan Benefit Packages"
            >
                {comparedPlans.map((healthPlan, index) => {
                    const numItems = comparedPlans.length;
                    const lastCompleteSetIdx = numItems - (numItems % 3) - 1;
                    return (
                        <Col
                            lg="4"
                            className={classnames('plan-item pt-3', {
                                'border-bottom': index <= lastCompleteSetIdx,
                            })}
                            key={`planItem_${index}`}
                            data-testid="PlanPreviewCard"
                        >
                            <Field
                                className="float-left m-2 "
                                name="enrollment[selectPlan][plan]"
                                component="input"
                                type="radio"
                                onChange={() => {
                                    savePlan(index);
                                }}
                                value={healthPlan.planName}
                                aria-label={healthPlan.planName}
                            />{' '}
                            <PlanPreview
                                title={healthPlan.planName}
                                handleTitleClick={() => savePlan(index)}
                                contractCode={healthPlan.contractCode}
                                pbp={healthPlan.pbp}
                                monthlyPremium={parseFloat(
                                    healthPlan.premiumAmount
                                )}
                                pcpCopay={healthPlan.officeVisitIn}
                                specialistCopay={healthPlan.specialistIn}
                                outOfPocketMax={parseFloat(
                                    healthPlan.inNetworkMoopLimit
                                )}
                                benefits={
                                    healthPlan.otherBenefits
                                        ? healthPlan.otherBenefits
                                              .split(';')
                                              .filter((str) => !!str)
                                        : []
                                }
                                planSummary={healthPlan.benefitSummary}
                                viewMenu={false}
                                removePlan={removePlan}
                                comparableView={true}
                                showCardBenefits={
                                    get(enrollment, 'findPlan.coverageYear') ===
                                    '2023'
                                }
                            />
                        </Col>
                    );
                })}
            </Row>
        </div>
    );
};

ComparePlans = reduxForm({
    form: 'wizard', //Form name is same
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(ComparePlans);

ComparePlans = connect((state) => {
    return getFormValues('wizard')(state);
})(ComparePlans);
export default ComparePlans;
