import { cloneDeep } from 'lodash';
import { INITIAL_VALUES } from '../constants/initialValues';
import validateFindPlan from './Validation/FindPlan-Validate';

const validate = (values) => {
    const errors = cloneDeep(INITIAL_VALUES);

    // Convert Initial Default Values

    if (values.enrollment) {
        if (values.enrollment.findPlan) {
            validateFindPlan(values, errors);
        }
    }
    return errors;
};

export default validate;
