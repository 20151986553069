import React from 'react';
import { Alert } from 'react-bootstrap';
import { useAppInsightContext } from '../../contexts/appInsight';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

const isEnrollmentMissingKeys = (error) => {
    // This only happens when refreshing page. We will ignore it for UI display.
    const errorStr = error.toString();
    const stack = error.stack;
    const stackIsFromEnrollment = stack.toLowerCase().includes('enrollment');
    return (
        errorStr.toLowerCase().includes('cannot read property') &&
        stackIsFromEnrollment
    );
};

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        let showError = false;
        showError = showError || !isEnrollmentMissingKeys(error);
        return { hasError: showError };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        const { appInsights } = this.props;
        if (appInsights) {
            appInsights.trackException({
                error,
                severityLevel: SeverityLevel.Error,
                errorInfo,
            });
        }
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <Alert variant="danger">
                    The application faced an error. Please try again by
                    refreshing the page or contact support.
                </Alert>
            );
        }

        return this.props.children;
    }
}

const ErrorBoundaryWithAppInsights = (props) => {
    const appInsights = useAppInsightContext();
    return <ErrorBoundary {...props} appInsights={appInsights} />;
};

export default ErrorBoundaryWithAppInsights;
