import React, { useContext, useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { createBrowserHistory } from 'history';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {
    ReactPlugin,
    withAITracking,
} from '@microsoft/applicationinsights-react-js';
import { ConfigContext } from './config';
import { registerLicense } from '@syncfusion/ej2-base';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const history = createBrowserHistory({ basename: baseUrl });
const reactPlugin = new ReactPlugin();
const withAITrackingWithReactPlugin = (component) =>
    withAITracking(reactPlugin, component);

const initAppInsights = (instrumentationKey, browserHistory) => {
    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: instrumentationKey,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history: browserHistory },
            },
        },
    });
    appInsights.loadAppInsights();
    return appInsights;
};

const AppInsightContext = React.createContext(null);

let ChildrenHOC = (props) => {
    return <>{props.children}</>;
};

const ChildrenHOCWithAITracking = withAITrackingWithReactPlugin(ChildrenHOC);

const WithAppInsightContext = ({ children }) => {
    const { InstrumentationKey, SyncfusionLicenseKey } =
        useContext(ConfigContext);
    registerLicense(SyncfusionLicenseKey);
    const [appInsights] = useState(
        initAppInsights(InstrumentationKey, history)
    );
    return (
        <AppInsightContext.Provider value={appInsights}>
            {!isEmpty(appInsights) && (
                <ChildrenHOCWithAITracking>
                    {children}
                </ChildrenHOCWithAITracking>
            )}
        </AppInsightContext.Provider>
    );
};

/*
Please refer to: https://github.com/microsoft/ApplicationInsights-JS#sending-telemetry-to-the-azure-portal
for how to design the trackEvent calls.

Example for .trackEvent():
    const appInsights = useAppInsightContext();
    useEffect(() => {
        appInsights.trackEvent({
            name: 'LayoutLoaded',
            properties: {
                count,
            },
        });
        count++;
    });



*/
const useAppInsightContext = () => {
    const appInsights = useContext(AppInsightContext);
    return appInsights;
};

export { WithAppInsightContext, useAppInsightContext };
