import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Field,
    reduxForm,
    getFormValues,
    getFormSubmitErrors,
} from 'redux-form';
import validate from '../../../actions/validate';
import { RenderCheckbox } from '../../../../actions/renderField';
import { SEP_QUESTIONS } from '../../../../constants/verification';
import { NextButton, BackButton } from '../../../../../Common/Button/Button';
import { useAppInsightContext } from '../../../../../../contexts/appInsight';
import { PAGES, SUBPAGES, PAGE_NAME } from '../../../../constants/enum';
import { get } from 'lodash';
import './first-eligibilityVerification.scss';
import AdditionalQuestion from 'components/Enrollment/components/FormReuse/AdditionalQuestion';
import { isEmpty } from 'lodash';
import AdditionalQuestionInputType from 'enums/AdditionalQuestionInputType';

let EligibilityVerification = ({
    handleSubmit,
    change,
    enrollment,
    previousPage,
}) => {
    const appInsights = useAppInsightContext();

    const checkValidResponse = (event) => {
        event.preventDefault();
        handleSubmit();
        return true;
    };

    const resetSubForm = (index, prevCheckboxValue) => {
        const additionalQuestions = SEP_QUESTIONS[index].additionalQuestions;
        if (prevCheckboxValue === 'true' && !isEmpty(additionalQuestions)) {
            additionalQuestions.forEach(
                (additionalQuestion, additionalQuestionIndex) => {
                    change(
                        `enrollment.enrolling[medicareAdvantageEligibility][questions][${index}][additionalQuestions][${additionalQuestionIndex}][response]`,
                        null
                    );
                    if (
                        additionalQuestion.type ===
                        AdditionalQuestionInputType.FileUpload
                    ) {
                        localStorage.removeItem(
                            `${enrollment.applicationId}_${additionalQuestion.fileUploadInfo?.documentType}`
                        );
                    }
                }
            );
        }
    };

    useEffect(() => {
        window._mfq.push([
            'newPageView',
            'Enrollment-EligibilityVerificationPage',
        ]);
        window.analytics.page('Medicare Advantage Eligibility Verification');

        appInsights.startTrackPage(
            PAGE_NAME[PAGES.ENROLLMENT][SUBPAGES.CONFIRM_ELIGIBILITY]
        );

        return () => {
            appInsights.stopTrackPage(
                PAGE_NAME[PAGES.ENROLLMENT][SUBPAGES.CONFIRM_ELIGIBILITY],
                null,
                {
                    applicationId: get(enrollment, 'applicationId'),
                }
            );
        };
    }, []);

    return (
        <div className="first-eligibility-verification">
            <div className="text-left">
                <p
                    className="enrollment-title my-4"
                    role="heading"
                    aria-level="1"
                >
                    Medicare Advantage Eligibility Verification
                </p>
                <p>
                    Typically, you may enroll in a Medicare Advantage plan only
                    during the annual enrollment period from October 15 through
                    December 7 of each year. There are exceptions that may allow
                    you to enroll in a Medicare Advantage plan outside of this
                    period. <br />
                    <br /> Please read the following statements carefully and
                    check the box if the statement applies to you. By checking
                    any of the following boxes you are certifying that, to the
                    best of your knowledge, you are eligible for an Enrollment
                    Period. If we later determine that this information is
                    incorrect, you may be disenrolled.
                </p>
            </div>
            <form onSubmit={checkValidResponse}>
                <div className="py-3">
                    {SEP_QUESTIONS.map((question, questionIndex) => (
                        <div
                            key={`verification_${questionIndex}`}
                            className="additional-questions"
                        >
                            <Field
                                name={`enrollment.enrolling[medicareAdvantageEligibility][questions][${questionIndex}][response]`}
                                type="checkbox"
                                onChange={({ target }) =>
                                    resetSubForm(questionIndex, target.value)
                                }
                                label={question.text}
                                component={RenderCheckbox}
                            />
                            {enrollment?.enrolling?.medicareAdvantageEligibility
                                ?.questions?.[questionIndex]?.response &&
                                question?.additionalQuestions?.map(
                                    (
                                        additionalQuestion,
                                        additionalQuestionIndex
                                    ) => (
                                        <div
                                            key={`additionalQuestion_${additionalQuestionIndex}`}
                                            className="additional-question"
                                        >
                                            <AdditionalQuestion
                                                name={`enrollment.enrolling[medicareAdvantageEligibility][questions][${questionIndex}][additionalQuestions][${additionalQuestionIndex}][response]`}
                                                type={additionalQuestion.type}
                                                label={additionalQuestion.label}
                                                required={
                                                    additionalQuestion.required
                                                }
                                                selectProps={{
                                                    list:
                                                        additionalQuestion.options &&
                                                        Object.keys(
                                                            additionalQuestion.options
                                                        ),
                                                    valueMap:
                                                        additionalQuestion.options,
                                                    addPlaceholder: true,
                                                }}
                                                fileUploadProps={{
                                                    fileUploadKey: `${enrollment.applicationId}_${additionalQuestion.fileUploadInfo?.documentType}`,
                                                    uploadLabel:
                                                        additionalQuestion
                                                            .fileUploadInfo
                                                            ?.uploadText,
                                                    documentLabel:
                                                        additionalQuestion
                                                            .fileUploadInfo
                                                            ?.documentType,
                                                    headerText:
                                                        additionalQuestion
                                                            .fileUploadInfo
                                                            ?.headerText,
                                                }}
                                            />
                                        </div>
                                    )
                                )}
                        </div>
                    ))}
                    <br />
                    <div className="text-left">
                        <p className="enrollment-title mb-1">
                            Attestation of Enrollment
                        </p>
                        <p>
                            If none of these statements applies to you or you’re
                            not sure, please contact Alignment Health Plan at{' '}
                            <span className="phone-number">
                                866-634-2247 (TTY 711)
                            </span>
                            , 8am-8pm, seven days a week (except Thanksgiving
                            and Christmas) from October 1 to March 31 and
                            8am-8pm Monday through Friday (except holidays) from
                            April 1 through September 30, to see if you are
                            eligible to enroll. Alignment Health Plan is an HMO,
                            HMO POS, HMO C-SNP, HMO D-SNP and PPO plan with a
                            Medicare contract and a contract with the
                            California, Florida, Nevada and North Carolina
                            Medicaid programs. Enrollment in Alignment Health
                            Plan depends on contract renewal. Alignment Health
                            Plan complies with applicable federal civil rights
                            laws and does not discriminate on the basis of race,
                            color, national origin, age, disability, or sex.{' '}
                        </p>
                    </div>
                </div>

                <div className="d-flex justify-content-center">
                    <BackButton className="mr-2" onClick={previousPage}>
                        Back
                    </BackButton>
                    <NextButton className="ml-2" type="submit">
                        Continue
                    </NextButton>
                </div>
            </form>
        </div>
    );
};

EligibilityVerification = reduxForm({
    form: 'wizard', //Form name is same
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
})(EligibilityVerification);

EligibilityVerification = connect((state) => {
    return getFormValues('wizard')(state) || {};
})(EligibilityVerification);

export default EligibilityVerification;
