import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { FormText } from 'reactstrap';
import { ENROLLMENT_DOC_TYPE } from '../../constants/enum';
import { isEmpty, get } from 'lodash';
import './styles/UploadDocument.scss';

const RESTRICTED_EXTENSIONS = ['.exe'];
const PERMITTED_EXTENSIONS = ['.pdf', '.docx'];

const fetchLocalStorage = (key) => {
    let localStorageState = localStorage.getItem(key);
    return localStorageState ? JSON.parse(localStorageState) : [];
};

const saveToLocalStorage = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
};

const UploadDocument = ({
    enrollment,
    name,
    fileUploadKey,
    documentLabel,
    uploadLabel,
    errorMessage,
    change,
    onChange,
    headerText,
    meta: { touched, error },
    multiple = false,
}) => {
    const [acceptedFileTypes, setAcceptedFileTypes] =
        useState(PERMITTED_EXTENSIONS);
    const [preloadFiles, setPreloadFiles] = useState(
        fetchLocalStorage(fileUploadKey)
    );
    const [documentIDs, setDocumentIDs] = useState({});
    const path = {
        removeUrl: '/api/Enrollment/DeleteEnrollmentDocument',
        saveUrl: '/api/Enrollment/UploadEnrollmentDocuments',
    };

    const recordDocumentID = async (event) => {
        if (event.operation === 'upload' && event.response.statusCode === 200) {
            const response = JSON.parse(get(event, 'e.currentTarget.response'));
            const fileUploadId = get(response, 'fileUploadID');

            if (multiple && enrollment.document.length > 0) {
                saveToLocalStorage(fileUploadKey, [
                    ...fetchLocalStorage(fileUploadKey),
                    event.file,
                ]);

                const savedFileIds = get(
                    enrollment,
                    name.slice(name.indexOf('.') + 1)
                );
                change(name, [...savedFileIds, fileUploadId]);
            } else {
                saveToLocalStorage(fileUploadKey, [event.file]);
                change(name, fileUploadId);
            }

            // Store DocumentId
            let newDocument = {
                documentTitle: documentLabel,
                documentId: fileUploadId,
                fileName: event.file.name,
            };
            setDocumentIDs((documentIds) => {
                return multiple
                    ? {
                          ...documentIds,
                          [newDocument.fileName]: newDocument.documentId,
                      }
                    : { [newDocument.fileName]: newDocument.documentId };
            });
            if (enrollment.documents.length) {
                change(`enrollment[documents]`, [
                    ...enrollment.documents,
                    newDocument,
                ]);
            } else {
                change(`enrollment[documents]`, [newDocument]);
            }
        } else if (event.operation === 'remove') {
            const filesUpdate = fetchLocalStorage(fileUploadKey).filter(
                (files) => !event.file.name.includes(files.name)
            );
            saveToLocalStorage(fileUploadKey, filesUpdate);

            if (multiple && enrollment.document.length > 0) {
                const savedFileIds = get(
                    enrollment,
                    name.slice(name.indexOf('.') + 1)
                );
                const documentId = enrollment.documents.find(
                    (document) => event.file.name === document.fileName
                );
                change(
                    name,
                    savedFileIds.filter(
                        (savedFileId) => savedFileId === documentId
                    )
                );
            } else {
                change(name, null);
            }

            change(
                `enrollment[documents]`,
                enrollment.documents.filter((document) => {
                    if (event.file.name !== document.fileName) {
                        return true;
                    } else if (document.documentTitle !== documentLabel) {
                        return true;
                    }
                    return false;
                })
            );
        }
    };
    const addDocumentID = (args) => {
        if (args.currentRequest != null) {
            args.currentRequest.setRequestHeader(
                'documentID',
                documentIDs[args.filesData[0].name]
            );

            args.currentRequest.setRequestHeader(
                'ApplicationID',
                enrollment.applicationId
            );
        }
    };
    const addHeaders = (args) => {
        // Application Id
        args.currentRequest.setRequestHeader(
            'ApplicationID',
            enrollment.applicationId
        );

        // File Type ID
        args.currentRequest.setRequestHeader(
            'FileTypeID',
            ENROLLMENT_DOC_TYPE[documentLabel]
        );
    };
    const validateFileType = ({ filesData }) => {
        let fileExtensions = new Set(PERMITTED_EXTENSIONS);
        for (let file of filesData) {
            if (!RESTRICTED_EXTENSIONS.includes(`.${file.type}`)) {
                fileExtensions.add(`.${file.type}`);
            }
        }

        setAcceptedFileTypes([...fileExtensions]);
    };
    return (
        <div className="pb-2">
            <div className="my-2 justify-content-md-center">
                {headerText && (
                    <p className="upload-document-header">{headerText}</p>
                )}
                <div className="uploader-container">
                    <UploaderComponent
                        id="document"
                        asyncSettings={path}
                        uploading={addHeaders}
                        removing={addDocumentID}
                        selected={validateFileType}
                        buttons={{
                            browse: uploadLabel,
                            clear: 'Clear',
                            upload: 'Upload',
                        }}
                        files={preloadFiles}
                        success={recordDocumentID}
                        change={onChange}
                        allowedExtensions={acceptedFileTypes.join(',')}
                        maxFileSize={20000000} // 20 Mb
                        multiple={multiple}
                    />
                </div>
            </div>
            {isEmpty(fetchLocalStorage(fileUploadKey)) && (
                <FormText color="danger">{errorMessage}</FormText>
            )}
            {touched && error && (
                <FormText color="danger" data-testid="errorMessageInput">
                    {error}
                </FormText>
            )}
        </div>
    );
};
UploadDocument.propTypes = {
    enrollment: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    fileUploadKey: PropTypes.string.isRequired,
    uploadLabel: PropTypes.string.isRequired,
    documentLabel: PropTypes.string.isRequired,
    errorMessage: PropTypes.string,
    change: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    headerText: PropTypes.string,
    multiple: PropTypes.bool,
};

export default UploadDocument;
