import moment from 'moment';

const API_ENDPOINT = 'api';

const getLocalTimeHeader = () => {
    const headers = {
        'X-LocalTimestamp': moment().format('YYYY-MM-DDTHH:mm:ssZ'),
    };
    return headers;
};

export { API_ENDPOINT, getLocalTimeHeader };
