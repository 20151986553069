import { isFunction } from 'lodash';
import React, { useCallback } from 'react';
import { useAppInsightContext } from '../../../contexts/appInsight';

const LinkWithAppInsights = ({
    appInsightEventName,
    children,
    onClick,
    ...rest
}) => {
    const appInsights = useAppInsightContext();
    const handleClick = useCallback((e) => {
        appInsights.trackEvent({
            name: appInsightEventName,
            properties: { url: rest.href },
        });
        if (isFunction(onClick)) {
            onClick(e);
        }
    }, []);
    return (
        <a {...rest} onClick={handleClick}>
            {children}
        </a>
    );
};

export default LinkWithAppInsights;
