import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { handleEnterKeyToClick } from '../../../../util/eventListeners';

const ProcessSteps = ({ headerLabels, currentPage, navigatePage }) => {
    const handleClick = useCallback(
        (elementIdx) => {
            if (currentPage > elementIdx) {
                window.analytics.track('Page Navigated', {
                    to: elementIdx,
                    from: currentPage,
                });
                navigatePage(elementIdx);
            }
        },
        [currentPage]
    );
    return (
        <ul
            role="list"
            aria-label="Application Navigation"
            className="ml-0 ml-md-2 breadcrumb breadcrumb-arrow d-flex"
        >
            {headerLabels.map(({ label, pageIndex }, i) => (
                <li
                    key={`progressHeaderLabel_${i}`}
                    className={cn({
                        active: currentPage === pageIndex,
                        visited:
                            currentPage !== pageIndex && i + 1 < currentPage,
                    })}
                    onClick={handleClick.bind(null, pageIndex)}
                    onKeyUp={handleEnterKeyToClick}
                    tabIndex={i + 1 < currentPage ? 0 : -1}
                    data-testid={`subPageTitle_${i}`}
                >
                    <div>
                        <label className="dot">{i + 1}</label>
                        <span>{label}</span>
                    </div>
                </li>
            ))}
        </ul>
    );
};

ProcessSteps.propTypes = {
    headerLabels: PropTypes.array.isRequired,
    currentPage: PropTypes.number.isRequired,
    navigatePage: PropTypes.func.isRequired,
};

export default ProcessSteps;
