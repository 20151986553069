import React, { useState, useEffect } from 'react';
import {
    ListGroup,
    ListGroupItem,
    ListGroupItemHeading,
    Label,
    Input,
} from 'reactstrap';
import { Col, Row } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import './SubviewPlanDetails.scss';
import { formatCost } from '../../../../util/format';
import CommonButton from '../../../../../Common/Button/Button';

const BenefitDetailsList = ({ details }) => (
    <ListGroup className="mx-0 detail-list">
        {details.map((detail, index) => {
            let bgColorDiv = 'muted';
            if (index % 2) {
                bgColorDiv = 'light';
            }

            if (isEmpty(detail.description)) {
                return (
                    <ListGroupItem color={bgColorDiv} key={index}>
                        <ListGroupItemHeading className="text-dark detail-header">
                            {detail.name}
                        </ListGroupItemHeading>
                    </ListGroupItem>
                );
            }

            return (
                <ListGroupItem color={bgColorDiv} key={index}>
                    <ListGroupItemHeading className="text-dark detail-header">
                        {detail.name}
                    </ListGroupItemHeading>

                    <ul className="benefits">
                        {detail.description.split('|  |').map((desc, index) => {
                            return !isEmpty(desc.trim()) && (
                                <li
                                    className="benefit-desc"
                                    key={`benefit_${index}`}
                                >
                                    {desc}
                                </li>
                            );
                        })}
                    </ul>
                </ListGroupItem>
            );
        })}
    </ListGroup>
);

const SubviewPlanDetails = ({
    title,
    details,
    displayView,
    comparableView,
    benefitSummaryENG,
    benefitSummarySPA,
    benefitSummaryCHN,
    benefitSummaryVIET,
    benefitSummaryKOR,
}) => {
    const [language, setLanguage] = useState('');
    const [filePath, setFilePath] = useState('');
    const languages = [
        benefitSummaryENG ? 'In English' : null,
        benefitSummarySPA ? 'In Spanish' : null,
        benefitSummaryCHN ? 'In Chinese' : null,
        benefitSummaryVIET ? 'In Vietnamese' : null,
        benefitSummaryKOR ? 'In Korean' : null,
    ];
    const languageDropdownId = `languageSelect-${displayView}-${title}`;

    useEffect(() => {
        if (language.includes('English')) {
            setFilePath(benefitSummaryENG);
        } else if (language.includes('Spanish')) {
            setFilePath(benefitSummarySPA);
        } else if (language.includes('Chinese')) {
            setFilePath(benefitSummaryCHN);
        } else if (language.includes('Vietnamese')) {
            setFilePath(benefitSummaryVIET);
        } else if (language.includes('Korean')) {
            setFilePath(benefitSummaryKOR);
        } else {
            setFilePath('');
        }
    }, [language]);

    return (
        <div>
            {!comparableView && (
                <div className="mt-3 mx-md-2 plan-details">
                    <Row>
                        <Col sm="12">
                            <h6>Summary of benefits and coverage:</h6>
                        </Col>
                    </Row>
                    <Row className="summary">
                        <Col sm="8" className="mb-sm-2">
                            <div className="language-input">
                                <Label for={languageDropdownId}>
                                    Choose Language
                                </Label>
                                <Input
                                    type="select"
                                    defaultValue="placeholder"
                                    id={languageDropdownId}
                                    onChange={(e) => {
                                        setLanguage(e.target.value);
                                    }}
                                >
                                    <option value="placeholder" disabled>
                                        Select...
                                    </option>
                                    {languages.map(
                                        (label, index) =>
                                            label && (
                                                <option
                                                    key={`language_${index}`}
                                                >
                                                    {label}
                                                </option>
                                            )
                                    )}
                                </Input>
                            </div>
                        </Col>
                        <Col sm="4" className="mb-sm-2 px-0">
                            <div className="download-btn">
                                <CommonButton
                                    disabled={isEmpty(filePath)}
                                    href={filePath}
                                    target="_blank"
                                    className="btn-secondary"
                                >
                                    Download
                                </CommonButton>
                            </div>
                        </Col>
                    </Row>
                </div>
            )}
            <BenefitDetailsList details={details} />
        </div>
    );
};

export { SubviewPlanDetails as default, BenefitDetailsList };
