export const APPINSIGHT_EVENTS = {
    APPLICATION_TIME_TO_SUBMISSION:
        'INDIVIDUAL_ENROLL_APPLICATION_TIME_TO_SUBMISSION',
    ZIPCODE_FAILURE: 'INDIVIDUAL_ENROLL_ZIPCODE_FAILURE',
    LINK_CLICK_OTHER_PROVIDERS: 'INDIVIDUAL_ENROLL_LINK_CLICK_OTHER_PROVIDERS',
    LINK_CLICK_DENTAL_PROVIDERS:
        'INDIVIDUAL_ENROLL_LINK_CLICK_DENTAL_PROVIDERS',
    LINK_CLICK_BROKER_PORTAL: 'INDIVIDUAL_ENROLL_LINK_CLICK_BROKER_PORTAL',
    LINK_CLICK_MEDICARE: 'INDIVIDUAL_ENROLL_LINK_CLICK_MEDICARE',
    LINK_CLICK_BUTTON: 'INDIVIDUAL_ENROLL_LINK_CLICK_BUTTON',
    GOLDENCOMM_REQUEST_PARAMS:'GOLDENCOMM_REQUEST_PARAMS'
};
