import React from 'react';

const ElectronicSignatureAgreementText = () => (
    <div>
        <h5 className="enrollment-title">Electronic Signature Agreement</h5>
        <p>
            By signing below, you are signing this Agreement electronically. You
            agree your electronic signature is the legal equivalent of your
            manual signature on this Agreement.
        </p>
    </div>
);
export { ElectronicSignatureAgreementText };
