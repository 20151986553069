import React from 'react';
import classnames from 'classnames';
import PlanPreview from '../../Plans/PlanPreview';
import { get, isNil } from 'lodash';
import { Col } from 'react-bootstrap';
import { Field } from 'redux-form';

const PlanSelectionView = ({
    indexStart,
    indexEnd,
    HealthPlans,
    savePlan,
    showCardBenefits,
}) => {
    let planSetView = [];

    for (let i = indexStart; i < indexEnd; i++) {
        let healthPlan = HealthPlans[i];
        const handleSavePlan = () => {
            window.analytics.track('Plan Selected', {
                plan: healthPlan.planName,
                contractCode: healthPlan.contractCode,
                pbp: healthPlan.pbp,
            });
            savePlan(i);
        };

        if (!isNil(healthPlan)) {
            const numItems = HealthPlans.length;
            const lastCompleteSetIdx = numItems - (numItems % 3) - 1;
            planSetView.push(
                <Col
                    lg="4"
                    className={classnames('plan-item pt-3', {
                        'border-bottom': i <= lastCompleteSetIdx,
                    })}
                    key={`planItem_${i}`}
                    data-testid="PlanPreviewCard"
                >
                    <Field
                        className="float-left m-2"
                        name="enrollment[selectPlan][plan]"
                        component="input"
                        type="radio"
                        onChange={handleSavePlan}
                        value={healthPlan.planName}
                        aria-label={healthPlan.planName}
                    />{' '}
                    <PlanPreview
                        title={healthPlan.planName}
                        handleTitleClick={handleSavePlan}
                        contractCode={healthPlan.contractCode}
                        pbp={healthPlan.pbp}
                        monthlyPremium={parseFloat(healthPlan.premiumAmount)}
                        pcpCopay={healthPlan.officeVisitIn}
                        specialistCopay={healthPlan.specialistIn}
                        outOfPocketMax={parseFloat(
                            healthPlan.inNetworkMoopLimit
                        )}
                        benefits={
                            healthPlan.otherBenefits
                                ? healthPlan.otherBenefits
                                      .split(';')
                                      .filter((str) => !!str)
                                : []
                        }
                        planSummary={healthPlan.benefitSummary}
                        benefitSummaryENG={healthPlan.benefitSummaryEnglishURL}
                        benefitSummarySPA={healthPlan.benefitSummarySpanishURL}
                        benefitSummaryCHN={healthPlan.benefitSummaryChineseURL}
                        benefitSummaryVIET={
                            healthPlan.benefitSummaryVietnameseURL
                        }
                        benefitSummaryKOR={healthPlan.benefitSummaryKoreanURL}
                        viewMenu={true}
                        showCardBenefits={showCardBenefits}
                    />
                </Col>
            );
        }
    }
    return planSetView;
};

export default PlanSelectionView;
