import React, { useState } from 'react';
import { Collapse, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronLeft,
    faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import './selectionPlanSummary-EnrollInfo.scss';
import LinkWithAppInsights from '../../../../../components/Common/LinkWithAppInsights';
import { APPINSIGHT_EVENTS } from '../../../../../constants/appInsightEvents';

const EnrollInfo = ({ isSNPPrequalFormRequired }) => {
    const [showInfo, setShowInfo] = useState(true);

    return (
        <div className="enroll-info">
            <div
                onClick={() => setShowInfo(!showInfo)}
                data-testid="enroll-info-toggle"
                className="enroll-info-toggle"
            >
                <p>Enrollment Information</p>
                <span>
                    {showInfo ? (
                        <FontAwesomeIcon
                            icon={faChevronDown}
                            title="chevron-down"
                        />
                    ) : (
                        <FontAwesomeIcon
                            icon={faChevronLeft}
                            title="chevron-left"
                        />
                    )}
                </span>
            </div>
            <Collapse
                isOpen={showInfo}
                className="enrollment-info-collapse"
                data-testid="enroll-info-body"
            >
                <Row>
                    <Col md>
                        <h2>Who can use this form?</h2>
                        <p>
                            People with Medicare who want to join a Medicare
                            Advantage{' '}
                            {isSNPPrequalFormRequired
                                ? 'HMO C-SNP or HMO D-SNP'
                                : 'HMO or PPO'}{' '}
                            Plan.
                        </p>
                        <h3>To join a plan, you must:</h3>
                        <ul>
                            <li>
                                Be a United States citizen or be lawfully
                                present in the U.S.
                            </li>
                            <li>Live in the plan’s service area</li>
                        </ul>
                        <h3>Important:</h3>
                        <p>
                            To join a Medicare Advantage{' '}
                            {isSNPPrequalFormRequired ? (
                                <>
                                    HMO C-SNP or
                                    <br /> HMO D-SNP
                                </>
                            ) : (
                                'HMO or PPO'
                            )}{' '}
                            Plan, you must also have both:
                        </p>
                        <ul>
                            <li>Medicare Part A (Hospital Insurance)</li>
                            <li>Medicare Part B (Medical Insurance)</li>
                        </ul>
                        <h2>When do I use this form?</h2>
                        <p>You can join a plan:</p>
                        <ul>
                            <li>
                                Between October 15 - December 7 each year (for
                                coverage starting January 1)
                            </li>
                            <li>Within 3 months of first getting Medicare</li>
                            <li>
                                In certain situations where you’re allowed to
                                join or switch plans
                            </li>
                        </ul>
                        <p>
                            Visit{' '}
                            <LinkWithAppInsights
                                href="https://www.medicare.gov/"
                                target="_blank"
                                appInsightEventName={
                                    APPINSIGHT_EVENTS.LINK_CLICK_MEDICARE
                                }
                            >
                                Medicare.gov
                            </LinkWithAppInsights>{' '}
                            to learn more about when you can sign up for a plan.
                        </p>
                        <h2>What do I need to complete this form?</h2>
                        <ul>
                            <li>
                                Your Medicare Number (the number on your red,
                                white, and blue Medicare card)
                            </li>
                            <li>Your permanent address and phone number</li>
                        </ul>
                        <p>
                            <strong>Note:</strong> You must complete all items
                            in Section 1. The items in Section 2 are optional —
                            you can’t be denied coverage because you don’t fill
                            them out.
                        </p>
                    </Col>
                    <Col md>
                        <h2>Reminders:</h2>
                        <ul>
                            <li>
                                If you want to join a plan during fall open
                                enrollment (October 15–December 7), the plan
                                must get your completed form by December 7.
                            </li>
                            <li>
                                Your plan will send you a bill for the plan’s
                                premium. You can choose to sign up to have your
                                premium payments deducted from your bank account
                                or your monthly Social Security (or Railroad
                                Retirement Board) benefit.
                            </li>
                        </ul>
                        <h2>What happens next?</h2>
                        <p>
                            Send your completed and signed form to:
                            <br />
                            Alignment Health Plan
                            <br />
                            P.O.Box 14010
                            <br />
                            Orange, CA 92863-9936
                        </p>
                        <p>
                            Once they process your request to join, they'll
                            contact you.
                        </p>
                        <h2>How do I get help with this form?</h2>
                        <p>
                            Call Alignment Health Plan at 888-979-2247. TTY
                            users can call 711.
                        </p>
                        <p>
                            Or, call Medicare at 1-800-MEDICARE
                            (1-800-633-4227). TTY users can call 1-877-486-2048.
                        </p>
                    </Col>
                </Row>
            </Collapse>
        </div>
    );
};

export default EnrollInfo;
