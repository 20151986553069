import React from 'react';
import { reduxForm } from 'redux-form';
import { YesNoSelector } from './RadioSelector';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

let CategorySurvey = (props) => {
    const { questions, categoryTitle, startIndex, required } = props;
    if (isEmpty(questions)) {
        return null;
    }
    return (
        <div className="py-2">
            <h5 className="enrollment-title">{categoryTitle}</h5>
            {questions.map((item, index) => {
                return (
                    <div key={categoryTitle + '_' + index}>
                        <YesNoSelector
                            label={`${index + 1}. ${item}`}
                            name={`enrollment[enrolling][prequalSNP][questions][${
                                startIndex + index
                            }][response]`}
                            addLineBreak
                            required={required}
                        />
                    </div>
                );
            })}
        </div>
    );
};
CategorySurvey.propTypes = {
    categoryTitle: PropTypes.string.isRequired,
    questions: PropTypes.array.isRequired,
    startIndex: PropTypes.number.isRequired,
};
CategorySurvey = reduxForm({
    form: 'wizard', //Form name is same
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(CategorySurvey);

export default CategorySurvey;
