import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import validate from '../../../actions/validate';
import { translateGender } from '../../../../util/translation';
import { YES_NO_OPTION } from '../../../../constants/enum';
import moment from 'moment';

let ReviewApplicantInfo = (props) => {
    return (
        <div className="row">
            <div className="col-md-5">
                <ul className="no-bullet list-align-left">
                    <li>
                        Last Name:{' '}
                        <strong>
                            {props.enrollment.enrolling.clientInfo['lastName']}
                        </strong>
                    </li>
                    <li>
                        First Name:{' '}
                        <strong>
                            {props.enrollment.enrolling.clientInfo['firstName']}
                        </strong>
                    </li>
                    <li>
                        Residence Address:{' '}
                        <strong>
                            {
                                props.enrollment.enrolling.clientInfo[
                                    'permanentAddressLine1'
                                ]
                            }
                        </strong>
                    </li>
                    <li>
                        City:{' '}
                        <strong>
                            {
                                props.enrollment.enrolling.clientInfo[
                                    'permanentCity'
                                ]
                            }
                        </strong>
                    </li>
                    <li>
                        State:{' '}
                        <strong>
                            {
                                props.enrollment.enrolling.clientInfo[
                                    'permanentState'
                                ]
                            }
                        </strong>
                    </li>
                    <li>
                        Zip Code:{' '}
                        <strong>
                            {
                                props.enrollment.enrolling.clientInfo[
                                    'permanentZipCode'
                                ]
                            }
                        </strong>
                    </li>
                </ul>
            </div>
            <div className="col-md-5">
                <ul className="no-bullet list-align-left">
                    <li>
                        Mailing Address:{' '}
                        <strong>
                            {props.enrollment.enrolling.clientInfo[
                                'addressMailingSame'
                            ] == YES_NO_OPTION['Yes'].toString()
                                ? props.enrollment.enrolling.clientInfo[
                                      'permanentAddressLine1'
                                  ]
                                : props.enrollment.enrolling.clientInfo[
                                      'mailingAddressLine1'
                                  ]}
                        </strong>
                    </li>
                    <li>
                        Phone Number:{' '}
                        <strong>
                            {
                                props.enrollment.enrolling.clientInfo[
                                    'primaryPhone'
                                ]
                            }
                        </strong>
                    </li>
                    <li>
                        Gender:{' '}
                        <strong>
                            {translateGender(
                                props.enrollment.enrolling.clientInfo['gender']
                            )}
                        </strong>
                    </li>
                    <li>
                        Date of Birth:{' '}
                        <strong>
                            {moment(
                                props.enrollment.enrolling.clientInfo[
                                    'dateOfBirth'
                                ]
                            ).format('MM/DD/YYYY')}
                        </strong>
                    </li>
                    <li>
                        Email:{' '}
                        <strong>
                            {
                                props.enrollment.enrolling.clientInfo[
                                    'contactEmail'
                                ]
                            }
                        </strong>
                    </li>
                </ul>
            </div>
        </div>
    );
};

ReviewApplicantInfo = reduxForm({
    form: 'wizard', //Form name is same
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
})(ReviewApplicantInfo);

ReviewApplicantInfo = connect((state) => {
    return getFormValues('wizard')(state) || {};
})(ReviewApplicantInfo);

export default ReviewApplicantInfo;
