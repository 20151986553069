import React from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { translateBool } from '../../../../util/translation';
import { ELIGIBILITY_QUESTIONS } from '../../../../constants/eligibility';

let ReviewAdditionalView = (props) => {
    const { enrollment } = props;

    let eligibilityKeys = Object.keys(ELIGIBILITY_QUESTIONS);

    const MIDPOINT_INDEX = eligibilityKeys.length / 2;
    return (
        <div className="row">
            <div className="col-md-5">
                <ul className="no-bullet list-align-left">
                    {eligibilityKeys
                        .slice(0, MIDPOINT_INDEX)
                        .map((keyValue, index) => {
                            return (
                                <li key={`previewEllig_${index}`}>
                                    <div>{ELIGIBILITY_QUESTIONS[keyValue]}</div>
                                    <div>
                                        <strong>
                                            {translateBool(
                                                enrollment.enrolling
                                                    .additionalInfo.questions[
                                                    index
                                                ].response
                                            )}
                                        </strong>
                                    </div>
                                </li>
                            );
                        })}
                </ul>
            </div>
            <div className="col-md-5">
                <ul className="no-bullet list-align-left">
                    {eligibilityKeys
                        .slice(MIDPOINT_INDEX)
                        .map((keyValue, index) => {
                            return (
                                <li key={`previewEllig_${index}`}>
                                    <div>{ELIGIBILITY_QUESTIONS[keyValue]}</div>
                                    <div>
                                        <strong>
                                            {translateBool(
                                                enrollment.enrolling
                                                    .additionalInfo.questions[
                                                    index + MIDPOINT_INDEX
                                                ].response
                                            )}
                                        </strong>
                                    </div>
                                </li>
                            );
                        })}
                </ul>
            </div>
        </div>
    );
};

const ReviewAdditional = connect((state) => {
    return getFormValues('wizard')(state) || {};
})(ReviewAdditionalView);

export default ReviewAdditional;
