import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { get } from 'lodash';
import validate from '../../../actions/validate';
import { RenderField } from '../../../../actions/renderField';
import {
    RadioSelector,
    YesNoSelector,
} from '../../../../components/FormReuse/RadioSelector';
import {
    QUESTION_INDEX,
    SUB_QUESTION_INDEX,
    YES_NO_OPTION,
    EDIT_SECTION,
    PAGES,
    SUBPAGES,
    PAGE_NAME,
} from '../../../../constants/enum';
import { LENGTH_RESTRICTIONS } from '../../../../constants/input';
import { NextButton, BackButton } from '../../../../../Common/Button/Button';
import { ELIGIBILITY_QUESTIONS } from '../../../../constants/eligibility';
import FieldTrimmed from '../../../../actions/FieldTrimmed';
import { useAppInsightContext } from '../../../../../../contexts/appInsight';

const INDEX = QUESTION_INDEX.ENROLLMENT.ADDITIONAL_INFO;

let AddInformation = (props) => {
    const {
        handleSubmit,
        previousPage,
        enrollment,
        change,
        goToRef,
        setGoToRef,
    } = props;
    const paymentOptionRef = useRef(null);
    const appInsights = useAppInsightContext();

    const resetSubForm = (index) => {
        if (
            get(
                enrollment,
                `enrolling.additionalInfo.questions[${index}].response`
            ) === YES_NO_OPTION['Yes'].toString()
        ) {
            return;
        }
        const subQuestions = get(
            enrollment,
            `enrolling.additionalInfo.questions[${index}].additionalQuestions`,
            []
        );
        for (const subIndex in subQuestions) {
            change(
                `enrollment.enrolling.additionalInfo.questions[${index}].additionalQuestions[${subIndex}].response`,
                null
            );
        }
    };

    useEffect(() => {
        window._mfq.push(['newPageView', 'Enrollment-AdditionalInfoPage']);
        window.analytics.page('Additional Information');

        appInsights.startTrackPage([
            PAGE_NAME[PAGES.ENROLLMENT][SUBPAGES.ADDITIONAL_INFO],
        ]);

        const scrollToRef = async () => {
            switch (goToRef) {
                case EDIT_SECTION.PAYMENT_OPTION:
                    paymentOptionRef.current.scrollIntoView();
                    break;
                default:
                    window.scrollTo(0, 0);
            }
        };
        scrollToRef().then(setGoToRef(null));

        return () => {
            appInsights.stopTrackPage(
                PAGE_NAME[PAGES.ENROLLMENT][SUBPAGES.ADDITIONAL_INFO],
                null,
                {
                    applicationId: get(enrollment, 'applicationId'),
                }
            );
        };
    }, []);

    return (
        <div>
            <div className="text-left">
                <h5 className="enrollment-title">Additional Information</h5>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="py-3">
                    <YesNoSelector
                        name={`enrollment[enrolling][additionalInfo][questions][${INDEX.OTHER_COVERAGE}][response]`}
                        label={`1. ${ELIGIBILITY_QUESTIONS.otherCoverage}`}
                        addLineBreak={true}
                        required
                        onChange={() => resetSubForm(INDEX.OTHER_COVERAGE)}
                    />
                    {enrollment.enrolling.additionalInfo.questions[
                        INDEX.OTHER_COVERAGE
                    ].response === YES_NO_OPTION['Yes'].toString() && (
                        <div className="text-left">
                            <div className="row">
                                <div className="col-md-4">
                                    <FieldTrimmed
                                        name={`enrollment[enrolling][additionalInfo][questions][${INDEX.OTHER_COVERAGE}][additionalQuestions][${SUB_QUESTION_INDEX.OTHER_COVERAGE.COVERAGE_NAME}][response]`}
                                        label="Name of other coverage"
                                        type="text"
                                        charLimit={
                                            LENGTH_RESTRICTIONS.COVERAGE_NAME
                                        }
                                        component={RenderField}
                                        required
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FieldTrimmed
                                        name={`enrollment[enrolling][additionalInfo][questions][${INDEX.OTHER_COVERAGE}][additionalQuestions][${SUB_QUESTION_INDEX.OTHER_COVERAGE.COVERAGE_ID}][response]`}
                                        label="Member number for this coverage"
                                        type="text"
                                        charLimit={
                                            LENGTH_RESTRICTIONS.COVERAGE_ID
                                        }
                                        component={RenderField}
                                        required
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FieldTrimmed
                                        name={`enrollment[enrolling][additionalInfo][questions][${INDEX.OTHER_COVERAGE}][additionalQuestions][${SUB_QUESTION_INDEX.OTHER_COVERAGE.COVERAGE_GROUP}][response]`}
                                        label="Group number for this coverage"
                                        type="text"
                                        charLimit={
                                            LENGTH_RESTRICTIONS.COVERAGE_GROUP
                                        }
                                        component={RenderField}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <YesNoSelector
                        name={`enrollment[enrolling][additionalInfo][questions][${INDEX.CHRONIC_CONDITION}][response]`}
                        label={`2. ${ELIGIBILITY_QUESTIONS.chronicCondition}`}
                        required
                        addLineBreak={true}
                    />
                    <YesNoSelector
                        name={`enrollment[enrolling][additionalInfo][questions][${INDEX.ELLIGIBLE_MEDICAID}][response]`}
                        label={`3. ${ELIGIBILITY_QUESTIONS.eligibleStateMedicaid}`}
                        required
                        addLineBreak={true}
                    />
                    <YesNoSelector
                        name={`enrollment[enrolling][additionalInfo][questions][${INDEX.ENROLLED_MEDICAID}][response]`}
                        label={`4. ${ELIGIBILITY_QUESTIONS.enrolledStateMedicaid}`}
                        onChange={() => resetSubForm(INDEX.ENROLLED_MEDICAID)}
                        required
                        addLineBreak={true}
                    />
                    {enrollment.enrolling.additionalInfo.questions[
                        INDEX.ENROLLED_MEDICAID
                    ].response === YES_NO_OPTION['Yes'].toString() && (
                        <div className="text-left">
                            <p>Please provide your State Medicaid Number</p>
                            <FieldTrimmed
                                name={`enrollment[enrolling][additionalInfo][questions][${INDEX.ENROLLED_MEDICAID}][additionalQuestions][${SUB_QUESTION_INDEX.ENROLLED_MEDICAID.MEDICAID_NUM}][response]`}
                                label="State Medicaid Number"
                                type="text"
                                charLimit={LENGTH_RESTRICTIONS.MEDICAID}
                                component={RenderField}
                                required
                            />
                        </div>
                    )}
                </div>

                <hr />

                <div className="text-left py-5">
                    <h1 className="enrollment-title" ref={paymentOptionRef}>
                        Paying your plan premiums
                    </h1>
                    <p>
                        You can pay your monthly plan premium (including any
                        late enrollment penalty that you currently have or may
                        owe) by mail each month.
                        <br />
                        <strong>
                            You can also choose to pay your premium by having it
                            automatically taken out of your Social Security or
                            Railroad Retirement Board (RRB) benefit each month.
                        </strong>
                    </p>
                    <RadioSelector
                        name="enrollment[enrolling][additionalInfo][premiumPaymentOption]"
                        label="Please select a plan premium and/or late enrollment payment option:"
                        addLineBreak
                        isBold
                        doEnumBasedOnListIndex
                        required
                        list={[
                            'Get a Bill',
                            'Automatic deduction from your monthly Social Security or Railroad Retirement Board (RRB) benefit check. (The Social Security/RRB deduction may take two or more months to begin after Social Security or RRB approves the deduction. In most cases, if Social Security or RRB accepts your request for automatic deduction, the first deduction from your Social Security or RRB benefit check will include all premiums due from your enrollment effective date up to the point withholding begins. If Social Security or RRB does not approve your request for automatic deduction, we will send you a paper bill for your monthly premiums.)',
                        ]}
                    />
                    <p>
                        <strong>
                            If you have to pay a Part D-Income Related Monthly
                            Adjustment Amount (Part D-IRMAA), you must pay this
                            extra amount in addition to your plan premium.
                        </strong>
                        The amount is usually taken out of your Social Security
                        benefit, or you may get a bill from Medicare (or the
                        RRB). DON’T pay Alignment Health Plan the Part D-IRMAA.
                    </p>
                </div>
                <div className="d-flex justify-content-center">
                    <BackButton className="mr-2" onClick={previousPage}>
                        Back
                    </BackButton>
                    <NextButton className="ml-2" type="submit">
                        Continue
                    </NextButton>
                </div>
            </form>
        </div>
    );
};

AddInformation = reduxForm({
    form: 'wizard', //Form name is same
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
})(AddInformation);

AddInformation = connect((state) => {
    return getFormValues('wizard')(state) || {};
})(AddInformation);
export default AddInformation;
