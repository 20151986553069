import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row } from 'react-bootstrap';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { NextButton } from '../../../../../Common/Button/Button';
import { isEqual, pickBy, isEmpty, get } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { RenderItemSelector } from '../../../../actions/renderField';
import { PAGES, SUBPAGES, PAGE_NAME } from '../../../../constants/enum';
import './selectPlans.scss';
import LinkButton from '../../../../components/common/LinkButton';
import PlanSelectionView from './PlanSelectionView';
import PaginationView from '../../../../../Common/Pagination/Pagination';
import { useAppInsightContext } from '../../../../../../contexts/appInsight';

const PLAN_DISPLAY_NUM = 3;
const SORT_OPTIONS = {
    PREMIUM: 'Lowest Monthly Premium',
    OOP_MAX: 'Lowest Out-of-Pocket Max',
};

let SelectPlans = ({
    change,
    comparePlans,
    HealthPlans,
    enrollment,
    navigateSub,
    navigatePage,
}) => {
    // Sample List
    const [selectedComparePlans, setSelectedComparePlans] = useState([]);
    const [showComparePopup, setShowComparePopup] = useState(false);
    const [maxPage, setmaxPage] = useState(null);
    const [sortedPlans, setSortedPlans] = useState([]);
    const [pageDisplayData, setPageDisplayData] = useState({
        currentPage: 1,
        indexStart: 0,
        indexEnd: PLAN_DISPLAY_NUM,
    });
    const appInsights = useAppInsightContext();

    useEffect(() => {
        appInsights.startTrackPage(
            PAGE_NAME[PAGES.SELECT_PLAN][[SUBPAGES.SELECT_PLAN]]
        );
        // Reset Plan Compare Selections
        if (comparePlans) {
            change(`comparePlans`, {});
        }
        if (!isEmpty(HealthPlans)) {
            let sortedStoredPlans = [...HealthPlans].sort(
                (target, other) => target.premiumAmount - other.premiumAmount
            );
            setSortedPlans(sortedStoredPlans);
        }
        return () => {
            change(`sortOption`, '');
            appInsights.stopTrackPage(
                PAGE_NAME[PAGES.SELECT_PLAN][[SUBPAGES.SELECT_PLAN]],
                null,
                {
                    applicationId: get(enrollment, 'applicationId'),
                }
            );
        };
    }, []);

    const removeComparedPlan = (planTitle) => {
        change(`comparePlans[${planTitle}]`, false);
    };

    useEffect(() => {
        comparePlansCheck();
    });
    const comparePlansCheck = () => {
        const checkedPlansFromProps = pickBy(
            comparePlans,
            (checked) => checked
        );
        if (!isEmpty(checkedPlansFromProps)) {
            let comparablePlans = Object.keys(checkedPlansFromProps).filter(
                (keyValue) => checkedPlansFromProps[keyValue]
            );

            if (
                comparablePlans.length > 0 &&
                !isEqual(comparablePlans, selectedComparePlans)
            ) {
                setSelectedComparePlans(comparablePlans);
                setShowComparePopup(true);
                return;
            }
        } else if (showComparePopup) {
            setSelectedComparePlans([]);
            setShowComparePopup(false);
        }
    };
    const captureSort = (option) => {
        let captureSortedPlans = [];
        if (option === SORT_OPTIONS.PREMIUM) {
            captureSortedPlans = [...sortedPlans].sort(
                (target, other) => target.premiumAmount - other.premiumAmount
            );
            setSortedPlans(captureSortedPlans);
        } else if (option === SORT_OPTIONS.OOP_MAX) {
            captureSortedPlans = [...sortedPlans].sort(
                (target, other) =>
                    target.inNetworkMoopLimit - other.inNetworkMoopLimit
            );
            setSortedPlans(captureSortedPlans);
        }
    };
    const savePlan = (index) => {
        const thisPlan = sortedPlans[index];
        change('enrollment.selectPlan.planSummary', thisPlan);
        if (get(enrollment, 'selectPlan.plan') !== thisPlan.planName) {
            change('enrollment.selectPlan.plan', thisPlan.planName);
            // flush PCP
            change('enrollment.selectPlan.physicianInfo', null);
            change('enrollment.selectPlan.physicianNPI', null);
            // flus optional benefits
            change('enrollment.selectPlan.optionalPlanName', null);
            change('enrollment.selectPlan.optionalPlanSummary', null);
        }
    };
    const renderCompareButton = () => {
        if (selectedComparePlans.length > 3)
            return (
                <p className="text-danger">
                    You can compare up to three plans. Please deselect one to
                    continue.
                </p>
            );
        return (
            <NextButton
                className="compare-btn"
                onClick={() => navigateSub(SUBPAGES.COMPARE_PLAN)}
            >
                Compare
            </NextButton>
        );
    };
    const navigateSubPage = (pageNum) => {
        if (pageDisplayData.currentPage !== pageNum) {
            let indexStart = PLAN_DISPLAY_NUM * (pageNum - 1);
            let indexEnd = PLAN_DISPLAY_NUM * pageNum;

            setPageDisplayData({ currentPage: pageNum, indexStart, indexEnd });
        }
    };

    const countyString = get(enrollment, 'findPlan.counties', null);
    return (
        <div className="select-plans">
            <div className="col-md header">
                <h1 className="text-left">
                    Available plans in zip code{' '}
                    <span>
                        <LinkButton
                            className="clickable display-contents zip-county-btn"
                            onClick={() => navigatePage(PAGES.FIND_PLAN)}
                        >
                            <span className="ml-1">
                                {enrollment.findPlan['zipCode']}
                            </span>
                            <span className="county ml-2">
                                {countyString && `(${countyString})`}
                            </span>
                        </LinkButton>
                    </span>
                </h1>
                <p className="plans-subtext">
                    Our plans are tailored to accommodate your health needs. To
                    select a plan, click the radio button on the plan card and
                    click "Continue" at the bottom of the page.
                </p>
            </div>
            <div className="row d-flex align-items-center">
                <div className="row col-xs-12 col-md-6 pt-2 sort-by-wrapper">
                    <div className="pt-3 pr-2">Sort by</div>
                    <Field
                        name="sortOption"
                        id="sortByOption"
                        ariaLabel="Sort By Option"
                        className="sort-dropdown"
                        onChange={(e) => {
                            captureSort(e.target.value);
                        }}
                        type="select"
                        list={Object.values(SORT_OPTIONS)}
                        component={RenderItemSelector}
                    />
                </div>
                <div className="pagination-wrapper col-xs-12 col-md-6 pt-3">
                    <p className="pagination-text">
                        {pageDisplayData.currentPage} of {maxPage} pages (
                        {sortedPlans.length} plans)
                    </p>
                    <div className="pr-3 pl-3 mr-3">
                        <PaginationView
                            totalRecordsCount={sortedPlans.length}
                            pageSize={PLAN_DISPLAY_NUM}
                            pageCount={2}
                            goToPage={navigateSubPage}
                            currentPage={pageDisplayData.currentPage}
                            onLoad={({ totalPages }) => setmaxPage(totalPages)}
                        />
                    </div>
                </div>
            </div>
            {showComparePopup && (
                <div
                    role="complementary"
                    className="d-flex justify-content-center"
                    data-testid="ComparePlansPopup"
                >
                    <div className="text-left compare-view">
                        <ListGroup>
                            <ListGroupItem className="compare-header">
                                Compare Plans{' '}
                                <FontAwesomeIcon
                                    className="float-right"
                                    style={{ cursor: 'pointer' }}
                                    aria-label="Close Compare Plans Popup"
                                    icon={faTimes}
                                    onClick={() =>
                                        selectedComparePlans.map((item) =>
                                            removeComparedPlan(item)
                                        )
                                    }
                                    data-testid="CloseComparePopup"
                                />
                            </ListGroupItem>
                            {selectedComparePlans.map((item, i) => {
                                return (
                                    <ListGroupItem
                                        key={`comparePlansList_${i}`}
                                        className="align-middle compare-item"
                                    >
                                        {item}
                                        <FontAwesomeIcon
                                            className="float-right"
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            aria-label="Remove Plan from Compare Plans list"
                                            icon={faTimes}
                                            onClick={() =>
                                                removeComparedPlan(item)
                                            }
                                            data-testid={`RemovePlan_${i}`}
                                        />
                                    </ListGroupItem>
                                );
                            })}
                            <ListGroupItem className="text-center">
                                {renderCompareButton()}
                            </ListGroupItem>
                        </ListGroup>{' '}
                    </div>
                </div>
            )}
            <Row
                className="plans pt-1"
                role="radiogroup"
                aria-label="Select Plan Benefit Package"
            >
                <PlanSelectionView
                    indexStart={pageDisplayData.indexStart}
                    indexEnd={pageDisplayData.indexEnd}
                    HealthPlans={sortedPlans}
                    enrollment={enrollment}
                    savePlan={savePlan}
                    showCardBenefits={
                        get(enrollment, 'findPlan.coverageYear') === '2023'
                    }
                />
            </Row>
        </div>
    );
};

SelectPlans = reduxForm({
    form: 'wizard', //Form name is same
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(SelectPlans);

SelectPlans = connect((state) => {
    return getFormValues('wizard')(state);
})(SelectPlans);
export default SelectPlans;
