import React, { useState, useEffect, useCallback } from 'react';
import { Input, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, Field } from 'redux-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faSearch } from '@fortawesome/free-solid-svg-icons';

import { isNil, isEmpty, debounce, get } from 'lodash';
import classnames from 'classnames';

import PhysicianInfo from './PhysicianInfo';
import PaginationView from '../../../../../Common/Pagination/Pagination';
import { getProviderDetails } from '../../../../../../api/enrollment';
import {
    getNumGroups,
    getNumLocations,
    getNearestLocation,
    getSpecialtyDesc,
} from '../../../../util/physicianInfo';
import './subviewProvider.scss';
import { useConfigContext } from '../../../../../../contexts/config';
import LinkWithAppInsights from '../../../../../../components/Common/LinkWithAppInsights';
import { APPINSIGHT_EVENTS } from '../../../../../../constants/appInsightEvents';
import { default as CommonButton } from '../../../../../Common/Button/Button';
import { formatPhysicianName } from '../../../../util/format';

const PHYSICIAN_DISPLAY_NUM = 5;

const SubviewProviderComponent = (props) => {
    const { enrollment, change, pbp, contractCode } = props;
    const [physicianSelection, setPhysicianSelection] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loadingState, setLoadingState] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalNPI, setTotalNPI] = useState();
    const { PROVIDER_SEARCH_URL } = useConfigContext();

    useEffect(() => {
        getProviderWithSearchQuery();
    }, []);

    const delayedQuery = useCallback(
        debounce((q) => {
            setCurrentPage(1);
            return getProviderWithSearchQuery(q);
        }, 500),
        []
    );
    const getProviderWithSearchQuery = (query = '', page = 1) => {
        setLoadingState(true);
        getProviderDetails(
            enrollment.findPlan.zipCode,
            pbp,
            enrollment.findPlan.coverageYear,
            contractCode,
            query,
            page,
            PHYSICIAN_DISPLAY_NUM
        )
            .then((data) => {
                setPhysicianSelection(get(data, 'providers', []));
                setTotalNPI(get(data, 'totalNPICount', 0));
                setLoadingState(false);
            })
            .catch(() => {
                setLoadingState(false);
            });
    };
    const onChange = (e) => {
        setSearchQuery(e.target.value);
        delayedQuery(e.target.value);
    };
    const keyPressed = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            return false;
        }
    };
    const navigatePage = (pageNum) => {
        if (currentPage !== pageNum) {
            setCurrentPage(pageNum);
            getProviderWithSearchQuery(searchQuery, pageNum);
        }
    };

    return (
        <div className="subview-provider">
            <div className="ml-0 ml-md-4">
                <div className="search-container">
                    <Input
                        aria-label="Search Provider by PCP Name or NPI"
                        type="search"
                        placeholder="Search by PCP Name or NPI"
                        onChange={onChange}
                        onKeyPress={keyPressed}
                        value={searchQuery}
                    />
                    <div className="search-btn">
                        <CommonButton
                            aria-label="Search for Provider"
                            onClick={() =>
                                getProviderWithSearchQuery(searchQuery)
                            }
                        >
                            <FontAwesomeIcon
                                icon={faSearch}
                                aria-label="Search icon button for provider"
                                aria-hidden="false"
                            />
                        </CommonButton>
                    </div>
                </div>

                <p className="subtitle-text mt-2">
                    Showing Primary Care Providers within 35 miles of zip code{' '}
                    {enrollment.findPlan['zipCode']}
                </p>
                <div className="provider-link">
                    <LinkWithAppInsights
                        href={PROVIDER_SEARCH_URL}
                        target="_blank"
                        appInsightEventName={
                            APPINSIGHT_EVENTS.LINK_CLICK_OTHER_PROVIDERS
                        }
                    >
                        Search for other providers{' '}
                        <FontAwesomeIcon
                            icon={faChevronRight}
                            aria-label="chevron-right"
                        />
                    </LinkWithAppInsights>
                </div>
            </div>

            {loadingState ? (
                <div className="box text-center py-5">
                    <Spinner />
                </div>
            ) : (
                <>
                    {!isEmpty(physicianSelection) ? (
                        physicianSelection.map((physician, i) => {
                            const nearestLocation =
                                getNearestLocation(physician);
                            return (
                                <div
                                    className="py-2 plan-subview"
                                    key={`physician_${i}`}
                                >
                                    <PhysicianInfo
                                        name={formatPhysicianName(physician)}
                                        npi={physician.npi}
                                        pcp={`${getSpecialtyDesc(
                                            get(physician, 'specialities')
                                        )}`}
                                        location={nearestLocation}
                                        selectPhysician={() => {}}
                                        groupNum={getNumGroups(physician)}
                                        locationNum={getNumLocations(physician)}
                                        distance={nearestLocation.distanceFromGivenAddress.toFixed(
                                            2
                                        )}
                                        physicianInfo={physician}
                                        enrollment={enrollment}
                                        change={change}
                                        ratingQuality={`${get(
                                            physician,
                                            'ratings.qualityRating'
                                        )}`}
                                    />
                                </div>
                            );
                        })
                    ) : (
                        <div className="pb-2">
                            No matches found for: <strong>{searchQuery}</strong>
                        </div>
                    )}
                </>
            )}
            <div className="provider-pagination">
                <PaginationView
                    totalRecordsCount={totalNPI}
                    pageSize={PHYSICIAN_DISPLAY_NUM}
                    pageCount={3}
                    goToPage={navigatePage}
                    currentPage={currentPage}
                />
            </div>
        </div>
    );
};
const SubviewProvider = reduxForm({
    form: 'wizard', //Form name is same
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(
    connect((state) => {
        return getFormValues('wizard')(state) || {};
    })(SubviewProviderComponent)
);

export default SubviewProvider;
