import React, { useState } from 'react';
import NoProfile from '../../../../../../assets/img/no-profile.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlusSquare,
    faMapMarkerAlt,
    faHospitalAlt,
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './physicianInfo.scss';
import { Row, Col } from 'reactstrap';
import ProviderListModal from './ProviderListModal';
import { Field } from 'redux-form';
import { get } from 'lodash';
import CustomIcon from '../../../../../Common/Icon/CustomIcon';
import { PROVIDERS_RATING } from '../../../../constants/enum';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const ProviderBadges = ({ groupNum, locationNum, distanceAway }) => (
    <div className="mb-4">
        <Row className="physician-stats no-gutters">
            <div className="physician-groups">
                <div>
                    <FontAwesomeIcon
                        className="info-icon"
                        icon={faPlusSquare}
                        aria-label="Group Icon"
                    />{' '}
                    {groupNum}
                </div>

                <div>Groups</div>
            </div>
            <div className="physician-locations">
                <div>
                    <FontAwesomeIcon
                        className="info-icon"
                        icon={faHospitalAlt}
                        aria-label="Locations Icon"
                    />{' '}
                    {locationNum}
                </div>
                <div>Locations</div>
            </div>
            <div className="physician-distance">
                <FontAwesomeIcon
                    className="landmark-icon"
                    size="2x"
                    icon={faMapMarkerAlt}
                    aria-label="Distance Icon"
                />{' '}
                <span>
                    {distanceAway} <br /> miles away
                </span>
            </div>
        </Row>
    </div>
);
const rateTooltip = (
    <Tooltip id="tooltip" className="rate-tooltip">
        High Quality PCP badge is a calculation of several factors including the
        provider’s HEDIS, CAHPS, member experience, and Disenrollment
        performance history.
        <br />
        <br />
        For more information please contact your member experience / partner
        experience representative.
    </Tooltip>
);
const PhysicianInfo = (props) => {
    const {
        npi,
        name,
        location,
        pcp,
        showImage,
        canSelect,
        selectPhysician,
        groupNum,
        locationNum,
        distance,
        physicianInfo,
        enrollment,
        change,
        ratingQuality,
    } = props;
    const [showModal, setShowModal] = useState(false);
    const selectedNPI = get(enrollment, 'selectPlan.physicianInfo.npi');
    const handleCloseModal = (selectedProviderID) => {
        if (canSelect) {
            if (!selectedProviderID) {
                const chosenPhysician = get(
                    enrollment,
                    'selectPlan.physicianInfo'
                );
                if (!chosenPhysician.provID) {
                    change('enrollment.selectPlan.physicianInfo', null);
                    change('enrollment.selectPlan.physicianNPI', null);
                }
            }
        }
        setShowModal(false);
    };
    return (
        <>
            {canSelect ? (
                <div className="enlarged-radio">
                    <Field
                        className="float-left m-2"
                        aria-label={`${name} - ${pcp} - ${location.address1} ${location.city}, ${location.state} ${location.zip}`}
                        name="enrollment[selectPlan][physicianNPI]"
                        component="input"
                        type="radio"
                        onChange={() => {
                            selectPhysician();
                            setShowModal(true);
                        }}
                        value={npi}
                        data-testid={`select_${npi}`}
                        checked={selectedNPI === npi}
                    />{' '}
                </div>
            ) : null}
            <div
                className={classnames('physician-info', {
                    'physician-img-info': showImage,
                })}
            >
                {showImage && (
                    <img
                        src={NoProfile}
                        className="physician-img"
                        alt="profile-img"
                    />
                )}

                <div className="physician">
                    <Row className="provider-header">
                        <Col md={9} className="px-0">
                            <span
                                className="physician-name clickable"
                                onClick={() => {
                                    if (canSelect) {
                                        selectPhysician();
                                    }
                                    setShowModal(true);
                                }}
                            >
                                {name}
                            </span>
                        </Col>

                        {Object.values(PROVIDERS_RATING).includes(
                            Number(ratingQuality)
                        ) && (
                            <Col md={3} className="rate-badge">
                                {PROVIDERS_RATING.GOLD.toString() ===
                                    ratingQuality && (
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={rateTooltip}
                                    >
                                        <CustomIcon
                                            icon="gold-badge"
                                            className="badge-icon"
                                        />
                                    </OverlayTrigger>
                                )}
                                {PROVIDERS_RATING.SILVER.toString() ===
                                    ratingQuality && (
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={rateTooltip}
                                    >
                                        <CustomIcon
                                            icon="silver-badge"
                                            className="badge-icon"
                                        />
                                    </OverlayTrigger>
                                )}
                            </Col>
                        )}
                    </Row>
                    <div>
                        <ul className="text-left physician-details no-bullet">
                            <li>
                                <strong>NPI: </strong>
                                {npi}
                            </li>
                            <li>
                                <strong>PCP - </strong>
                                {pcp}
                            </li>
                            <li className="location">
                                <strong>Nearest Location: </strong>
                                {location.address1} <br />
                                {location.city}, {location.state} {location.zip}
                            </li>
                        </ul>
                        <ProviderBadges
                            groupNum={groupNum}
                            locationNum={locationNum}
                            distanceAway={distance}
                        />
                        <hr className="m-0" />
                    </div>
                </div>
                <ProviderListModal
                    showModal={showModal}
                    handleClose={handleCloseModal}
                    npi={npi}
                    name={name}
                    physicianInfo={physicianInfo}
                    enrollment={enrollment}
                    change={change}
                    canSelect={canSelect}
                />
            </div>
        </>
    );
};
PhysicianInfo.propTypes = {
    name: PropTypes.string.isRequired,
    npi: PropTypes.string.isRequired,
    pcp: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    groupNum: PropTypes.number.isRequired,
    locationNum: PropTypes.number.isRequired,
    distance: PropTypes.string.isRequired,
    ratingQuality: PropTypes.string,

    suffix: PropTypes.string,
    selectPhysician: PropTypes.func,
    showImage: PropTypes.bool,
    canSelect: PropTypes.bool,
};
export default PhysicianInfo;
