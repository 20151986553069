import React from 'react';
import { Layout } from './components/Layout';
import SubmitApplication from './components/Enrollment';
import { Route, Routes } from 'react-router-dom';

import './assets/css/bootstrap.css';
import './assets/css/avabroker.css';
import './assets/css/animate.min.css';
import './assets/css/demo.css';
import './custom.css';
import './syncfusion.scss';
import { WithConfigContext } from './contexts/config';
import { WithAppInsightContext } from './contexts/appInsight';
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';
import { Spinner } from 'react-bootstrap';
import MockGoldenCommInterface from './components/Enrollment/MockGoldenCommInterface';
import { FEATURE_IDS } from 'constants/identifiers';
import ConditionalRoute from 'components/Routing/ConditionalRoute';

const App = () => {
    const isProduction = process.env.NODE_ENV === 'production';

    return (
        <CacheBuster
            currentVersion={packageInfo.version}
            isEnabled={isProduction} //If false, the library is disabled.
            isVerboseMode={false} //If true, the library writes verbose logs to console.
            loadingComponent={<Spinner />} //If not pass, nothing appears at the time of new version check.
        >
            <WithConfigContext>
                <WithAppInsightContext>
                    <Layout>
                        <Routes>
                            <Route path="/" element={SubmitApplication} />
                            <Route
                                path="/test-shopping"
                                element={
                                    <ConditionalRoute
                                        flagId={FEATURE_IDS.GC_PLAN_CONSOLE}
                                        component={MockGoldenCommInterface}
                                    />
                                }
                            />
                        </Routes>
                        <SubmitApplication />
                    </Layout>
                </WithAppInsightContext>
            </WithConfigContext>
        </CacheBuster>
    );
};

export default App;
