const handleEnterKey = (handleFunc) => (e) => {
    if (e.key === 'Enter' && typeof handleFunc === 'function') {
        handleFunc();
    }
};
const handleEnterKeyToClick = (e) => {
    handleEnterKey(() => {
        const self = e.target;
        if (self.click && typeof self.click === 'function') {
            self.click(e);
        }
    })(e);
};
export { handleEnterKey, handleEnterKeyToClick };
