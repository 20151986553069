import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useFeatureFlagContext } from 'contexts/config';

const ConditionalRoute = ({ component: Component, flagId }) => {
    const ConditionalComponent = (props) => {
        const navigate = useNavigate();
        const featureFlags = useFeatureFlagContext();

        const meetsCondition = isEmpty(flagId) || featureFlags[flagId];

        if (!meetsCondition) {
            navigate.push('/');
        }
        return <Component {...props} />;
    };
    return ConditionalComponent;
};

ConditionalRoute.propTypes = {
    defaultPath: PropTypes.string,
};
ConditionalRoute.defaultProps = {
    defaultPath: '/',
    component: () => null,
};

export default ConditionalRoute;
