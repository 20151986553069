import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { formatCost } from '../../../util/format';
import { BackButton, NextButton } from '../../../../Common/Button/Button';
import { PAGES, PAGE_NAME } from '../../../constants/enum';
import { Row, Col, Button } from 'reactstrap';
import EnrollInfo from './SelectionPlanSummary-EnrollInfo';
import { get, isEmpty } from 'lodash';
import { useAppInsightContext } from '../../../../../contexts/appInsight';
import './selectionPlanSummary.scss';
import AttentionModal from '../../../components/common/AttentionModal/AttentionModal';
import { RESET_ENROLLMENT_WARNING } from '../../../constants/alertMessages';
import { useConfigContext } from 'contexts/config';

const SelectionPlanSummaryView = (props) => {
    const { enrollment, onSubmit, previousPage, hasGoldenCommmOrigin } = props;
    const appInsights = useAppInsightContext();
    const [showModal, setShowModal] = useState(false);
    const { GOLDEN_COMM_SHOPPING_URL } = useConfigContext();
    const doesNotIncludeOptionalBenefits = isEmpty(
        get(enrollment, 'selectPlan.planSummary.optionalBenefitPlans[0]')
    );

    useEffect(() => {
        window._mfq.push(['newPageView', 'SelectionPlanSummaryPage']);
        window.analytics.page('Summary of Plan Selection');

        appInsights.startTrackPage(PAGE_NAME[PAGES.SUMMARY]);

        return () => {
            appInsights.stopTrackPage(PAGE_NAME[PAGES.SUMMARY], null, {
                applicationId: get(enrollment, 'applicationId'),
            });
        };
    }, []);

    const toggleModal = () => {
        setShowModal(!showModal);
    };
    const alertToRestoreProcess = () => {
        setShowModal(true);
    };

    return (
        <div className="plan-summary">
            <AttentionModal
                toggleModal={toggleModal}
                showModal={showModal}
                bodyComponent={RESET_ENROLLMENT_WARNING}
                buttonsComponent={
                    <div>
                        <Button
                            className="attention-no-btn"
                            onClick={toggleModal}
                        >
                            NO
                        </Button>
                        <Button
                            className="attention-yes-btn"
                            onClick={() => {
                                toggleModal();
                                window.location.href = GOLDEN_COMM_SHOPPING_URL;
                            }}
                        >
                            YES
                        </Button>
                    </div>
                }
            />
            <Row className="pt-5 justify-content-md-center find-plan">
                <Col
                    lg={9}
                    className="d-flex justify-content-md-center flex-column"
                >
                    <div
                        className="selected-plan-info"
                        role="heading"
                        aria-level="1"
                    >
                        <span className="directive">Thanks for selecting</span>
                        <ul className="plan-details">
                            <li className="plan-names">
                                {enrollment.selectPlan.planSummary.planName} -
                                Plan Year {enrollment.findPlan.coverageYear} -{' '}
                                <span className="plan-cost">
                                    {formatCost(
                                        parseFloat(
                                            enrollment.selectPlan.planSummary
                                                .premiumAmount
                                        )
                                    )}
                                </span>
                            </li>
                            {enrollment.selectPlan.optionalPlanName && (
                                <li className="plan-names no-bold">
                                    {enrollment.selectPlan.optionalPlanName} -{' '}
                                    <span>
                                        {formatCost(
                                            parseFloat(
                                                enrollment.selectPlan
                                                    .optionalPlanSummary
                                                    .premiumAmount
                                            )
                                        )}
                                    </span>
                                </li>
                            )}
                        </ul>
                        <span className="directive">
                            We are excited to see you be a part of the Alignment
                            family!
                        </span>
                    </div>
                    <br />
                    <EnrollInfo
                        isSNPPrequalFormRequired={get(
                            enrollment,
                            'selectPlan.planSummary.isSNPPrequalFormRequired'
                        )}
                    />

                    <p className="directive call-to-action">
                        Ready to enroll in this plan? Click "Enroll"
                    </p>

                    <div className="d-flex justify-content-center mt-5">
                        <BackButton
                            className="mr-2"
                            onClick={
                                hasGoldenCommmOrigin &&
                                doesNotIncludeOptionalBenefits
                                    ? alertToRestoreProcess
                                    : previousPage
                            }
                            color="secondary"
                        >
                            Back
                        </BackButton>
                        <NextButton
                            className="ml-2"
                            onClick={onSubmit}
                            variant="warning"
                        >
                            Enroll
                        </NextButton>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

const SelectionPlanSummary = connect((state) => {
    return getFormValues('wizard')(state);
})(SelectionPlanSummaryView);

export default SelectionPlanSummary;
