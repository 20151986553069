import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import validate from '../../../actions/validate';
import moment from 'moment';

let ReviewMedicare = (props) => {
    const { enrollment } = props;
    return (
        <div className="row">
            <div className="col-md-5">
                <ul className="no-bullet list-align-left">
                    <li>
                        Your Medicare Beneficiary Number:{' '}
                        <strong>
                            {enrollment.enrolling.clientInfo['medicareID']}
                        </strong>
                    </li>
                </ul>
            </div>
            <div className="col-md-5">
                <ul className="no-bullet list-align-left">
                    <li>
                        Hospital Insurance Benefits (Part A) Date: <br />
                        <strong>
                            {moment(
                                enrollment.enrolling.clientInfo[
                                    'medicareEligibilityDatePartA'
                                ]
                            ).format('MM/DD/YYYY')}
                        </strong>
                    </li>
                    <li>
                        Medical Insurance Benefits (Part B) Date: <br />
                        <strong>
                            {moment(
                                enrollment.enrolling.clientInfo[
                                    'medicareEligibilityDatePartB'
                                ]
                            ).format('MM/DD/YYYY')}
                        </strong>
                    </li>
                </ul>
            </div>
        </div>
    );
};

ReviewMedicare = reduxForm({
    form: 'wizard', //Form name is same
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
})(ReviewMedicare);

ReviewMedicare = connect((state) => {
    return getFormValues('wizard')(state) || {};
})(ReviewMedicare);

export default ReviewMedicare;
