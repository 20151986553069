import React, { useEffect } from 'react';
import './styles/index.scss';
import { Provider } from 'react-redux';
import store from '../../store';
import WizardForm from './components/AHCEnrollment';
import submitApplication from './actions/submitApplication';

let SubmitApplication = () => {
    return (
        <Provider
            store={store}
            className="border"
            style={{
                minHeight: '100%',
                height: '100%',
            }}
        >
            <div className="pt-3 px-0 py-0">
                <WizardForm onSubmit={submitApplication} />
            </div>
        </Provider>
    );
};
export default SubmitApplication;
