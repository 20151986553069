import { get, isEmpty } from 'lodash';

const getNumGroups = (physician) => {
    return get(physician, 'ahRecords.length', 0);
};
const getNumLocations = (physician) => {
    const ahRecords = get(physician, 'ahRecords', []);
    let accountedAddresses = [];
    for (const record of ahRecords) {
        const addresses = get(record, 'addresses', []);
        for (const address of addresses) {
            const addressKey = `${address.address1} ${address.address2} ${address.city} ${address.state} ${address.count} ${address.zip}`;
            if (!accountedAddresses.includes(addressKey)) {
                accountedAddresses.push(addressKey);
            }
        }
    }
    return accountedAddresses.length;
};
const getNearestLocation = (physician) => {
    const addresses = get(physician, 'ahRecords[0].addresses', []);
    if (isEmpty(addresses)) return {};
    return addresses.reduce((nearest, next) =>
        nearest.distanceFromGivenAddress > next.distanceFromGivenAddress
            ? next
            : nearest
    );
};
const getSpecialtyDesc = (specialties) => {
    if (specialties)
        return specialties.map((specialty) => specialty.desc).join(', ');
};

export { getNumGroups, getNumLocations, getNearestLocation, getSpecialtyDesc };
