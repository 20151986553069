import { isNil } from 'lodash';
import moment from 'moment';

const validateEmail = (value) => {
    if (value) {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            return false;
        }
    }
    return true;
};
const validateFirstLastName = (value) => {
    if (value) {
        if (!/^[a-zA-Z\.\-]+ [a-zA-Z\.\-]+/i.test(value)) {
            return false;
        }
    }
    return true;
};
const validateDatePast = (value) => {
    if (value) {
        if (new Date() < new Date(value)) {
            return false;
        }
    }
    return true;
};

// Checks xxx-xxx-xxxx is proper length
const validatePhoneNumberLength = (value) => {
    if (value) {
        const match = value.toString().match(/\d/g);
        if (match && match.length !== 10) {
            return false;
        }
    }
    return true;
};
const validateExactInputLength = (value, count) => {
    if (value) {
        if (isNil(value.length) || value.length !== count) {
            return false;
        }
    }
    return true;
};
const validateDateFormat = (dateValue) => {
    const minDate = '1800-01-01';
    return (
        moment(dateValue, 'YYYY-MM-DD').format('YYYY-MM-DD') === dateValue &&
        moment(dateValue).isAfter(minDate)
    );
};
export {
    validateEmail,
    validateFirstLastName,
    validateDatePast,
    validatePhoneNumberLength,
    validateExactInputLength,
    validateDateFormat,
};
