import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { first, get, isEmpty, slice } from 'lodash';
import moment from 'moment';
import { NextButton, BackButton } from '../../../../../Common/Button/Button';
import validate from '../../../actions/validate';
import {
    RenderField,
    RenderCheckbox,
    RenderDatePicker,
    RenderMultiSelectField,
} from '../../../../actions/renderField';
import { YesNoSelector } from '../../../../components/FormReuse/RadioSelector';
import CategorySurvey from '../../../../components/FormReuse/CategorySurvey';
import {
    DIABETES_SURVEY,
    CARDIOVASCULAR_SURVEY,
    HEART_FAILURE_SURVEY,
    ESRD_SURVEY,
    MENTAL_HEALTH_SURVEY,
    MENTAL_HEALTH_CONDITIONS,
    LUNG_DISORDER_SURVEY,
    LUNG_DISORDER_CONDITIONS,
} from '../../../../constants/prequalification';
import {
    QUESTION_INDEX,
    PAGES,
    SUBPAGES,
    PAGE_NAME,
    YES_NO_OPTION,
} from '../../../../constants/enum';
import { normalizePhone } from '../../../../util/normalize';
import { LENGTH_RESTRICTIONS } from '../../../../constants/input';
import { ElectronicSignatureAgreementText } from '../../../../components/FormReuse/ElectronicSignature';
import FieldTrimmed from '../../../../actions/FieldTrimmed';
import { useAppInsightContext } from '../../../../../../contexts/appInsight';
import AttentionModal from 'components/Enrollment/components/common/AttentionModal/AttentionModal';

const INDEX = QUESTION_INDEX.ENROLLMENT.SNP_PREQUALIFICATION;

let PreQualification = (props) => {
    const { enrollment, handleSubmit, previousPage, change, onSubmit } = props;
    const appInsights = useAppInsightContext();
    const [showModal, setShowModal] = useState(false);

    const specialNeedsCategory =
        enrollment.selectPlan.planSummary.specialNeedsCategory;
    const is2024Plan = enrollment.findPlan.coverageYear === '2024';
    const warningIndexExclusion = [
        INDEX.HOMEBOUND,
        INDEX.ESRD_1,
        INDEX.ESRD_2,
        ...(!is2024Plan
            ? [
                  INDEX.LUNG_1,
                  INDEX.LUNG_2,
                  INDEX.LUNG_3,
                  INDEX.LUNG_4,
                  INDEX.MENTAL_1,
                  INDEX.MENTAL_2,
                  INDEX.MENTAL_3,
                  INDEX.MENTAL_4,
              ]
            : []),
    ];

    const saveFormattedDate = (path, value) => {
        change(path, value);
    };
    useEffect(() => {
        appInsights.startTrackPage(
            PAGE_NAME[PAGES.ENROLLMENT][SUBPAGES.SNP_PREQUALIFICATION]
        );

        const currentDate = moment().format('YYYY-MM-DD');
        if (
            isEmpty(
                get(enrollment, 'enrolling.prequalSNP.beneficiarySignatureDate')
            )
        ) {
            change(
                'enrollment.enrolling.prequalSNP.beneficiarySignatureDate',
                currentDate
            );
        }

        return () => {
            appInsights.stopTrackPage(
                PAGE_NAME[PAGES.ENROLLMENT][SUBPAGES.SNP_PREQUALIFICATION],
                null,
                {
                    applicationId: get(enrollment, 'applicationId'),
                }
            );
        };
    }, []);

    const checkSNPQualification = () => {
        const questions = get(enrollment, 'enrolling.prequalSNP.questions');
        let isSnpQualified = false;
        if (specialNeedsCategory === 'Heart & Diabetes') {
            isSnpQualified = questions.some(
                (question, index) =>
                    question.response !== YES_NO_OPTION.No.toString() &&
                    question.response !== 'NULL' &&
                    !warningIndexExclusion.includes(index)
            );
        } else if (specialNeedsCategory === 'ESRD') {
            isSnpQualified =
                questions[INDEX.ESRD_1].response ===
                    YES_NO_OPTION.Yes.toString() ||
                questions[INDEX.ESRD_2].response ===
                    YES_NO_OPTION.Yes.toString();
        } else {
            isSnpQualified = questions.some(
                (question) =>
                    question.response !== YES_NO_OPTION.No.toString() &&
                    question.response !== 'NULL'
            );
        }
        if (isSnpQualified) {
            handleSubmit(onSubmit)();
        } else {
            setShowModal(true);
        }
    };

    return (
        <div>
            <div className="text-left">
                <h1 className="enrollment-title">
                    Supplemental clinical pre-qualification questions
                </h1>
                <p className="py-2">
                    Please answer the following questions. If the answer is
                    "Yes" to at least one of the questions, you will pre-qualify
                    for the condition.
                </p>
                <YesNoSelector
                    name={`enrollment[enrolling][prequalSNP][questions][${INDEX.HOMEBOUND}][response]`}
                    label="Do you consider yourself to be homebound?"
                    addLineBreak={true}
                    required
                />
            </div>
            <div className="text-left" data-testid="snpQuestions">
                <CategorySurvey
                    categoryTitle="Diabetes"
                    questions={Object.values(DIABETES_SURVEY)}
                    startIndex={INDEX.DIABETES_1}
                    required
                />

                <CategorySurvey
                    categoryTitle="Cardiovascular Disorders"
                    questions={Object.values(CARDIOVASCULAR_SURVEY)}
                    startIndex={INDEX.CARDIO_1}
                    required
                />
                <CategorySurvey
                    categoryTitle="Chronic Heart Failure"
                    questions={Object.values(HEART_FAILURE_SURVEY)}
                    startIndex={INDEX.CHRONIC_1}
                    required
                />
                {!is2024Plan && (
                    <>
                        <CategorySurvey
                            categoryTitle="Chronic Lung Disorder"
                            questions={slice(
                                Object.values(LUNG_DISORDER_SURVEY),
                                1
                            )}
                            startIndex={INDEX.LUNG_2}
                            required
                        />

                        <div>
                            <Field
                                name={`enrollment[enrolling][prequalSNP][questions][${INDEX.LUNG_1}][response]`}
                                label={first(
                                    Object.values(LUNG_DISORDER_SURVEY)
                                )}
                                options={LUNG_DISORDER_CONDITIONS}
                                component={RenderMultiSelectField}
                                required
                            />
                        </div>
                        <CategorySurvey
                            categoryTitle="Mental Health"
                            questions={slice(
                                Object.values(MENTAL_HEALTH_SURVEY),
                                1
                            )}
                            startIndex={INDEX.MENTAL_2}
                            required
                        />
                        <div>
                            <Field
                                name={`enrollment[enrolling][prequalSNP][questions][${INDEX.MENTAL_1}][response]`}
                                label={first(
                                    Object.values(MENTAL_HEALTH_SURVEY)
                                )}
                                options={MENTAL_HEALTH_CONDITIONS}
                                component={RenderMultiSelectField}
                                required
                            />
                        </div>
                    </>
                )}
                {enrollment.selectPlan.planSummary.isESRDFormRequired && (
                    <CategorySurvey
                        categoryTitle="End Stage Renal Disease (ESRD)"
                        questions={Object.values(ESRD_SURVEY)}
                        startIndex={INDEX.ESRD_1}
                        required
                    />
                )}
            </div>
            <h5 className="enrollment-title py-3">
                Physician Currently Treating the Applicant for Qualifying
                Disease(s)
            </h5>
            <p>
                Please enter either the Primary Care Provider or Specialist Name
                with Phone Numbers.
            </p>
            <div className="row">
                <div className="col-md-3">
                    <FieldTrimmed
                        name="enrollment[enrolling][prequalSNP][physicianName]"
                        label="Physician Name"
                        type="text"
                        charLimit={LENGTH_RESTRICTIONS.GENERAL_INFO}
                        component={RenderField}
                        required={
                            !(
                                enrollment.enrolling.prequalSNP
                                    .specialistName &&
                                enrollment.enrolling.prequalSNP.specialistPhone
                            ) || enrollment.enrolling.prequalSNP.physicianPhone
                        }
                    />
                </div>
                <div className="col-md-3">
                    <FieldTrimmed
                        name="enrollment[enrolling][prequalSNP][physicianPhone]"
                        label="Physician Phone #"
                        type="text"
                        component={RenderField}
                        normalize={normalizePhone}
                        required={
                            !(
                                enrollment.enrolling.prequalSNP
                                    .specialistName &&
                                enrollment.enrolling.prequalSNP.specialistPhone
                            ) || enrollment.enrolling.prequalSNP.physicianName
                        }
                    />
                </div>
                <div className="col-md-3">
                    <FieldTrimmed
                        name="enrollment[enrolling][prequalSNP][specialistName]"
                        label="Specialist Name"
                        charLimit={LENGTH_RESTRICTIONS.GENERAL_INFO}
                        type="text"
                        component={RenderField}
                        required={
                            !(
                                enrollment.enrolling.prequalSNP.physicianName &&
                                enrollment.enrolling.prequalSNP.physicianPhone
                            ) || enrollment.enrolling.prequalSNP.specialistPhone
                        }
                    />
                </div>
                <div className="col-md-3">
                    <FieldTrimmed
                        name="enrollment[enrolling][prequalSNP][specialistPhone]"
                        label="Specialist Phone #"
                        type="text"
                        component={RenderField}
                        normalize={normalizePhone}
                        required={
                            !(
                                enrollment.enrolling.prequalSNP.physicianName &&
                                enrollment.enrolling.prequalSNP.physicianPhone
                            ) || enrollment.enrolling.prequalSNP.specialistName
                        }
                    />
                </div>
            </div>

            <div className="row py-3">
                <div className="col-md-8">
                    <FieldTrimmed
                        name="enrollment[enrolling][prequalSNP][listMedications]"
                        label="List all current Medication(s)"
                        charLimit={LENGTH_RESTRICTIONS.TEXT}
                        type="textarea"
                        role="textbox"
                        component={RenderField}
                        required
                    />
                </div>
            </div>
            <div className="ml-2 py-3">
                <Field
                    name="enrollment[enrolling][prequalSNP][authorizeAHPRequestRecords]"
                    label="I authorize for AHP to request medical records from my physician(s)."
                    type="checkbox"
                    component={RenderCheckbox}
                    required
                />
            </div>

            <div className="row">
                <div className="col">
                    <p>
                        I acknowledge that by joining a Special Needs Plan
                        (SNP), I am enrolling in a plan which offers special
                        programs specifically designed to maintain or improve my
                        health condition. I understand that I am required to
                        make an appointment at an Alignment Healthcare Center to
                        get my special care plan underway. At that time, a
                        health care provider will also verify any prequalifying
                        conditions.
                    </p>
                </div>
            </div>
            <div className="row py-2">
                <div className="col-md-8">
                    <ElectronicSignatureAgreementText />
                </div>
            </div>
            <div className="row pb-3">
                <div className="col-md-4">
                    <FieldTrimmed
                        name="enrollment[enrolling][prequalSNP][beneficiarySignature]"
                        type="text"
                        required
                        label="Applicant Signature"
                        component={RenderField}
                    />
                </div>
                <div className="col-md-4">
                    <Field
                        name="enrollment[enrolling][prequalSNP][beneficiarySignatureDate]"
                        label="Date"
                        component={RenderDatePicker}
                        saveFormattedDate={saveFormattedDate}
                        required
                        disabled
                    />
                </div>
            </div>
            <form onSubmit={handleSubmit(checkSNPQualification)}>
                <div className="py-5 text-center">
                    <BackButton className="mr-2" onClick={previousPage}>
                        Back
                    </BackButton>
                    <NextButton className="ml-2" type="submit">
                        Continue
                    </NextButton>
                </div>
            </form>
            <AttentionModal
                title={'SNP Pre-Qualification Warning'}
                toggleModal={() => setShowModal(false)}
                showModal={showModal}
                bodyComponent={
                    <>
                        <p>
                            The answers selected for Clinical Pre-Qualification
                            do not align with the chosen PBP. <br />
                            <br />
                            Please review and update your responses. Incorrect
                            answers may impact the enrollment process and could
                            result in application cancellation.
                        </p>
                        <p className="mb-0">
                            <strong>Do you still want to continue?</strong>
                        </p>
                    </>
                }
                buttonsComponent={
                    <div>
                        <BackButton
                            className="attention-no-btn"
                            onClick={() => setShowModal(false)}
                        >
                            NO
                        </BackButton>
                        <NextButton
                            className="attention-yes-btn"
                            type="submit"
                            onClick={(e) => {
                                setShowModal(false);
                                handleSubmit(e);
                            }}
                        >
                            YES
                        </NextButton>
                    </div>
                }
            />
        </div>
    );
};

PreQualification = reduxForm({
    form: 'wizard', //Form name is same
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
})(PreQualification);

PreQualification = connect((state) => {
    return getFormValues('wizard')(state) || {};
})(PreQualification);
export default PreQualification;
