import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import './NavMenu/NavMenu.scss';
import './layout.css';
import ErrorBoundary from './ErrorBoundary/ErrorBoundary';

export const Layout = ({ children }) => {
    return (
        <div className="layout-template">
            <div className="layout-main-content">
                <Container fluid className="layout-wrapper">
                    <Row>
                        <Col>
                            <ErrorBoundary>{children}</ErrorBoundary>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};
