import React from 'react';
import classnames from 'classnames';
import './linkButton.scss';

const LinkButton = ({ className, children, ...rest }) => (
    <button
        {...rest}
        className={classnames('btn btn-link px-0 py-0 link-button', className)}
    >
        {children}
    </button>
);

export default LinkButton;
