import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { Row, Col } from 'reactstrap';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty, get } from 'lodash';
import { RenderField, RenderDatePicker } from '../../../../actions/renderField';
import { NextButton, BackButton } from '../../../../../Common/Button/Button';
import validate from '../../../actions/validate';
import ReviewPCP from '../Review/ReviewPCP';
import ReviewEnroller from '../Review/ReviewEnrollmentAuthor';
import ReviewMedicare from '../Review/ReviewMedicareInfo';
import ReviewAdditional from '../Review/ReviewAdditional';
import ReviewApplicantInfo from '../Review/ReviewApplicantInfo';
import ReviewEmergencyContact from '../Review/ReviewEmergencyContact';
import ReviewSNP from '../Review/ReviewSNP';
import ReviewPayment from '../Review/ReviewPayment';
import {
    PAGES,
    SUBPAGES,
    EDIT_SECTION,
    PAGE_NAME,
} from '../../../../constants/enum';
import LinkButton from '../../../../components/common/LinkButton';
import { formatCost, formatDate } from '../../../../util/format';
import { ElectronicSignatureAgreementText } from '../../../../components/FormReuse/ElectronicSignature';
import './reviewEnrollment.scss';
import moment from 'moment';
import FieldTrimmed from '../../../../actions/FieldTrimmed';
import { useAppInsightContext } from '../../../../../../contexts/appInsight';

const ImportantInformationText = () => (
    <>
        <ul>
            <li>
                I must keep Hospital (Part A) or Medical (Part B) to stay in
                Alignment Health Plan.
            </li>
            <li>
                By joining this Medicare Advantage Plan or Medicare Prescription
                Drug Plan, I acknowledge that Alignment Health Plan will share
                my information with Medicare, who may use it to track my
                enrollment, to make payments, and for other purposes allowed by
                Federal law that authorize the collection of this information
                (see Privacy Act Statement below).
            </li>
            <li>
                Your response to this form is voluntary. However, failure to
                respond may affect enrollment in the plan.
            </li>
            <li>
                The information on this enrollment form is correct to the best
                of my knowledge. I understand that if I intentionally provide
                false information on this form, I will be disenrolled from the
                plan.
            </li>
            <li>
                I understand that people with Medicare are generally not covered
                under Medicare while out of the country, except for limited
                coverage near the U.S. border.
            </li>
            <li>
                I understand that when my Alignment Health Plan coverage begins,
                I must get all of my medical and prescription drug benefits from
                Alignment Health Plan. Benefits and services provided by
                Alignment Health Plan and contained in my Alignment Health Plan
                “Evidence of Coverage” document (also known as a member contract
                or subscriber agreement) will be covered. Neither Medicare nor
                Alignment Health Plan will pay for benefits or services that are
                not covered.
            </li>
            <li>
                I understand that my signature (or the signature of the person
                legally authorized to act on my behalf) on this application
                means that I have read and understand the contents of this
                application. If signed by an authorized representative (as
                described above), this signature certifies that:
                <ol>
                    <li>
                        This person is authorized under State law to complete
                        this enrollment, and
                    </li>
                    <li>
                        Documentation of this authority is available upon
                        request by Medicare.
                    </li>
                </ol>
            </li>
        </ul>
        <p className="privacy-statement">
            <strong>PRIVACY ACT STATEMENT</strong>
            <br />
            The Centers for Medicare &amp; Medicaid Services (CMS) collects
            information from Medicare plans to track beneficiary enrollment in
            Medicare Advantage (MA) or Prescription Drug Plans (PDP), improve
            care, and for the payment of Medicare benefits. Sections 1851 and
            1860D-1 of the Social Security Act and 42 CFR §§ 422.50, 422.60
            authorize the collection of this information. CMS may use, disclose
            and exchange enrollment data from Medicare beneficiaries as
            specified in the System of Records Notice (SORN) “Medicare Advantage
            Prescription Drug (MARx)”, System No. 09-70-0588. Your response to
            this form is voluntary. However, failure to respond may affect
            enrollment in the plan.
        </p>
    </>
);
const ReviewEnrollmentComponent = (props) => {
    const { handleSubmit, enrollment, navigatePage, navigateSubPage, change } =
        props;
    const onSNPWorkflow = get(
        enrollment,
        'selectPlan.planSummary.isSNPPrequalFormRequired'
    );
    const [previousSubPage, setPreviousSubPage] = useState(
        onSNPWorkflow ? SUBPAGES.SNP_PREQUALIFICATION : SUBPAGES.ADDITIONAL_INFO
    );
    const saveFormattedDate = (path, value) => {
        change(path, value);
    };
    const appInsights = useAppInsightContext();

    useEffect(() => {
        window._mfq.push(['newPageView', 'ReviewPage']);
        window.analytics.page('Review Application');

        appInsights.startTrackPage(
            PAGE_NAME[PAGES.ENROLLMENT][SUBPAGES.REVIEW]
        );

        const currentDate = moment().format('YYYY-MM-DD');
        if (isEmpty(enrollment.review.reviewAuthSignatureDate)) {
            change('enrollment.review.reviewAuthSignatureDate', currentDate);
        }

        return () => {
            appInsights.stopTrackPage(
                PAGE_NAME[PAGES.ENROLLMENT][SUBPAGES.REVIEW],
                null,
                {
                    applicationId: get(enrollment, 'applicationId'),
                }
            );
        };
    }, []);
    return (
        <div className="review-enrollment">
            <Row className="pt-5 justify-content-md-center find-plan">
                <Col
                    lg={9}
                    className="d-flex justify-content-md-center flex-column"
                >
                    <form onSubmit={handleSubmit} className="text-left">
                        <h1 className="header">
                            Review Enrollment Application
                        </h1>
                        <div className="review-title">
                            <ul className="no-bullet list-align-left">
                                <li>
                                    <h5 className="review-title">
                                        <LinkButton
                                            className="clickable"
                                            onClick={() =>
                                                navigatePage(PAGES.SELECT_PLAN)
                                            }
                                        >
                                            {
                                                enrollment.selectPlan
                                                    .planSummary.planName
                                            }{' '}
                                            - Plan Year{' '}
                                            {enrollment.findPlan.coverageYear} -{' '}
                                            {formatCost(
                                                parseFloat(
                                                    enrollment.selectPlan
                                                        .planSummary
                                                        .premiumAmount
                                                )
                                            )}{' '}
                                            <FontAwesomeIcon
                                                icon={faPencilAlt}
                                                aria-label="Click to edit Plan selection"
                                            />
                                        </LinkButton>
                                    </h5>
                                </li>
                                {enrollment.selectPlan.optionalPlanName && (
                                    <>
                                        <li>
                                            <h5 className="review-title">
                                                <LinkButton
                                                    className="clickable"
                                                    onClick={() =>
                                                        navigatePage(
                                                            PAGES.OPTIONAL_BENEFITS_PLAN
                                                        )
                                                    }
                                                >
                                                    {
                                                        enrollment.selectPlan
                                                            .optionalPlanName
                                                    }{' '}
                                                    -{' '}
                                                    {formatCost(
                                                        parseFloat(
                                                            enrollment
                                                                .selectPlan
                                                                .optionalPlanSummary
                                                                .premiumAmount
                                                        )
                                                    )}{' '}
                                                    <FontAwesomeIcon
                                                        icon={faPencilAlt}
                                                        aria-label="Click to edit optional benefit selection"
                                                    />
                                                </LinkButton>
                                            </h5>
                                        </li>
                                        <li>
                                            Total Monthly Premium:{' '}
                                            {formatCost(
                                                parseFloat(
                                                    enrollment.selectPlan
                                                        .optionalPlanSummary
                                                        .premiumAmount
                                                ) +
                                                    parseFloat(
                                                        enrollment.selectPlan
                                                            .planSummary
                                                            .premiumAmount
                                                    )
                                            )}
                                        </li>
                                    </>
                                )}
                            </ul>
                        </div>

                        <div className="py-5">
                            Proposed Effective Date:{' '}
                            <strong>
                                {moment(
                                    enrollment.enrolling.clientInfo
                                        .proposedEffectiveDate
                                ).format('MM/DD/YYYY')}
                            </strong>
                        </div>

                        <div className="py-2">
                            <h5 className="review-title">
                                <LinkButton
                                    className="clickable"
                                    onClick={() =>
                                        navigateSubPage(
                                            SUBPAGES.APPLICANT_INFO,
                                            EDIT_SECTION.APPLICANT_INFO
                                        )
                                    }
                                >
                                    Applicant Information{' '}
                                    <FontAwesomeIcon
                                        icon={faPencilAlt}
                                        aria-label="Click to edit Applicant Information"
                                    />
                                </LinkButton>
                            </h5>
                            <ReviewApplicantInfo />
                        </div>
                        <div className="py-2">
                            <h5 className="review-title">
                                <LinkButton
                                    className="clickable"
                                    onClick={() =>
                                        navigateSubPage(
                                            SUBPAGES.APPLICANT_INFO,
                                            EDIT_SECTION.EMERGENCY_CONTACT
                                        )
                                    }
                                >
                                    Emergency Contact{' '}
                                    <FontAwesomeIcon
                                        icon={faPencilAlt}
                                        aria-label="Click to edit Emergency Contact"
                                    />
                                </LinkButton>
                            </h5>
                            <ReviewEmergencyContact />
                        </div>
                        <div className="py-2">
                            <h5 className="review-title">
                                <LinkButton
                                    className="clickable"
                                    onClick={() =>
                                        navigateSubPage(SUBPAGES.APPLICANT_INFO)
                                    }
                                >
                                    Who is completing the Enrollment
                                    Application?{' '}
                                    <FontAwesomeIcon
                                        icon={faPencilAlt}
                                        aria-label="Click to edit Who is completing Application"
                                    />
                                </LinkButton>
                            </h5>
                            <ReviewEnroller />
                        </div>

                        <div className="py-2">
                            <h5 className="review-title">
                                <LinkButton
                                    className="clickable"
                                    onClick={() =>
                                        navigateSubPage(SUBPAGES.CHOOSE_DOCTOR)
                                    }
                                >
                                    Primary Care Provider{' '}
                                    <FontAwesomeIcon
                                        icon={faPencilAlt}
                                        aria-label="Click to edit Primary Care Provider selection"
                                    />
                                </LinkButton>
                            </h5>
                            <ReviewPCP />
                        </div>

                        <div className="py-2">
                            <h5 className="review-title">
                                <LinkButton
                                    className="clickable"
                                    onClick={() =>
                                        navigateSubPage(
                                            SUBPAGES.APPLICANT_INFO,
                                            EDIT_SECTION.MEDICARE
                                        )
                                    }
                                >
                                    Medicare Information{' '}
                                    <FontAwesomeIcon
                                        icon={faPencilAlt}
                                        aria-label="Click to edit Medicare Information"
                                    />
                                </LinkButton>
                            </h5>
                            <ReviewMedicare />
                        </div>
                        <div className="py-2">
                            <h5 className="review-title">
                                {' '}
                                <LinkButton
                                    className="clickable"
                                    onClick={() =>
                                        navigateSubPage(
                                            SUBPAGES.ADDITIONAL_INFO
                                        )
                                    }
                                >
                                    Additional Information{' '}
                                    <FontAwesomeIcon
                                        icon={faPencilAlt}
                                        aria-label="Click to edit Additional Information"
                                    />
                                </LinkButton>
                            </h5>
                            <ReviewAdditional />
                        </div>
                        <div className="py-2">
                            <h5 className="review-title">
                                <LinkButton
                                    className="clickable"
                                    onClick={() =>
                                        navigateSubPage(
                                            SUBPAGES.ADDITIONAL_INFO,
                                            EDIT_SECTION.PAYMENT_OPTION
                                        )
                                    }
                                >
                                    Payment Option{' '}
                                    <FontAwesomeIcon
                                        icon={faPencilAlt}
                                        aria-label="Click to edit Payment Option"
                                    />
                                </LinkButton>
                            </h5>
                            <ReviewPayment />
                        </div>

                        {onSNPWorkflow && (
                            <div className="py-2">
                                <h5 className="review-title">
                                    <LinkButton
                                        className="clickable"
                                        onClick={() =>
                                            navigateSubPage(
                                                SUBPAGES.SNP_PREQUALIFICATION
                                            )
                                        }
                                    >
                                        SNP Questions{' '}
                                        <FontAwesomeIcon
                                            icon={faPencilAlt}
                                            aria-label="Click to edit SNP Questions"
                                        />
                                    </LinkButton>
                                </h5>
                                <ReviewSNP />
                            </div>
                        )}

                        <div className="py-3">
                            <div className="text-left">
                                <div className="important-text-container">
                                    <h5 className="review-sub-title">
                                        IMPORTANT: Read and sign below:
                                    </h5>
                                    <ImportantInformationText />
                                </div>
                                <div>
                                    <ElectronicSignatureAgreementText />
                                    <Row>
                                        <Col className="pl-2 py-3">
                                            <FieldTrimmed
                                                name="enrollment[review][reviewAuthSignature]"
                                                type="text"
                                                required
                                                label="Signature of Beneficiary or Authorized Representative"
                                                component={RenderField}
                                            />
                                        </Col>
                                        <Col lg={4} className="pl-2 py-3">
                                            <Field
                                                name="enrollment[review][reviewAuthSignatureDate]"
                                                label="Signature Date"
                                                component={RenderDatePicker}
                                                saveFormattedDate={
                                                    saveFormattedDate
                                                }
                                                required
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center">
                            <BackButton
                                className="mr-2"
                                onClick={() => navigateSubPage(previousSubPage)}
                            >
                                Back
                            </BackButton>
                            <NextButton className="ml-2" type="submit">
                                Submit
                            </NextButton>
                        </div>
                    </form>
                </Col>
            </Row>
        </div>
    );
};
const ReviewEnrollment = reduxForm({
    form: 'wizard', //Form name is same
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
})(
    connect((state) => {
        return getFormValues('wizard')(state) || {};
    })(ReviewEnrollmentComponent)
);

export default ReviewEnrollment;
