import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Col } from 'react-bootstrap';
import { formatCost } from '../../../../util/format';

const CostSummary = ({
    renderClickableAction,
    title,
    handleTitleClick,
    monthlyPremium,
    pcpCopay,
    specialistCopay,
    outOfPocketMax,
}) => {
    const haveClickableAction = renderClickableAction.name !== 'noop';
    return (
        <div>
            <div className="row title-height">
                <Col
                    xs={haveClickableAction ? '10' : '12'}
                    className="pl-4 pl-sm-0 pr-2"
                >
                    <h5 className="enrollment-title" onClick={handleTitleClick}>
                        {title}
                    </h5>
                </Col>
                {renderClickableAction()}
            </div>
            <div className="text-center monthly-premium">
                <h3>{formatCost(monthlyPremium, 2)}</h3>
                <p>
                    Monthly Premium
                </p>
            </div>
            <div className="text-center pb-3 row copay-row cost-info">
                <div className="col-4 other-costs">
                    <h5
                        className="enrollment-title"
                        data-testid="pcpCopayAmount"
                    >
                        {formatCost(pcpCopay, 0)}
                    </h5>
                    <strong style={{ fontSize: '80%' }}>
                        PCP Copay <br className="py-0 my-0" /> In-Network
                    </strong>
                </div>
                <div className="col-4 px-1 other-costs border-right border-left">
                    <h5 className="enrollment-title">
                        {formatCost(specialistCopay, 0)}
                    </h5>
                    <strong style={{ fontSize: '80%' }}>
                        Specialist Copay <br className="py-0 my-0" /> In-Network
                    </strong>
                </div>
                <div className="col-4 other-costs px-1">
                    <h5
                        className="enrollment-title"
                        data-testid="outOfPocketMaxAmount"
                    >
                        {formatCost(outOfPocketMax, 0)}
                    </h5>
                    <strong style={{ fontSize: '80%' }}>
                        Out-Of-Pocket Max <br className="py-0 my-0" />{' '}
                        In-Network
                    </strong>
                </div>
            </div>
        </div>
    );
};
CostSummary.propTypes = {
    renderClickableAction: PropTypes.func,
    title: PropTypes.string,
    handleTitleClick: PropTypes.func,
    monthlyPremium: PropTypes.number,
    pcpCopay: PropTypes.string,
    specialistCopay: PropTypes.string,
    outOfPocketMax: PropTypes.number,
};
CostSummary.defaultProps = {
    renderClickableAction: noop,
    title: 'No Title',
    handleTitleClick: noop,
    monthlyPremium: 0,
    pcpCopay: 0,
    specialistCopay: 0,
    outOfPocketMax: 0,
};

export default CostSummary;
