import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { RenderError } from '../../actions/renderField';
import classnames from 'classnames';
import { YES_NO_OPTION } from '../../constants/enum';
import { isEmpty } from 'lodash';
import './styles/radioSelector.scss';
import { formatIdString } from '../../util/format';

let RadioSelector = (props) => {
    const {
        label,
        required,
        list,
        name,
        addLineBreak,
        isBold,
        doEnumBasedOnListIndex,
        valueMap,
    } = props;
    const getValue = (item, index) => {
        if (!isEmpty(valueMap)) {
            return valueMap[item].toString();
        } else if (doEnumBasedOnListIndex) {
            return (index + 1).toString();
        }
        return item;
    };
    const inputPrefix = formatIdString(name);
    return (
        <div
            className={classnames('text-left mb-3 enlarged-radio', {
                'd-flex': !addLineBreak,
            })}
            role="radiogroup"
            aria-labelledby={`${inputPrefix}LabelId`}
        >
            <p
                className={classnames({
                    'font-weight-bold': isBold,
                    'required-label': required,
                })}
                id={`${inputPrefix}LabelId`}
            >
                {label}{' '}
            </p>
            <span className="radio-options">
                {list.map((item, index) => {
                    const optionLabelId = `${inputPrefix}-${index}-Id`;
                    return (
                        <Label
                            className="mx-3 d-flex"
                            key={`radioOption_${index}`}
                        >
                            <div
                                className={`d-flex ${
                                    item.length >= 50
                                        ? 'pt-1'
                                        : 'align-items-center'
                                }`}
                            >
                                <Field
                                    name={name}
                                    component="input"
                                    type="radio"
                                    aria-labelledby={optionLabelId}
                                    value={getValue(item, index)}
                                />
                            </div>
                            <div id={optionLabelId} className="option-text">
                                {item}
                            </div>
                        </Label>
                    );
                })}
            </span>
            <Field name={name} component={RenderError} />
        </div>
    );
};
RadioSelector.propTypes = {
    label: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    valueMap: PropTypes.object,
    required: PropTypes.bool,
    addLineBreak: PropTypes.bool,
    isBold: PropTypes.bool,
    doEnumBasedOnListIndex: PropTypes.bool,
};
RadioSelector.defaultProps = {
    valueMap: {},
    required: false,
    isBold: false,
    addLineBreak: false,
    doEnumBasedOnListIndex: false,
};
RadioSelector = reduxForm({
    form: 'wizard', //Form name is same
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(RadioSelector);

// Only Yes/No
let YesNoSelector = (props) => {
    const { label, required, name, addLineBreak, isBold, includeNotSure } =
        props;
    const [choiceList, setChoiceList] = useState(
        includeNotSure
            ? {
                  Yes: YES_NO_OPTION['Yes'],
                  No: YES_NO_OPTION['No'],
                  'Not Sure': YES_NO_OPTION['Not Sure'],
              }
            : { Yes: YES_NO_OPTION['Yes'], No: YES_NO_OPTION['No'] }
    );
    const inputPrefix = formatIdString(name);
    return (
        <div
            className={classnames(
                'yes-no-selector text-left mb-3 enlarged-radio',
                {
                    'd-flex': !addLineBreak,
                }
            )}
            role="radiogroup"
            aria-labelledby={`${inputPrefix}LabelId`}
        >
            <p
                className={classnames('prompt mb-2', {
                    'font-weight-bold': isBold,
                    'required-label': required,
                })}
                id={`${inputPrefix}LabelId`}
            >
                {label}{' '}
            </p>
            <div className="d-flex yes-no-radio">
                {Object.keys(choiceList).map((keyword, index) => {
                    const optionLabelId = formatIdString(
                        `${inputPrefix}-${keyword}-Id`
                    );
                    return (
                        <Label
                            className="mx-3 options"
                            key={`radioOption_${index}`}
                        >
                            <div className="d-flex align-items-center">
                                <Field
                                    name={name}
                                    component="input"
                                    type="radio"
                                    aria-labelledby={optionLabelId}
                                    value={choiceList[keyword].toString()}
                                />
                            </div>
                            <div id={optionLabelId} className="option-text">
                                {keyword}
                            </div>
                        </Label>
                    );
                })}
            </div>
            <Field name={name} component={RenderError} />
        </div>
    );
};
YesNoSelector.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    addLineBreak: PropTypes.bool,
    isBold: PropTypes.bool,
    includeNotSure: PropTypes.bool,
    required: PropTypes.bool,
};
YesNoSelector = reduxForm({
    form: 'wizard', //Form name is same
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(YesNoSelector);

export { RadioSelector, YesNoSelector };
