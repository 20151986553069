export const LENGTH_RESTRICTIONS = {
    FIRST_NAME: 30,
    MIDDLE_INITIAL: 1,
    LAST_NAME: 30,
    MEDICARE_ID: 12,
    ADDRESS_LINE: 50,
    CITY: 30,
    ZIPCODE: 5,
    EMAIL: 50,
    TEXT: 1000,
    GENERAL_INFO: 120,
    INSTITUTION_NAME: 40,
    SUITE_APT: 100,
    COVERAGE_NAME: 50,
    COVERAGE_GROUP: 50,
    COVERAGE_ID: 25,
    MEDICAID: 15,
};
