import React, { useCallback } from 'react';
import PropType from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { noop } from 'lodash';
import classnames from 'classnames';

const PrintButton = ({ className, icon, label, buttonProps, onClick }) => {
    const clickHandler = useCallback(() => {
        window.print();
        if (typeof onClick === 'function') {
            onClick();
        }
    }, []);
    return (
        <Button
            variant="link"
            className={classnames('print-button', className)}
            {...buttonProps}
            onClick={clickHandler}
        >
            <FontAwesomeIcon
                icon={icon}
                className="icon"
                aria-label="Print Button Icon"
            />
            <span className="label">{label}</span>
        </Button>
    );
};
PrintButton.propTypes = {
    className: PropType.string,
    icon: PropType.object,
    label: PropType.string,
    buttonProps: PropType.object,
    onClick: PropType.func,
};
PrintButton.defaultProps = {
    className: '',
    icon: faPrint,
    label: '',
    buttonProps: {},
    onClick: noop,
};
export default PrintButton;
