const PAGES = {
    FIND_PLAN: 1,
    SELECT_PLAN: 2,
    OPTIONAL_BENEFITS_PLAN: 3,
    SUMMARY: 4,
    ENROLLMENT: 5,
    CONFIRMATION: 6,
};
const SUBPAGES = {
    // ENROLLMENT PAGE
    APPLICANT_INFO: 1,
    CONFIRM_ELIGIBILITY: 2,
    CHOOSE_DOCTOR: 3,
    ADDITIONAL_INFO: 4,
    SNP_PREQUALIFICATION: 5,
    REVIEW: 6,

    // SELECT PLANS PAGE
    SELECT_PLAN: 1,
    COMPARE_PLAN: 2,
};

const QUESTION_INDEX = {
    ENROLLMENT: {
        ADDITIONAL_INFO: {
            OTHER_COVERAGE: 0,
            CHRONIC_CONDITION: 1,
            ELLIGIBLE_MEDICAID: 2,
            ENROLLED_MEDICAID: 3,
        },
        SNP_PREQUALIFICATION: {
            HOMEBOUND: 0,
            DIABETES_1: 1,
            DIABETES_2: 2,
            CARDIO_1: 3,
            CARDIO_2: 4,
            CHRONIC_1: 5,
            CHRONIC_2: 6,
            ESRD_1: 7,
            ESRD_2: 8,
            LUNG_1: 9,
            LUNG_2: 10,
            LUNG_3: 11,
            LUNG_4: 12,
            MENTAL_1: 13,
            MENTAL_2: 14,
            MENTAL_3: 15,
            MENTAL_4: 16,
        },
    },
};

const SUB_QUESTION_INDEX = {
    OTHER_COVERAGE: {
        COVERAGE_NAME: 0,
        COVERAGE_ID: 1,
        COVERAGE_GROUP: 2,
    },
    ENROLLED_MEDICAID: {
        MEDICAID_NUM: 0,
    },
};

const PAY_OPTION = {
    RECEIVE_BILL: 1,
    DEDUCT_FROM_CHECK: 2,
};
const GENDER = {
    NONE: 0,
    MALE: 1,
    FEMALE: 2,
    UNKNOWN: 3,
    NONBINARY: 4,
};
const PREFERRED_CONTACT = {
    NONE: 0,
    HOME_PHONE: 1,
    WORK_PHONE: 2,
    CELL_PHONE: 3,
    EMAIL: 4,
};
const RECEIPT_PREF = {
    NONE: 0,
    PRINT: 1,
    EMAIL: 2,
    CD: 3,
    WEBSITE: 4,
    BRAILLE: 5,
    LARGE_PRINT: 6,
    AUDIO_CD: 7,
};
const RELATIONSHIP = {
    NONE: 0,
    CHILD: 1,
    CONSERVATOR: 2,
    DOCTORSOFFICE: 3,
    EMPLOYEE: 4,
    FRIEND: 5,
    CAREGIVER: 6,
    HEALTHPLAN: 7,
    MEDICALGROUP: 10,
    MEMBER: 11,
    NEIGHBOR: 12,
    NOTAPPLICABLE: 13,
    SALESREP: 14,
    PARENT: 15,
    POWEROFATTORNEY: 16,
    RELATIVE: 17,
    SPOUSE: 18,
    SPECIALIST: 19,
    OTHER: 20,
};
const ENROLLMENT_DOC_TYPE = {
    POA: 0,
    SOA: 1,
    CSNP: 2,
    Attestation: 3,
    DOILicense: 4,
    EO: 5,
    AHIP: 6,
    Enroll: 7,
    Other: 8,
    PBPChange: 9,
    HMO: 10,
    PPO: 11,
    HMOSNP: 12,
    ESRD: 13,
    PaperApplication: 14,
    HMOPPO: 15,
    HMONC: 16,
    HMONV: 17,
    OSB: 18,
    HRA: 19,
    PPOSNP: 20,
    SEPSSI: 21,
    SEPChange: 22,
};
const YES_NO_OPTION = {
    None: 0,
    Yes: 1,
    No: 2,
    'Not Sure': 3,
};
const EDIT_SECTION = {
    MEDICARE: 1,
    APPLICANT_INFO: 2,
    EMERGENCY_CONTACT: 3,
    PAYMENT_OPTION: 4,
};
const PAGE_NAME = {
    [PAGES.FIND_PLAN]: 'IndividualEnrollmentFindPlanPage',
    [PAGES.SELECT_PLAN]: {
        [SUBPAGES.SELECT_PLAN]: 'IndividualEnrollmentSelectPlanPage',
        [SUBPAGES.COMPARE_PLAN]: 'IndividualEnrollmentComparePlansPage',
    },
    [PAGES.OPTIONAL_BENEFITS_PLAN]: 'IndividualEnrollmentOptionalBenefitsPage',
    [PAGES.SUMMARY]: 'IndividualEnrollmentSelectionPlanSummaryPage',
    [PAGES.ENROLLMENT]: {
        [SUBPAGES.CHOOSE_DOCTOR]: 'IndividualEnrollmentEnrollChooseDoctorPage',
        [SUBPAGES.APPLICANT_INFO]:
            'IndividualEnrollmentEnrollApplicantInfoPage',
        [SUBPAGES.CONFIRM_ELIGIBILITY]:
            'IndividualEnrollmentEnrollEligibilityPage',
        [SUBPAGES.ADDITIONAL_INFO]:
            'IndividualEnrollmentEnrollAdditionalInfoPage',
        [SUBPAGES.SNP_PREQUALIFICATION]: 'IndividualEnrollmentEnrollSNPPage',
        [SUBPAGES.REVIEW]: 'IndividualEnrollmentReviewPage',
    },
    [PAGES.CONFIRMATION]: 'IndividualEnrollmentConfirmationPage',
};

const PROVIDERS_RATING = {
    GOLD: 5,
    SILVER: 4,
};

const DISASTER_ENUM = {
    DROUGHT: 1,
    FLOOD: 2,
    FIRE: 3,
    OTHER: 4,
};

export {
    PAGES,
    SUBPAGES,
    QUESTION_INDEX,
    SUB_QUESTION_INDEX,
    PAY_OPTION,
    RECEIPT_PREF,
    RELATIONSHIP,
    GENDER,
    ENROLLMENT_DOC_TYPE,
    YES_NO_OPTION,
    PREFERRED_CONTACT,
    EDIT_SECTION,
    PAGE_NAME,
    PROVIDERS_RATING,
    DISASTER_ENUM,
};
