import React from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { get, isEmpty } from 'lodash';
import { ENROLLER_TYPE } from '../../../../../../constants/identifiers';
import { translateRelation } from '../../../../util/translation';

const ReviewEnrollerComponent = ({ enrollment }) => {
    const enrollerType = get(enrollment, 'enrolling.clientInfo.enrollerType');
    let fName, lName, phoneNum, addressLine, city, zip, state, relationship;
    if (enrollerType === ENROLLER_TYPE.REPRESENTATIVE) {
        const {
            representativeFirstName,
            representativeLastName,
            representativePhone,
            representativeAddressLine1,
            representativeCity,
            representativeState,
            representativeZipCode,
            representativeRelationship,
        } = get(enrollment, 'enrolling.clientInfo');
        fName = representativeFirstName;
        lName = representativeLastName;
        phoneNum = representativePhone;
        addressLine = representativeAddressLine1;
        city = representativeCity;
        state = representativeState;
        zip = representativeZipCode;
        relationship = translateRelation(representativeRelationship);
    } else {
        const {
            firstName,
            lastName,
            primaryPhone,
            permanentAddressLine1,
            permanentCity,
            permanentState,
            permanentZipCode,
        } = get(enrollment, 'enrolling.clientInfo');
        fName = firstName;
        lName = lastName;
        phoneNum = primaryPhone;
        addressLine = permanentAddressLine1;
        city = permanentCity;
        state = permanentState;
        zip = permanentZipCode;
    }
    return (
        <>
            {enrollerType === ENROLLER_TYPE.REPRESENTATIVE && (
                <h5 className="review-sub-title">{enrollerType}</h5>
            )}
            <div className="row">
                <div className="col-md-5">
                    <ul className="no-bullet list-align-left">
                        <li>
                            First Name: <strong>{fName}</strong>
                        </li>
                        <li>
                            Last Name: <strong>{lName}</strong>
                        </li>
                        <li>
                            Phone Number: <strong>{phoneNum}</strong>
                        </li>
                        {!isEmpty(relationship) && (
                            <li>
                                Relationship: <strong>{relationship}</strong>
                            </li>
                        )}
                    </ul>
                </div>
                <div className="col-md-5">
                    <ul className="no-bullet list-align-left">
                        <li>
                            Address: <strong>{addressLine}</strong>
                        </li>
                        <li>
                            City: <strong>{city}</strong>
                        </li>
                        <li>
                            State: <strong>{`${state}, ${zip}`}</strong>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

const ReviewEnroller = connect((state) => {
    return getFormValues('wizard')(state) || {};
})(ReviewEnrollerComponent);

export default ReviewEnroller;
