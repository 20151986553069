import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Input, FormText } from 'reactstrap';
import Autocomplete from 'react-google-autocomplete';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { defaultTo, isEmpty, noop, get, filter } from 'lodash';
import moment from 'moment';
import { formatIdString } from '../util/format';
import classnames from 'classnames';
import { MultiSelect } from 'react-multi-select-component';
import '../styles/index.scss';
import './renderField.scss';
import AccessibleDatePicker from '../components/common/DatePicker/AccessibleDatePicker';

const trimWhiteSpaces = (e, onEventFunc) => {
    const str = e.target.value;
    const eventHandler = defaultTo(onEventFunc, noop);
    if (typeof str === 'string') {
        return eventHandler(str.trim());
    }
    return eventHandler(str);
};
const formatAriaLabel = (value) => {
    if (value) {
        return value.replace(/#/g, 'Number');
    }
};

const RenderField = ({
    input,
    id,
    required,
    label,
    type,
    ariaLabel,
    charLimit,
    disabled,
    meta: { touched, error },
    autoComplete = 'new-password',
    autoFocus,
    tooltip,
}) => {
    const handleBlur = (e) => trimWhiteSpaces(e, input.onBlur);

    const idStr = `${id || formatIdString(input.name)}`;
    return (
        <div className="text-left mb-3 render-field">
            <FormGroup>
                <div className="label-section">
                    <Label
                        className={classnames('px-2', {
                            'required-label': required,
                        })}
                        for={idStr}
                    >
                        {label}{' '}
                    </Label>
                    {tooltip}
                </div>

                <Input
                    {...input}
                    id={idStr}
                    aria-label={ariaLabel || formatAriaLabel(label)}
                    onBlur={handleBlur}
                    placeholder={label}
                    type={type}
                    value={
                        type === 'date'
                            ? moment(input.value).format('YYYY-MM-DD')
                            : input && input.value
                    }
                    maxLength={charLimit}
                    disabled={disabled}
                    autoComplete={autoComplete}
                    autoFocus={autoFocus}
                />
                {touched && error && (
                    <FormText color="danger">{error}</FormText>
                )}
            </FormGroup>
        </div>
    );
};
const RenderDropdownDatePicker = ({
    input: { onChange, value },
    id,
    required,
    dateFormat,
    label,
    ariaLabel,
    pastOnly,
    minDate,
    maxDate,
    disabled,
    meta: { touched, error },
}) => {
    const handleDateSelect = (selectedDate) => {
        onChange(moment(selectedDate).format('YYYY-MM-DD'));
    };
    const idStr = `${id || Math.round(Math.random() * 1000)}`;

    return (
        <div className="text-left mb-3">
            <FormGroup>
                <Label
                    className={classnames('px-2', {
                        'required-label': required,
                    })}
                    for={idStr}
                >
                    {label}{' '}
                </Label>
                <DatePicker
                    id={idStr}
                    aria-label={ariaLabel || formatAriaLabel(label)}
                    disabled={disabled}
                    dateFormat={dateFormat}
                    selected={!value ? null : moment(value).toDate()}
                    onChange={handleDateSelect}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    minDate={minDate}
                    maxDate={pastOnly ? moment().toDate() : maxDate}
                    className="form-control"
                />
                {touched && error && (
                    <FormText color="danger">{error}</FormText>
                )}
            </FormGroup>
        </div>
    );
};
const RenderDatePicker = ({
    input,
    id,
    required,
    label,
    ariaLabel,
    pastOnly,
    minDate,
    maxDate,
    start,
    disabled,
    saveFormattedDate,
    meta: { touched, error },
}) => {
    const [pickedDate, setPickedDate] = useState(null);
    const INVALID_INPUT = 'Invalid date';

    useEffect(() => {
        if (!input.value) {
            setPickedDate(null);
            return;
        }
        const dateValue = moment(input.value).format('MM/DD/YYYY');

        setPickedDate(dateValue === INVALID_INPUT ? null : dateValue);
        if (saveFormattedDate) {
            const formattedDate = moment(input.value).format('YYYY-MM-DD');

            saveFormattedDate(
                input.name,
                formattedDate === INVALID_INPUT ? null : formattedDate
            );
        }
    }, [input.value]);

    const idStr = `${id || Math.round(Math.random() * 1000)}`;
    return (
        <div className="text-left mb-3">
            <FormGroup>
                <Label
                    className={classnames('px-2', {
                        'required-label': required,
                    })}
                    for={idStr}
                >
                    {label}{' '}
                </Label>
                <AccessibleDatePicker
                    {...input}
                    id={idStr}
                    ariaLabel={ariaLabel || formatAriaLabel(label)}
                    openOnFocus={true}
                    placeholder={label}
                    value={pickedDate}
                    format="MM/dd/yyyy"
                    onChange={(selectedDate) => {
                        if (!selectedDate.value) {
                            return;
                        }
                        setPickedDate(
                            moment(selectedDate.value).format('MM/DD/YYYY')
                        );
                    }}
                    min={minDate}
                    max={pastOnly ? moment().toDate() : maxDate}
                    start={start}
                    enabled={!disabled}
                    className="date-picker"
                />
                {touched && error && (
                    <FormText color="danger">{error}</FormText>
                )}
            </FormGroup>
        </div>
    );
};
const RenderItemSelector = ({
    input,
    id,
    required,
    label,
    list,
    disabled,
    doEnumBasedOnListIndex,
    valueMap,
    addPlaceholder,
    ariaLabel,
    meta: { touched, error },
    tooltip,
    ...rest
}) => {
    const getValue = (item, index) => {
        if (!isEmpty(valueMap)) {
            return valueMap[item]?.toString();
        } else if (doEnumBasedOnListIndex) {
            let optionValue = index;
            if (addPlaceholder) {
                optionValue += 1;
            }
            return optionValue?.toString();
        }
        return item;
    };

    const idStr = `${id || formatIdString(input.name)}`;
    return (
        <div className="text-left render-field">
            <FormGroup>
                {label && (
                    <div className="label-section">
                        <Label
                            className={classnames('px-2', {
                                'required-label': required,
                            })}
                            for={idStr}
                        >
                            {label}{' '}
                        </Label>
                        {tooltip}
                    </div>
                )}
                <Input
                    {...input}
                    id={idStr}
                    type="select"
                    aria-label={ariaLabel || formatAriaLabel(label)}
                    disabled={disabled}
                    {...rest}
                >
                    {addPlaceholder && (
                        <option value="" disabled>
                            Select...
                        </option>
                    )}
                    {!isEmpty(list) &&
                        list.map((item, i) => {
                            return (
                                <option
                                    key={`option_${i}`}
                                    value={getValue(item, i)}
                                >
                                    {item}
                                </option>
                            );
                        })}
                </Input>
                {touched && error && (
                    <FormText color="danger">{error}</FormText>
                )}
            </FormGroup>
        </div>
    );
};
const RenderMultiSelectField = ({
    input,
    id,
    ariaLabel,
    required,
    label,
    options,
    disabled,
    placeholder,
    hasSelectAll,
    meta: { touched, error },
    tooltip,
    ...rest
}) => {
    const idStr = `${id || formatIdString(input.name)}`;

    return (
        <div className="text-left mb-3 render-field multi-select">
            <FormGroup>
                <Label
                    className={classnames('px-2', {
                        'required-label': required,
                    })}
                    for={idStr}
                >
                    {label}{' '}
                </Label>
                <MultiSelect
                    id={idStr}
                    name={input.name}
                    onChange={(items) => {
                        const valueStr = items
                            .map((item) => item.value)
                            .join(',');
                        input?.onChange(valueStr);
                    }}
                    value={
                        filter(options, (item) =>
                            input?.value?.includes(item.value)
                        ) ?? []
                    }
                    aria-label={ariaLabel || formatAriaLabel(label)}
                    options={options}
                    labelledBy={label}
                    hasSelectAll={hasSelectAll}
                />
                {touched && error && (
                    <FormText color="danger" data-testid="errorMessageInput">
                        {error}
                    </FormText>
                )}
            </FormGroup>
        </div>
    );
};
const RenderCheckbox = ({
    input,
    required,
    label,
    addLineBreak,
    disabled,
    ariaLabel,
    meta: { touched, error },
    id,
}) => {
    const idStr = `${id || Math.round(Math.random() * 1000)}`;
    return (
        <div>
            <div className="text-left mb-3 checkbox-field">
                <FormGroup
                    className={`mx-3 mb-0`}
                    role="group"
                    aria-label={ariaLabel || formatAriaLabel(label)}
                >
                    <Input
                        {...input}
                        type="checkbox"
                        disabled={disabled}
                        id={idStr}
                    />
                    {addLineBreak ? <br /> : null}
                    <label className="checkbox-text" htmlFor={idStr}>
                        {label}{' '}
                        {required ? (
                            <span className="text-danger">*</span>
                        ) : (
                            <span />
                        )}
                    </label>
                </FormGroup>
                {touched && error && (
                    <FormText color="danger">{error}</FormText>
                )}
            </div>
        </div>
    );
};
const RenderAddressAutoCompleteField = ({
    input,
    id,
    required,
    label,
    charLimit,
    disabled,
    meta: { touched, error },
    tooltip,
    inputPath,
    googleApiKey,
    handlePlaceSelected,
}) => {
    const handleBlur = (e) => trimWhiteSpaces(e, input.onBlur);

    const idStr = `${id || formatIdString(input.name)}`;
    return (
        <div className="text-left mb-3 render-field">
            <FormGroup>
                <div className="label-section">
                    <Label
                        className={classnames('px-2', {
                            'required-label': required,
                        })}
                        for={idStr}
                    >
                        {label}{' '}
                    </Label>
                    {tooltip}
                </div>
                <Autocomplete
                    {...input}
                    id={`${inputPath}${idStr}`}
                    aria-label="Enter address"
                    onBlur={handleBlur}
                    placeholder={label}
                    className="text-field-input form-control"
                    onPlaceSelected={handlePlaceSelected}
                    required={required}
                    value={input && input.value}
                    options={{
                        types: ['address'],
                        componentRestrictions: {
                            country: 'us',
                        },
                    }}
                    maxLength={charLimit}
                    disabled={disabled}
                    apiKey={googleApiKey}
                />
                {touched && error && (
                    <FormText color="danger">{error}</FormText>
                )}
            </FormGroup>
        </div>
    );
};
const RenderError = ({ meta: { touched, error } }) =>
    touched && error ? (
        <FormText className="text-left text-danger">{error}</FormText>
    ) : (
        false
    );
export {
    RenderField,
    RenderItemSelector,
    RenderCheckbox,
    RenderError,
    RenderDatePicker,
    RenderDropdownDatePicker,
    RenderMultiSelectField,
    RenderAddressAutoCompleteField,
};
