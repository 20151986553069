import React, { useCallback, useRef } from 'react';
import { PagerComponent } from '@syncfusion/ej2-react-grids';
import { handleEnterKey } from '../../../util/eventListeners';
const tryIt = (func) => {
    try {
        func();
    } catch (e) {}
};

const PagerComponentTabIndexed = (props) => {
    const pagerRef = useRef();
    const onCreated = useCallback(() => {
        const element = pagerRef.current.element;
        element.querySelectorAll('.e-pagercontainer').forEach((container) => {
            container.removeAttribute('role');
        });
        element.querySelectorAll('a[role=link]').forEach((link) => {
            link.setAttribute('tabIndex', '0');
            link.removeAttribute('role');
        });

        [
            '.e-first:not(.e-disable)',
            '.e-prev:not(.e-disable)',
            '.e-next:not(.e-disable)',
            '.e-last:not(.e-disable)',
        ].forEach((selector) => {
            tryIt(() => {
                const selectedDom = element.querySelector(selector);
                selectedDom.setAttribute('tabIndex', '0');
                selectedDom.addEventListener(
                    'keyup',
                    handleEnterKey(() => selectedDom.click())
                );
            });
        });

        element
            .querySelector('.e-pagercontainer')
            .setAttribute('aria-label', 'paging section');
    }, []);
    return (
        <PagerComponent
            {...props}
            ref={pagerRef}
            created={onCreated}
            aria-label="Pages navigation"
        />
    );
};

export default PagerComponentTabIndexed;
