import { submitEnrollmentApplication } from '../../../api/enrollment';

const submitApplication = async (enrollment, timestamp) => {
    const enrollmentSubmission = {
        ...enrollment,
        submittedTimestamp: timestamp,
    };
    const response = await submitEnrollmentApplication(enrollmentSubmission);
    return response;
};
export default submitApplication;
