import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import './blankModal.scss';

const BlankModal = ({
    showModal,
    toggleModal,
    title,
    bodyComponent,
    buttonsComponent,
}) => (
    <Modal
        className="popup-modal"
        container="div#root"
        isOpen={showModal}
        toggle={toggleModal}
        centered
        size="lg"
    >
        <ModalHeader className="popup-header border-0" toggle={toggleModal}>
            <span className="popup-text">{title}</span>
        </ModalHeader>
        <ModalBody className="popup-body">{bodyComponent}</ModalBody>
        <ModalFooter className="popup-footer border-0">
            {buttonsComponent || (
                <Button
                    className="popup-ok-btn"
                    onClick={() => {
                        toggleModal();
                    }}
                >
                    OK
                </Button>
            )}
        </ModalFooter>
    </Modal>
);
BlankModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    title: PropTypes.string,
    bodyComponent: PropTypes.node,
    buttonsComponent: PropTypes.node,
};
BlankModal.defaultProps = {
    title: null,
    bodyComponent: null,
    buttonsComponent: null,
};
export default BlankModal;
