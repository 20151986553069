const USER_TYPE = {
    FieldAgent: 'FieldAgent',
    TeleAgent: 'TeleAgent',
    Broker: 'Broker',
};
const ENROLLER_TYPE = {
    APPLICANT: 'Applicant',
    REPRESENTATIVE: 'Authorized Representative',
};
const RESOURCE_TYPE = {
    MEMBER_RESOURCE: 'MemberResources',
    BROKER_PORTAL: 'BrokerPortal',
    PROVIDER_SEARCH: 'ProviderSearch',
    GOLDEN_COMM_SHOPPING: 'GoldenCommShoppingSite',
};
const RESPONSE = {
    YES: 'YES',
    NO: 'NO',
};
const GENDER_CODE = {
    FEMALE: 'F',
    MALE: 'M',
};
const FEATURE_IDS = {
    GC_PLAN_CONSOLE: 'plan-select-console',
};
export {
    USER_TYPE,
    RESPONSE,
    GENDER_CODE,
    ENROLLER_TYPE,
    RESOURCE_TYPE,
    FEATURE_IDS,
};
